import i18n from 'i18next';

/**
 * Get translated allowance label with fallback to database value
 *
 * @param {string} key - The allowance key (e.g. 'full', 'db_43')
 * @param {boolean} isShort - Whether to get the short version
 * @returns {string} - The translated label
 */
const getAllowanceLabel = (key, isShort = false) => {
  if (!key) return '';

  // Database allowances always start with db_
  if (key.startsWith('db_')) {
    const id = key.replace('db_', '');
    const allowances = window.__STORES__?.timelogStore?.allowances?.value || [];
    const allowance = allowances.find((a) => a.id.toString() === id);

    if (allowance) {
      const result = isShort && allowance.short_name ? allowance.short_name : allowance.name;
      return result;
    }
  }

  // For standard allowances, use translations
  const suffix = isShort ? '_short' : '';
  const translationKey = `timelog.attributes.${key}${suffix}`;
  const translation = i18n.t(translationKey);
  return translation;
};

// Export both as default and named export for backward compatibility
export { getAllowanceLabel };
export default getAllowanceLabel;

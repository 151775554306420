/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import MUIDataTable from "mui-datatables";
import {
  Button,
  Link,
} from '@material-ui/core';
import LoadingSpinner from '../shared/LoadingSpinner';
import BillingStatusDialog from './BillingStatusDialog';

import './billing.css';

@inject('uiStore', 'loginStore', 't', 'employerWorkOrderStore')
@observer
class EmployerBilling extends Component {
  constructor(props) {
    super(props);

    this.defaultOffset = 0;
    this.defaultWorkOrdersPerPage = 25;
    // this.defaultSort = 'work_order.from-desc,bill.status-asc';
    // this.defaultSort = 'work_order.from-desc,work_order.to-desc';

    this.state = {
      showAddNewWorkOrder: false,
      billingStatusDialogOpen: false,
      openBilling: null,
      workOrdersPerPage: this.defaultWorkOrdersPerPage,
    };
  }

  queryWorkOrders(offset, defaultWorkOrdersPerPage = null, sort = null) {
    const { employerWorkOrderStore } = this.props;
    const { workOrdersPerPage } = this.state;

    const limit = workOrdersPerPage || defaultWorkOrdersPerPage;

    this.setState({
      isLoading: true,
      offset,
      workOrders: [],
      // workOrdersPerPage: limit,
    }, () => {
      employerWorkOrderStore.getEmployerWorkOrdersBillingPaginated(offset, limit, sort).then((res) => {
        console.log('RES: ', res);
        this.setState({
          isLoading: false,
          // page: res.page,
          // sortOrder,
          sort,
          workOrders: res.workOrders,
          workOrdersCount: res.count,
        }, () => {
          employerWorkOrderStore.cacheBillingWorkOrders(res.workOrders, res.count, limit, offset, sort);
        });
      });
    });
  }

  convertSortOrderObjectToString = (sortObject) => {
    const { name, direction } = sortObject;
    if (!name || !direction) {
      return '';
    }

    let sort;
    if (!name.includes('.')) {
      // Assuming a work order column by default, we need the 'dot notation' here for postgreSQL query generation
      sort = `work_order.${name}-${direction}`;
    } else {
      sort = `${name}-${direction}`;
    }
    return sort;
  }

  componentDidMount() {
    const { employerWorkOrderStore, uiStore: { currentView } } = this.props;

    // this.updateWindowDimensions();
    // window.addEventListener('resize', this.updateWindowDimensions);

    // // Set the default filters
    // if (!employerWorkOrderStore.billingFilters.hasOwnProperty('bill.status') || !employerWorkOrderStore.billingFilters.hasOwnProperty('timeStatus')) {
    //   // employerWorkOrderStore.setBillingFilters('bill.status', ['not_billed', null, 'billable']);
    //   // employerWorkOrderStore.setBillingFilters('timeStatus', ['archived', 'ongoing']);
    //   this.queryWorkOrders(this.defaultOffset, this.defaultWorkOrdersPerPage);
    // }

    // this.queryWorkOrders(this.defaultOffset, this.defaultWorkOrdersPerPage, this.defaultSort);
    if (employerWorkOrderStore.billingWorkOrderCache && employerWorkOrderStore.billingWorkOrderCache.workOrders) {
      this.setState({
        workOrders: employerWorkOrderStore.billingWorkOrderCache.workOrders,
        workOrdersCount: employerWorkOrderStore.billingWorkOrderCache.count,
        offset: employerWorkOrderStore.billingWorkOrderCache.offset,
        workOrdersPerPage: employerWorkOrderStore.billingWorkOrderCache.limit,
        sort: employerWorkOrderStore.billingWorkOrderCache.sort,
      });
    } else if (employerWorkOrderStore.billingWorkOrderCache && !employerWorkOrderStore.billingWorkOrderCache.workOrders) {
      this.setState({
        offset: employerWorkOrderStore.billingWorkOrderCache.offset,
        workOrdersPerPage: employerWorkOrderStore.billingWorkOrderCache.limit,
      }, () => {
        this.queryWorkOrders(employerWorkOrderStore.billingWorkOrderCache.offset, employerWorkOrderStore.billingWorkOrderCache.limit, employerWorkOrderStore.billingWorkOrderCache.sort);
      });
    } else {
      this.queryWorkOrders(this.defaultOffset, this.defaultWorkOrdersPerPage, this.defaultSort);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  // loadWorkOrders() {
  //   const { employerWorkOrderStore } = this.props;
  //   employerWorkOrderStore.getMoreWorkOrders();
  // }

  toggleAddNewWorkOrder = () => {
    const { showAddNewWorkOrder } = this.state;

    if (!showAddNewWorkOrder) {
      this.setState({
        showAddNewWorkOrder: true,
      });
    } else {
      this.setState({
        showAddNewWorkOrder: false,
      });
    }
  }

  openBillingStatusDialog(billing, workOrderName) {
    this.setState({
      openBilling: { ...billing, workOrderName },
      billingStatusDialogOpen: true,
    });
  }

  handleBillingStatusDialogClose = () => {
    this.setState({
      openBilling: null,
      billingStatusDialogOpen: false,
    });
  }

  changePage = (page, sortOrder, rowsPerPage) => {
    const sort = this.convertSortOrderObjectToString(sortOrder);
    const offset = page * rowsPerPage;
    this.queryWorkOrders(offset, null, sort);
  };

  changeRowsPerPage = (page, sortOrder, rowsPerPage) => {
    const sort = this.convertSortOrderObjectToString(sortOrder);
    this.setState({
      workOrdersPerPage: rowsPerPage,
    }, () => {
      const offset = page * rowsPerPage;
      this.queryWorkOrders(offset, null, sort);
    });
  }

  changeSorting = (column, direction) => {
    let sort = this.convertSortOrderObjectToString({ name: column, direction: direction });
    // Bill status sort fixing attempt
    // if (column === 'bill.status') {
    //   sort = `bill.status-${direction};`
    // } else if (!column.includes('.')) {
    //   // Assuming a work order column by default, we need the 'dot notation' here for postgreSQL query generation
    //   sort = `work_order.${column}-${direction}`;
    // } else {
    //   sort = `${column}-${direction}`;
    // }

    this.setState({
      sort,
    }, () => {
      // Re-query using the new sorting, starting at offset 0
      this.queryWorkOrders(0, null, sort);
    });
  }

  renderBillingTable() {
    const { uiStore, uiStore: { mobileMode, currentUser, currentUser: { accountInfo } }, t, employerWorkOrderStore } = this.props;
    const { isLoading, billingStatusDialogOpen, openBilling, workOrders, workOrdersCount, offset, workOrdersPerPage } = this.state;

    // if (!workOrders || isLoading) {
    //   // DB query not yet complete
    //   return (
    //     // display: mobileMode ? 'none' : 'initial'
    //     <div style={{ textAlign: 'center', padding: '20px' }}>
    //       <LoadingSpinner color="black" />
    //     </div>
    //   );
    // }

    // Smaller is higher priority
    // const billStatusPriorities = {
    //   'billable': 0,
    //   'not_billed': 1,
    //   'billed': 2,
    // };

    // const timeStatusPriorities = {
    //   'archived': 2,
    //   'ongoing': 1,
    //   'upcoming': 0,
    // };

    // const billStatusPriorityNull = Object.keys(billStatusPriorities).length;
    // const data = sortBy([...workOrders.value], [(o) => (o.bill?.status ? billStatusPriorities[o.bill.status] : Object.keys(billStatusPriorities).length)]);
    // const data = sortBy([...workOrders.value], ['bill.status', 'timeStatus'], ['desc', 'asc']);

    // Custom sort: the work order's time status is the first order clause, the second clause is the bill status
    // const data = workOrders ? [...workOrders].sort((a, b) => {
    //   const billStatusPriorityA = a.bill?.status ? billStatusPriorities[a.bill.status] : billStatusPriorityNull;
    //   const billStatusPriorityB = b.bill?.status ? billStatusPriorities[b.bill.status] : billStatusPriorityNull;
    //   return timeStatusPriorities[a.timeStatus] - timeStatusPriorities[b.timeStatus] || billStatusPriorityA - billStatusPriorityB;
    // }) : [];

    const columns = [
      {
        name: 'id',
        label: 'ID',
        options: {
          display: 'excluded',
          filter: false,
        },
      },
      {
        name: 'timeStatus',
        label: t('work_order.time_status'),
        options: {
          // display: employerWorkOrderStore.billingDisplayColumns.timeStatus === undefined ? true : employerWorkOrderStore.billingDisplayColumns.timeStatus,
          customBodyRender: (value) => {
            let colorClass;
            if (value === 'upcoming') {
              colorClass = 'invitations-warning';
            } else if (value === 'ongoing') {
              colorClass = 'invitations-ok';
            }

            return (
              <div className={colorClass} style={{ width: 'fit-content' }}>
                {t(`work_order.time_statuses.${value}`)}
              </div>
            );
          },
          // sort: false,
          // filter: true,
          filterOptions: {
            renderValue: (v) => v ? t(`work_order.time_statuses.${v}`) : ''
          },
          customFilterListOptions: {
            render: (v) => v ? `${t('work_order.time_status')}: ${t(`work_order.time_statuses.${v}`)}` : ''
          },
          filterList: employerWorkOrderStore.billingFilters.timeStatus,
          filterType: 'checkbox',
        },
      },
      {
        name: 'name',
        label: 'Nimi',
        options: {
          filter: false,
          // display: employerWorkOrderStore.billingDisplayColumns.name === undefined ? true : employerWorkOrderStore.billingDisplayColumns.name,
        },
      },
      {
        name: 'from',
        label: 'Alkaa',
        options: {
          customBodyRender: (value) => (
            <span>{value.format('D.M.YY')}</span>
          ),
          filter: false,
          // display: employerWorkOrderStore.billingDisplayColumns.from === undefined ? true : employerWorkOrderStore.billingDisplayColumns.from,
        },
      },
      {
        name: 'to',
        label: 'Päättyy',
        options: {
          customBodyRender: (value) => (
            <span>{value.format('D.M.YY')}</span>
          ),
          filter: false,
          // display: employerWorkOrderStore.billingDisplayColumns.to === undefined ? true : employerWorkOrderStore.billingDisplayColumns.to,
        },
      },
      {
        name: 'locationName',
        label: t('work_order_billing.labels.locationName'),
        options: {
          filter: false,
          // display: employerWorkOrderStore.billingDisplayColumns['location.name'] === undefined ? true : employerWorkOrderStore.billingDisplayColumns['location.name'],
        },
      },
      {
        name: 'purchaserName',
        label: t('work_order_billing.labels.purchaserName'),
        options: {
          filter: false,
          // display: employerWorkOrderStore.billingDisplayColumns['purchaser.name'] === undefined ? true : employerWorkOrderStore.billingDisplayColumns['purchaser.name'],
        },
      },
      {
        name: 'billingId',
        label: 'Viitenumero',
        options: {
          display: accountInfo.billingIdsEnabled ? true : 'excluded',
          filter: false,
        },
      },
      {
        name: 'bill.status',
        label: t('bill.status'),
        options: {
          customBodyRenderLite: (dataIndex, rowIndex) => {
            // Even if we access just bill instead of bill.status for the column here, the object is converted into a string
            // Which is why we retrieve the object here
            const woId = workOrders[dataIndex].id;
            const woName = workOrders[dataIndex].name;
            const value = workOrders[dataIndex]?.bill?.status;
            const billObject = workOrders?.find((wo) => wo.id === woId)?.bill;
            // return <div onClick={() => this.openBillingStatusDialog(billObject, woName)} className={`bill-status status-${value}`}>{value ? t(`bill.status.${value}`) : 'Aseta tila'}</div>
            return (
              <Button
                onClick={() => this.openBillingStatusDialog(billObject, woName)}
                className={value ? `billing-status-button billing-status-button-${value}` : `billing-status-button employer-reject-button`}
              >
                {value ? t(`bill.statuses.${value}`) : 'Aseta tila'}
              </Button>
            );
          },
          // filter: true,
          // filterOptions: {
          //   display: 'block',
          //   renderValue: (value) => value ? t(`bill.statuses.${value}`) : t('bill.statuses.not_set')
          // },
          // customFilterListOptions: {
          //   render: (value) => value ? `${t('bill.status')}: ${t(`bill.statuses.${value}`)}` : `${t('bill.status')}: ${t(`bill.statuses.not_set`)}`
          // },
          filterType: 'checkbox',
          sort: false,
          // filterList: employerWorkOrderStore.billingFilters['bill.status'],
          // display: employerWorkOrderStore.billingDisplayColumns['bill.status'] === undefined ? true : employerWorkOrderStore.billingDisplayColumns['bill.status'],
        },
      },
      {
        name: 'report',
        label: 'Raportti',
        options: {
          sort: false,
          filter: false,
          empty: true,
          // display: employerWorkOrderStore.billingDisplayColumns.report === undefined ? true : employerWorkOrderStore.billingDisplayColumns.report,
          customBodyRenderLite: (dataIndex) => {
            return (
              <>
                {workOrders[dataIndex].workHourType === 'hourly' ? (
                  <div className="billing-report-link">
                    <Link
                      component="button"
                      onClick={() => uiStore.showBillingReportsHourly(workOrders[dataIndex].id)}
                    >
                      Tuntiraportti
                    </Link>
                  </div>
                ) : (
                  <div className="billing-report-link">
                    <Link
                      component="button"
                      onClick={() => uiStore.showBillingReports(workOrders[dataIndex].id)}
                    >
                      Tuntiraportti
                    </Link>
                  </div>
                )}
                {currentUser.accountInfo.productsEnabled && (
                  <>
                    <div className="billing-report-link">
                      <Link
                        component="button"
                        onClick={() => uiStore.showProductReport(workOrders[dataIndex].id)}
                      >
                        Tuoteraportti
                      </Link>
                    </div>
                  </>
                )}
              </>
            )
          }
        },
      },
      {
        name: 'attachments',
        // label: 'Liitteet',
        label: t('work_order_billing.labels.attachments'),
        options: {
          sort: false,
          filter: false,
          empty: true,
          // display: employerWorkOrderStore.billingDisplayColumns.attachments === undefined ? true : employerWorkOrderStore.billingDisplayColumns.attachments,
          customBodyRenderLite: (dataIndex) => {
            return (
              <div className="billing-report-link">
                <Link
                  component="button"
                  onClick={() => uiStore.showBillingAttachments(workOrders[dataIndex].id)}
                >
                  {(workOrders[dataIndex].billingAttachmentsCount + workOrders[dataIndex].salaryPeriodAttachmentsCount) > 0 ? `${workOrders[dataIndex].billingAttachmentsCount + workOrders[dataIndex].salaryPeriodAttachmentsCount} kpl` : 'Lisää liite'}
                </Link>
              </div>
            )
          }
        },
      },
    ];

    const options = {
      page: Math.floor((offset + 1) / workOrdersPerPage),
      serverSide: true,
      count: workOrdersCount,
      filter: false,
      sort: true,
      filterType: 'checkbox',
      // responsive: 'vertical',
      responsive: 'standard',
      search: false,
      viewColumns: true,
      selectableRows: 'none',
      fixedHeader: false,
      download: false,
      print: false,
      enableNestedDataAccess: '.',
      rowsPerPage: workOrdersPerPage,
      rowsPerPageOptions: [10, 25, 50, 100],
      setTableProps: () => ({ className: 'mui-data-table-billing' }),
      textLabels: {
        body: {
          noMatch: (
            isLoading ? (
              <div style={{ textAlign: uiStore.mobileMode ? 'left' : 'center', padding: '20px', fontSize: '14px' }}>
                <LoadingSpinner color="black" />
              </div>
            ) : (
              'Keikkoja ei löytynyt'
            )
          )
        },
        toolbar: {
          search: 'Haku',
          viewColumns: 'Näytä sarakkeet',
          filterTable: 'Suodatin',
        },
        pagination: {
          next: "Seuraava sivu",
          previous: "Edellinen",
          rowsPerPage: "Rivejä sivulla:",
          displayRows: "/",
        },
        filter: {
          all: "Kaikki",
          title: "SUODATIN",
          reset: "Tyhjennä",
        },
        viewColumns: {
          title: "Näytä sarakkeet",
          titleAria: "Näytä/piilota sarakkeet",
        },
        selectedRows: {
          text: "rivi(ä) valittu",
          delete: "Poista",
          deleteAria: "Poista valitut rivit",
        },
      },
      // onFilterChange: (changedColumn, filterList, type, changedColumnIndex) => {
      //   console.log('Filter list: ', filterList);
      //   if (changedColumnIndex) {
      //     console.log('New filter: ', filterList[changedColumnIndex]);
      //     let timeStatusFilter = filterList[changedColumnIndex].find((filter) => filter === 'upcoming' || filter === 'archived' || filter === 'ongoing');
      //     console.log('Found timeStatusFilter: ', timeStatusFilter);
      //     if (timeStatusFilter) {
      //       console.log('Should do a new database query');
      //     }
      //     employerWorkOrderStore.setBillingFilters(changedColumn, filterList[changedColumnIndex]);
      //   } else {
      //     // Reset
      //     employerWorkOrderStore.emptyFilters();
      //     employerWorkOrderStore.setBillingFilters('bill.status', []);
      //     employerWorkOrderStore.setBillingFilters('timeStatus', []);
      //     // SHOULD DO A NEW QUERY IF THERE WERE FILTERS
      //   }
      // },
      // onViewColumnsChange: (changedColumn, action) => {
      //   const columnIndex = columns.findIndex((col) => col.name === changedColumn);
      //   if (columnIndex !== -1) {
      //     if (action === 'remove') {
      //       employerWorkOrderStore.setBillingDisplayColumns(changedColumn, false);
      //     } else if (action === 'add') {
      //       employerWorkOrderStore.setBillingDisplayColumns(changedColumn, true);
      //     }
      //   }
      // },
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
            break;
          case 'changeRowsPerPage':
            this.changeRowsPerPage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
          case 'sort':
            if (tableState.sortOrder.name) {
              this.changeSorting(tableState.sortOrder.name, tableState.sortOrder.direction);
            }
            break;
          default:
            console.log('action not handled.');
        }
      },
    };

    return (
      <div
        style={{
          width: '100%',
          padding: '20px',
          // display: mobileMode ? 'none' : 'initial',
        }}
        className={`mui-billing-table ${mobileMode ? 'mobile-billing-table' : null}`}
      >
        <MUIDataTable
          data={workOrders}
          columns={columns}
          options={options}
        />
        <BillingStatusDialog handleClose={this.handleBillingStatusDialogClose} open={billingStatusDialogOpen} workOrderBilling={openBilling} />
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="view-heading">
          <span>Laskutus</span>
        </div>
        {this.renderBillingTable()}
      </>
    );
  }
}

export default EmployerBilling;

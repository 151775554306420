import { action, observable, set } from 'mobx';
import moment from '../../utils/moment';

const getFloat = (value) => {
  let newValue;
  if (typeof value === 'string') {
    newValue = Math.round(parseFloat(value || '0.0') * 100) / 100;
  }

  if (newValue === '' || newValue == null || Number.isNaN(newValue) || newValue === 0) {
    newValue = '';
  }

  return newValue;
};

// const getDate = (val) => (val != null ? dateWithoutTime(val) : val);

export default class UserProduct {
  @observable id;

  @observable createdAt;

  @observable updatedAt;

  @observable productId;

  @observable count;

  // @observable userProductCollectionId;

  @observable name;

  @observable _destroy;

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static toJson(o) {
    return {
      id: o.id,
      product_id: o.productId || o.product_id,
      count: o.count,
      // user_product_collection_id: o.userProductCollectionId,
      // status: (o.status == null) ? 'draft' : o.status,
      _destroy: (o._destroy == null) ? 0 : o._destroy,
    };
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    return Object.assign(this, data);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action changeFloatAttr(attr, value) {
    set(this, attr, getFloat(value));
  }

  @action changeAttr(attr, value) {
    set(this, attr, value);
  }

  @action updatePropertiesFromJson(o) {
    const updatedAt = moment(o.updated_at);

    // update content only if received more up to date content – this may happen e.g. when multiple updates are delayed due to slow network
    if (this.updatedAt != null && this.updatedAt.isAfter(updatedAt)) {
      return null;
    }

    const values = {
      id: o.id,
      name: o.name,
      productId: o.product_id,
      count: o.count,
      userProductCollectionId: o.user_product_collection_id,
    };
    return this.updateProperties(values);
  }

  static fromJsonProperties(object) {
    const userProduct = new UserProduct({});
    userProduct.updatePropertiesFromJson(object);
    return userProduct;
  }

  static fromJson(json) {
    return UserProduct.fromJsonProperties(JSON.parse(json));
  }
}

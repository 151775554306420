/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
} from '@rmwc/dialog';
import { TextField } from '@rmwc/textfield';
import { Grid, GridCell, GridInner } from '@rmwc/grid';
import { Button } from '@material-ui/core';
import { IconButton } from '@rmwc/icon-button';
import { MenuSurfaceAnchor, Menu, MenuItem } from '@rmwc/menu';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';

import AddIcon from '@material-ui/icons/Add';
import DeleteSweepOutlinedIcon from '@material-ui/icons/DeleteSweepOutlined';

import { cloneDeep } from 'lodash';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/layout-grid/dist/mdc.layout-grid.css';
import '@material/icon-button/dist/mdc.icon-button.css';
import '@rmwc/icon/icon.css';
import '@material/typography/dist/mdc.typography.css';

import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/list/dist/mdc.list.css';

import RouteLocation from './RouteLocation';
import TripRoute from './TripRoute';
import AutocompletableLocation from '../shared/AutocompletableLocation';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

const emptyFakeData = [];

@inject('timelogStore', 't', 'uiStore', 'globalSnackbarStore')
@observer
class TimelogRoute extends Component {
  constructor(props) {
    super(props);

    this.updateLocation = this.updateLocation.bind(this);
    this.updateRouteLocation = this.updateRouteLocation.bind(this);

    this.state = {
      // routeAddresses: [],
      showDeleteDialog: false,
      itemToDelete: [],
      deleteMessage: '',
      fakeData: emptyFakeData,
      matches: [],
      originalWorkHour: { ...props.workHour },
      saveButtonDisabled: true,
      loading: false,
    };
  }

  componentDidMount() {
    const { workHour } = this.props;
    const { uiStore: { currentUser } } = this.props;

    const foundAddresses = [...currentUser.autocompleteLocations];

    // Add a null check for workHour and tripRoutes
    if (workHour && workHour.tripRoutes) {
      if (workHour.tripRoutes.length === 0) {
        workHour.tripRoutes.push(this.addEmptyRoute());
      }

      workHour.tripRoutes.forEach((route) => {
        // TODO: This is stupid, should be a loop
        if (route.routeLocations.length === 0 && !route.id) {
          const location1 = this.addEmptyAddress(route.id);
          location1.order = 0;
          const location2 = this.addEmptyAddress(route.id);
          location2.order = 1;
          route.routeLocations.push(location1);
          route.routeLocations.push(location2);
          // addressCount.push(route.route_locations.length);
        } else if (route.routeLocations.length === 1) {
          const location2 = this.addEmptyAddress(route.id);
          location2.order = 1;
          route.routeLocations.push(location2);
          // addressCount.push(route.route_locations.length);
        } else if (route.routeLocations.length > 1) {
          // addressCount.push(route.route_locations.length);
        }
      });

      this.setState({
        fakeData: workHour.tripRoutes,
        autocompleteOptions: foundAddresses,
      });
      this.checkDisabledSaveButton(workHour.tripRoutes);
    } else {
      const newList = [];

      newList.push(new TripRoute(this.addEmptyRoute()));

      this.setState({
        fakeData: newList,
        autocompleteOptions: foundAddresses,
      });
      this.checkDisabledSaveButton(newList);
    }
  }

  addEmptyRoute = (tripRouteId = null) => (
    {
      kmsType: '',
      kms: '',
      id: tripRouteId,
      routeLocations: [{
        city: '',
        street: '',
        zipCode: '',
        tripRouteId,
      },
      {
        city: '',
        street: '',
        zipCode: '',
        tripRouteId,
      }],
    }
  )

  addEmptyAddress = (tripRouteId = null) => (
    {
      city: '',
      street: '',
      zipCode: '',
      tripRouteId,
    }
  )

  handleDeleteDialog = () => {
    const { fakeData } = this.state;

    // Empty and destroy the whole form
    const newFakeData = fakeData.map((route) => {
      // Mark each route and its locations for removal
      const updatedRoute = { ...route, _destroy: 1 };
      if (updatedRoute.routeLocations) {
        updatedRoute.routeLocations = updatedRoute.routeLocations.map((location) => ({
          ...location,
          _destroy: 1,
        }));
      }
      return updatedRoute;
    });

    this.setState({
      fakeData: newFakeData,
    }, () => {
      this.save();
    });
  };

  // eslint-disable-next-line react/sort-comp
  handleDeleteRoute(routeIndex) {
    const { fakeData } = this.state;

    const newFakeData = [...fakeData];
    // newFakeData.splice(routeIndex, 1);
    newFakeData[routeIndex]._destroy = 1;

    this.setState({
      fakeData: newFakeData,
    });

    this.checkDisabledSaveButton(newFakeData);
  }

  handleDeleteLocation(routeIndex, locationIndex) {
    const { fakeData } = this.state;

    const newFakeData = [...fakeData];
    // newFakeData[routeIndex].routeLocations.splice(locationIndex, 1);
    newFakeData[routeIndex].routeLocations[locationIndex]._destroy = 1;

    this.setState({
      fakeData: newFakeData,
    });

    this.checkDisabledSaveButton(newFakeData);
  }

  handleAddAddress(routeIndex) {
    const { fakeData } = this.state;

    const newFakeData = [...fakeData];
    newFakeData[routeIndex].routeLocations.push(new RouteLocation(this.addEmptyAddress()));

    this.setState({
      fakeData: newFakeData,
    });
    this.checkDisabledSaveButton(newFakeData);
  }

  addRoute(routeIndex) {
    const { fakeData } = this.state;

    const styleInner = {
      gap: '0px',
    };

    const styleInnerAdd = {
      gap: '0px',
      columnGap: '10px',
    };

    const addressList = [];

    // Could be turned into a for-of loop instead to simplify syntax
    for (let i = 0; i < fakeData[routeIndex].routeLocations.length; i += 1) {
      // if (i === 0) {
      //   addressList.push(<GridCell key={`divider-${i}`} span={12}>Aloituspiste</GridCell>);
      // }

      if (fakeData[routeIndex].routeLocations[i]._destroy !== 1) {
        // if (i === (fakeData[routeIndex].routeLocations.length - 1)) {
        //   addressList.push(<GridCell key={`divider-${i}`} span={12}>Määränpää</GridCell>);
        // }
        addressList.push(this.addAddress(fakeData[routeIndex], i, routeIndex));

        if (i !== (fakeData[routeIndex].routeLocations.length - 1)) {
          addressList.push(<GridCell key={`divider-${i}`} span={12} style={{ textAlign: 'center', color: 'var(--mdc-theme-primary)' }}>-</GridCell>);
        }
      }
    }

    return (
      <Grid
        key={routeIndex}
        className="route-grid"
      // style={style}
      >
        <GridInner
          style={styleInner}
        >
          <GridCell
            span={12}
            className="border-pseudo-top-margin"
            style={{
              margin: '15px 0 5px 0',
              paddingBottom: '5px',
            }}
          >
            <Typography
              use="subtitle1"
              style={{ paddingLeft: '10px' }}
            >
              {`Reitti ${routeIndex + 1}`}
              <IconButton
                icon="delete_outlined"
                className="route-delete-button"
                style={{ color: '#c7c9cc', fontSize: '24px', float: 'right' }}
                onClick={() => {
                  this.confirmDelete(routeIndex);
                }}
              />
            </Typography>
            {/* hack to remove autofocus on calendar */}
            <input style={{ width: '1px', opacity: '0' }} />
          </GridCell>
          <GridCell span={12}>
            <GridInner
              style={{ rowGap: '0px' }}
            >
              <GridCell desktop={6} phone={4}>
                {this.addDecimalField('kms', 'Kilometrimäärä *', { width: '100%' }, routeIndex)}
              </GridCell>
              <GridCell desktop={6} phone={4}>
                {this.addDescriptionField('description', 'Selite *',
                  { width: '100%', marginBottom: '1rem' }, routeIndex)}
              </GridCell>
            </GridInner>
          </GridCell>

          {addressList}

          <GridCell
            span={12}
            className="border-pseudo"
          >
            <GridInner
              style={styleInnerAdd}
              className="route-row"
            >
              <GridCell
                desktop={11}
                tablet={7}
                phone={6}
                className="route-address-info"
              >
                <GridInner
                  style={styleInner}
                >
                  <GridCell
                    span={12}
                  >
                    <Button
                      startIcon={<AddIcon />}
                      // style={{ marginTop: '1rem', marginBottom: '1rem' }}
                      style={{
                        margin: '1rem 0rem',
                        color: 'var(--mdc-theme-primary)',
                        border: '1px solid var(--mdc-theme-primary)',
                        borderRadius: '0px',
                        letterSpacing: '1.25px',
                      }}
                      onClick={() => this.handleAddAddress(routeIndex)}
                    >
                      Lisää osoite
                    </Button>
                  </GridCell>
                </GridInner>
              </GridCell>
            </GridInner>
          </GridCell>
        </GridInner>
      </Grid>
    );
  }

  doOnChangeRoute(attr, value, event, routeIndex) {
    const { fakeData, totalKms } = this.state;
    // This is confusing since it accepts strings that may be either a number (kms) or something else
    // Should probably do this more elegantly, so that we do not try to e.g. capitalize a number string
    const capitalizedValue = this.capitalizeString(value);

    if (attr === 'kms') {
      const reduceOldKms = totalKms - fakeData[routeIndex].kms;
      this.setState({
        // eslint-disable-next-line radix
        totalKms: reduceOldKms + Number.parseInt(capitalizedValue),
      });
    }

    const newFakeData = [...fakeData];
    const descToChange = newFakeData[routeIndex];
    descToChange[attr] = capitalizedValue;

    this.setState({
      fakeData: newFakeData,
    });
    this.checkDisabledSaveButton(newFakeData);
  }

  doOnChangeAddress(attr, value, routeIndex, locationIndex) {
    const { fakeData, autocompleteOptions } = this.state;
    const capitalizedValue = this.capitalizeString(value);

    const newFakeData = [...fakeData];
    const addressToChange = newFakeData[routeIndex].routeLocations[locationIndex];
    addressToChange[attr] = capitalizedValue;

    const stateName = `${attr}-${routeIndex}-${locationIndex}-open`;

    let results = [];
    let matches = [];

    if (attr === 'street') {
      results = autocompleteOptions.filter(
        (target) => capitalizedValue && target[attr].indexOf(capitalizedValue) === 0,
      );

      matches = results.map((result) => result[attr]);
    } else {
      results = autocompleteOptions.filter(
        (target) => capitalizedValue && target[attr] && target[attr].indexOf(capitalizedValue) === 0,
      );

      matches = [...new Set(results.map((q) => q[attr]))];
    }

    this.setState({
      fakeData: newFakeData,
      matches,
      matchObjects: results,
      [stateName]: results.length > 0,
    });
  }

  doOnSelect(attr, event, routeIndex, locationIndex) {
    const { fakeData, matches, matchObjects } = this.state;

    const newFakeData = [...fakeData];
    const addressToChange = newFakeData[routeIndex].routeLocations[locationIndex];
    addressToChange[attr] = matches[event.detail.index];

    if (attr === 'street' && matchObjects) {
      addressToChange.zipCode = matchObjects[event.detail.index].zipCode;
      addressToChange.city = matchObjects[event.detail.index].city;
    }

    newFakeData[routeIndex].routeLocations[locationIndex] = addressToChange;

    this.setState({
      fakeData: newFakeData,
    });
    this.checkDisabledSaveButton(newFakeData);
  }

  // eslint-disable-next-line class-methods-use-this
  capitalizeString(str) {
    const capitalizedStr = str.charAt(0).toUpperCase() + str.slice(1);
    return capitalizedStr;
  }

  doOnBlur() {
    const { fakeData } = this.state;
    this.checkDisabledSaveButton(fakeData);
    // const { workHour, timelogStore, kmsType, overwriteTripRoutes } = this.props;
    const { kmsType } = this.props;

    fakeData.forEach((tripRoute) => {
      // eslint-disable-next-line no-param-reassign
      tripRoute.kmsType = kmsType;
      tripRoute.routeLocations.forEach((routeLocation, index) => {
        // eslint-disable-next-line no-param-reassign
        routeLocation.locationOrder = index;
      });
    });
  }

  save() {
    const {
      workHour,
      timelogStore,
      toggleRouteDialog,
      employerContextTripId,
      employerUpdateMode,
      relayUpdatedWorkHours,
      workOrder,
    } = this.props;
    const { fakeData } = this.state;

    if (employerUpdateMode) {
      // Create an isolated copy of fakeData to cut off state updates, otherwise cleanUp() resets the _destroy parameters and prevents deleting locations
      const savingTripRoutes = cloneDeep(fakeData);

      // Employer timelog view dialog
      const totalKms = savingTripRoutes.filter((item) => item._destroy !== 1).reduce((accumulator, tripRoute) => accumulator + Number.parseFloat(tripRoute.kms || 0), 0);
      workHour.updateProperties({ tripRoutes: savingTripRoutes, updatedByEmployer: true, kms: totalKms });

      if (relayUpdatedWorkHours) {
        const updatedWorkHours = workOrder.workHours.filter((item) => (item.updatedByEmployer));
        relayUpdatedWorkHours(workOrder, updatedWorkHours, employerContextTripId);
      }
    } else {
      timelogStore.updateTripRoutes(workHour, fakeData, employerContextTripId);
    }
    // timelogStore.updateTripRoute(route, newHour.workOrderId, 'other', newHour.id, routeIndex);
    toggleRouteDialog();
  }

  // eslint-disable-next-line class-methods-use-this
  calculateKmsTotal(routes) {
    let kmsTotal = 0;
    routes.forEach((route) => {
      kmsTotal += !Number.isNaN(route.kms) ? Number(route.kms) : 0;
    });
    return kmsTotal;
  }

  addDecimalField(attr, label, styles = null, routeIndex, className = null) {
    const { disabled } = this.props;
    const { fakeData } = this.state;

    const handleKeyDown = (event) => {
      const keyCode = event.keyCode || event.which;
      const keyValue = String.fromCharCode(keyCode);

      // Check if the pressed key is a letter and prevent its input
      if (/[a-zA-Z]/.test(keyValue)) {
        event.preventDefault();
      }
    };

    return (
      <TextField {...{
        style: { ...styles },
        label,
        type: 'text',
        autoFocus: true,
        // required: true,
        disabled,
        pattern: '[0-9.,]*',
        onBlur: (event) => {
          // this.doOnBlur(attr, event.target.value, event);
          this.doOnBlur(attr, event.target.value, event, routeIndex, null, 'tripRoute');
        },
        onChange: (event) => {
          const { value } = event.target;
          this.doOnChangeRoute(attr, value, event, routeIndex);
        },
        rootProps: { ripple: false },
        theme: ['textPrimaryOnLight', 'secondaryBg'],
        onKeyPress: handleEnter,
        onKeyDown: handleKeyDown,
        className,
        value: (this.state != null && fakeData[routeIndex][attr] != null && !Number.isNaN(fakeData[routeIndex][attr])) ? fakeData[routeIndex][attr] : '',
      }}
      />
    );
  }

  addDescriptionField(attr, label, styles = null, routeIndex, className = null) {
    const { disabled } = this.props;
    const { fakeData } = this.state;

    return (
      <TextField {...{
        style: { ...styles },
        label,
        // required: true,
        disabled,
        onBlur: (event) => {
          this.doOnBlur(attr, event.target.value, event, routeIndex, null, 'tripRoute');
        },
        onChange: (event) => {
          const { value } = event.target;
          this.doOnChangeRoute(attr, value, event, routeIndex);
        },
        rootProps: { ripple: false },
        theme: ['textPrimaryOnLight', 'secondaryBg'],
        onKeyPress: handleEnter,
        className,
        value: fakeData[routeIndex][attr] != null ? fakeData[routeIndex][attr] : '',
      }}
      />
    );
  }

  updateLocation(tripRoute, routeIndex) {
    const { fakeData } = this.state;

    const newFakeData = [...fakeData];
    newFakeData[routeIndex] = tripRoute;

    this.setState({
      fakeData: newFakeData,
    }, () => {
      this.checkDisabledSaveButton(newFakeData);
    });
  }

  updateRouteLocation(routeIndex, locationIndex, location) {
    const { fakeData } = this.state;

    const newFakeData = [...fakeData];
    newFakeData[routeIndex].routeLocations[locationIndex] = location;

    this.setState({
      fakeData: newFakeData,
    }, () => {
      this.checkDisabledSaveButton(newFakeData);
    });
  }

  addTextField(attr, label, styles = null, routeIndex, locationIndex, className = null) {
    const { disabled } = this.props;
    const { fakeData, matches, matchObjects } = this.state;

    const stateName = `${attr}-${routeIndex}-${locationIndex}-open`;

    const ref = React.createRef();

    return (
      <>
        <MenuSurfaceAnchor>
          <Menu
            /* eslint-disable-next-line react/destructuring-assignment */
            open={this.state[stateName]}
            anchorCorner="bottomLeft"
            style={{
              width: '100vw',
              maxWidth: '100%',
            }}
            onClose={() => this.setState({
              [stateName]: false,
            })}
            onSelect={(event) => {
              this.doOnSelect(attr, event, routeIndex, locationIndex);
            }}
            focusOnOpen={false}
            ref={ref}
          >
            {matches.length > 0 && attr !== 'street' && matches.slice(0, 5).map((match, index) => (
              <MenuItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                style={{ color: 'white', textAlign: 'center' }}
                theme={['textPrimaryOnDark', 'secondaryBg']}
              >
                {match}
              </MenuItem>
            ))}

            {matches.length > 0 && attr === 'street' && matchObjects.slice(0, 5).map((matchObj, index) => (
              <MenuItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                style={{ color: 'white', textAlign: 'center' }}
                theme={['textPrimaryOnDark', 'secondaryBg']}
              >
                {`${matchObj?.street}, ${matchObj?.zipCode != null ? matchObj?.zipCode : ''} ${matchObj?.city != null ? matchObj?.city : ''}`}
              </MenuItem>
            ))}
          </Menu>
          <TextField {...{
            style: { ...styles },
            label,
            // required: true,
            disabled,
            onChange: (event) => {
              const { value } = event.target;
              this.doOnChangeAddress(attr, value, routeIndex, locationIndex);
            },
            // Apparently this enables selecting
            onKeyDown: (event) => {
              if (event.key === 'ArrowDown' && matches.length > 0) {
                ref.current.foundation.adapter_.focusItemAtIndex(0);
              }
            },
            onBlur: (event) => {
              this.doOnBlur(attr, event.target.value, event, routeIndex, locationIndex, 'routeLocation');
            },
            rootProps: { ripple: false },
            theme: ['textPrimaryOnLight', 'secondaryBg'],
            onKeyPress: handleEnter,
            className,
            value: fakeData[routeIndex].routeLocations[locationIndex][attr] != null ? fakeData[routeIndex].routeLocations[locationIndex][attr] : '',
          }}
          />
        </MenuSurfaceAnchor>
      </>
    );
  }

  checkDisabledSaveButton(fakeData) {
    let disabled = true;
    const filteredRouteList = fakeData.filter((tripRoute) => tripRoute._destroy !== 1);

    // If no routes -> Enable save. User can delete routes and save.
    if (filteredRouteList.length === 0) {
      disabled = false;
    }

    filteredRouteList.forEach((tripRoute) => {
      const routeLocations = tripRoute.routeLocations.filter((item) => item._destroy !== 1);
      const filledRouteLocations = routeLocations.filter((routeLocation) => routeLocation.street && routeLocation.city);

      // Check if kms and description and at least two addresses are filled -> Enable save button.
      // Second part after OR checks if kms and description are filled but there are no addresses -> Enable save button
      if ((tripRoute.kms && tripRoute.description && filledRouteLocations.length >= 2) || (tripRoute.kms && tripRoute.description && filledRouteLocations.length === 0)) {
        disabled = false;
      }
    });

    this.setState({ saveButtonDisabled: disabled });
  }

  handleAddRoute() {
    const { fakeData } = this.state;

    const newFakeData = [...fakeData];
    const tripRouteId = null;
    newFakeData.push(new TripRoute(this.addEmptyRoute(tripRouteId)));

    this.setState({
      fakeData: newFakeData,
    });

    this.checkDisabledSaveButton(newFakeData);
  }

  generateQuickAddressOptions() {
    const { workOrder, uiStore: { currentUser } } = this.props;
    const quickAddresses = {};

    if (workOrder.accomodationCity && workOrder.accomodationStreet) {
      quickAddresses.accomodation = [{
        name: 'Majoitus',
        city: workOrder.accomodationCity,
        street: workOrder.accomodationStreet,
        zipCode: workOrder.accomodationZipCode,
      }];
    }

    quickAddresses.location = [{
      name: 'Kohde',
      city: workOrder.location.city,
      street: workOrder.location.street,
      zipCode: workOrder.location.zip_code,
      defaultDepartureAddress: true,
    }];

    const homeAddress = currentUser.homeAddress();
    if (homeAddress) {
      quickAddresses.home = [homeAddress];
    }

    currentUser.accountInfo.companyLocations.forEach((companyLocation) => {
      if (!quickAddresses.companyLocation) {
        quickAddresses.companyLocation = [];
      }
      quickAddresses.companyLocation.push({
        name: companyLocation.name,
        city: companyLocation.city,
        street: companyLocation.street,
        zipCode: companyLocation.zip_code,
      });
    });

    return quickAddresses;
  }

  addAddress(tripRoute, locationIndex, routeIndex) {
    const { autocompleteOptions } = this.state;
    const styleInner = {
      rowGap: '0px',
      columnGap: '10px',
    };

    const quickAddresses = this.generateQuickAddressOptions();
    
    // Check if this location has address data
    const location = tripRoute.routeLocations[locationIndex];
    const hasAddressData = location && location.street && location.city;
    
    // Determine if we should force manual input mode
    const forceManualInput = location && location._manualAddressInputActive;

    return (
      <GridCell
        span={12}
        key={locationIndex}
      >
        <GridInner
          style={styleInner}
          className="route-row"
        >
          <GridCell
            desktop={11}
            tablet={7}
            phone={6}
            className="route-address-info"
          >
            <AutocompletableLocation
              key={`${routeIndex}-${locationIndex}-${hasAddressData ? 'with-data' : 'empty'}`}
              locationIndex={locationIndex}
              locationLabel="Katuosoite"
              selectedQuickAddressLabel="Osoite"
              tripRoute={[tripRoute]}
              autocompleteOptions={autocompleteOptions}
              updateLocation={this.updateLocation}
              updateRouteLocation={this.updateRouteLocation}
              quickAddresses={quickAddresses || {}}
              routeIndex={routeIndex}
              forceManualInput={forceManualInput}
            />
          </GridCell>
          <GridCell
            desktop={1}
            tablet={1}
            phone={1}
            style={{ alignSelf: 'center', textAlign: 'center' }}
          >
            <IconButton
              icon="delete_outlined"
              className="route-delete-button"
              style={{ color: '#c7c9cc', fontSize: '24px' }}
              onClick={() => this.confirmDelete(routeIndex, locationIndex)}
            />

          </GridCell>
        </GridInner>
      </GridCell>
    );
  }

  confirmDelete(routeIndex = null, locationIndex = null) {
    const { fakeData } = this.state;

    if (routeIndex !== null && locationIndex !== null) {
      const address = fakeData[routeIndex].routeLocations[locationIndex].street;

      this.setState({
        showDeleteDialog: true,
        itemToDelete: [routeIndex, locationIndex],
        deleteMessage: address ? `Haluatko varmasti poistaa osoiterivin ${address}?` : 'Haluatko varmasti poistaa osoiterivin?',
      });
    } else if (routeIndex !== null && locationIndex === null) {
      this.setState({
        showDeleteDialog: true,
        itemToDelete: [routeIndex],
        deleteMessage: 'Haluatko varmasti poistaa reitin?',
      });
    } else {
      this.setState({
        showDeleteDialog: true,
        deleteMessage: 'Haluatko varmasti tyhjentää kaikki kilometrikorvaustiedot?',
      });
    }
  }

  cleanUp() {
    const { fakeData } = this.state;
    fakeData.forEach((route) => {
      // eslint-disable-next-line no-param-reassign
      route._destroy = 0;
      route.routeLocations.forEach((routeLocation) => {
        // eslint-disable-next-line no-param-reassign
        routeLocation._destroy = 0;
      });
    });
  }

  hasCopyableRoutes = () => {
    const {
      workHour,
      workOrder,
    } = this.props;

    if (!workHour || !workOrder) {
      return false;
    }

    // Get the previous day's date
    const previousDate = workHour.date.clone().subtract(1, 'days');
    const dateFormat = 'YYYY-MM-DD';
    const previousDateFormatted = previousDate.format(dateFormat);

    // Find the previous day's work hour entry
    const previousDayWorkHour = workOrder.workHours.find(
      (wh) => wh.date.format(dateFormat) === previousDateFormatted
              && wh.tripRoutes
              && wh.tripRoutes.length > 0
              && wh.tripRoutes.some((route) => !route._destroy),
    );

    // Check if there are any routes available in the previous day
    return !!previousDayWorkHour;
  }

  copyRoutesFromPreviousDay = () => {
    const {
      workHour,
      workOrder,
      globalSnackbarStore,
    } = this.props;

    // Show loading indicator
    this.setState({ loading: true });

    // Get the previous day's date
    const previousDate = workHour.date.clone().subtract(1, 'days');
    const dateFormat = 'YYYY-MM-DD';
    const previousDateFormatted = previousDate.format(dateFormat);

    // Find the previous day's work hour entry
    const previousDayWorkHour = workOrder.workHours.find(
      (wh) => wh.date.format(dateFormat) === previousDateFormatted
              && wh.tripRoutes
              && wh.tripRoutes.length > 0
              && wh.tripRoutes.some((route) => !route._destroy),
    );

    if (previousDayWorkHour && previousDayWorkHour.tripRoutes) {
      try {
        // Deep clone the routes to avoid reference issues
        const routesToCopy = cloneDeep(
          previousDayWorkHour.tripRoutes.filter((route) => !route._destroy),
        );

        // Reset IDs to create new entries
        routesToCopy.forEach((route) => {
          // Reset the route ID
          route.id = null;
          
          if (route.routeLocations) {
            // Filter out destroyed locations and reset IDs
            route.routeLocations = route.routeLocations
              .filter(location => location._destroy !== 1)
              .map(location => {
                // Create a new location object with reset IDs
                return {
                  ...location,
                  id: null,
                  tripRouteId: null,
                  // Force these to be strings to avoid null values
                  street: location.street || '',
                  city: location.city || '',
                  zipCode: location.zipCode || '',
                  // Add a special property to indicate this is a copied address
                  _isCopied: true,
                  // Force manual input for copied addresses
                  _manualAddressInputActive: true
                };
              });
          }
        });

        // Update the state with the copied routes
        this.setState({
          fakeData: routesToCopy,
          loading: false,
          saveButtonDisabled: false,
        }, () => {
          // Show success message
          if (globalSnackbarStore) {
            globalSnackbarStore.openSnackbar(`Reitit kopioitu päivältä ${previousDate.format('dd DD.MM.')}`);
          }
        });
      } catch (error) {
        console.error('Error copying routes:', error);
        this.setState({ loading: false });
        if (globalSnackbarStore) {
          globalSnackbarStore.openSnackbar('Virhe reittien kopioinnissa');
        }
      }
    } else {
      this.setState({ loading: false });
      if (globalSnackbarStore) {
        globalSnackbarStore.openSnackbar('Ei löytynyt reittejä edelliseltä päivältä');
      }
    }
  }

  renderDescription() {
    const { t, kmsType } = this.props;
    const { loading } = this.state;

    // Check if there are routes available to copy
    const hasCopyableRoutes = this.hasCopyableRoutes();

    return (
      <GridCell
        style={{
          backgroundColor: 'var(--mdc-theme-surface, #fff)',
          padding: '15px 10px',
          margin: '5px -10px',
          fontSize: '14px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >

        {hasCopyableRoutes && (
          <Button
            className="employee-accept-button-outlined"
            onClick={this.copyRoutesFromPreviousDay}
            disabled={loading}
            style={{ 
              width: 'auto', // Only take the space needed for content
              minHeight: '48px',
              padding: '8px 16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 'auto'
            }}
          >
            <div style={{
              display: 'flex',
              alignItems: 'center', // Ensure vertical centering
              justifyContent: 'center',
            }}>
              <Icon icon="flip_to_front" style={{ 
                transform: 'rotate(90deg)',
                marginRight: '8px',
                display: 'flex',
                alignItems: 'center',
              }} />
              <span>
                {loading ? 'Kopioidaan...' : 'Kopioi eiliseltä'}
              </span>
            </div>
          </Button>
        )}
      </GridCell>
    );
  }

  renderRoutes() {
    const { fakeData } = this.state;

    const routes = [];

    if (fakeData && fakeData.length > 0) {
      for (let i = 0; i < fakeData.length; i += 1) {
        if (fakeData[i]._destroy !== 1) {
          routes.push(this.addRoute(i));
        }
      }
    }

    routes.push(
      <GridCell
        key={routes.length}
      >
        <Button
          style={{
            margin: '1rem 0rem',
            color: 'var(--mdc-theme-primary)',
            border: '1px solid var(--mdc-theme-primary)',
            borderRadius: '0px',
            width: '100%',
            letterSpacing: '1.25px',
          }}
          startIcon={<AddIcon />}
          onClick={() => this.handleAddRoute()}
        >
          {`Lisää reitti ${routes.length + 1}`}
        </Button>
      </GridCell>,
    );

    return routes;
  }

  render() {
    const { routeDialogOpen, toggleRouteDialog, employerUpdateMode } = this.props;
    const {
      showDeleteDialog,
      deleteMessage,
      itemToDelete,
      saveButtonDisabled,
    } = this.state;

    return (
      <>
        <Dialog
          className={employerUpdateMode ? 'employer-trip-route-dialog trip-route-dialg' : 'trip-route-dialog'}
          open={routeDialogOpen}
          preventOutsideDismiss
          // onClose={(evt) => {
          //   evt.stopPropagation();
          //   console.error('KMS DIALOG ONCLOSE');
          //   this.cleanUp();
          //   // toggleRouteDialog was causing problems with reopening the dialog when clearing or saving because it's also used in save()
          //   // Here only called if we cancel
          //   if (evt.detail.action === 'cancel') {
          //     toggleRouteDialog();
          //   }
          // }}
        >
          <DialogTitle
            style={{
              color: 'var(--mdc-theme-primary)',
              padding: '5px 10px 0px',
              marginTop: '5px',
              display: 'flex',
              alignItems: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <span style={{ flex: 1 }}>Kilometrikorvaukset</span>
            {this.state.fakeData && this.state.fakeData.some(route => route._destroy !== 1) && (
              <Button
                style={{
                  color: '#c7c9cc',
                  lineHeight: 'normal',
                  letterSpacing: '1.25px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => this.confirmDelete()}
                autoFocus={false}
                className="mdc-button"
              >
                <DeleteSweepOutlinedIcon style={{ marginRight: '8px' }} />
                Tyhjennä
              </Button>
            )}
          </DialogTitle>
          <DialogContent
            style={{ color: 'white' }}
          >
            {this.renderDescription()}
            {/* <h3>Lisää kilometrimäärä, selite ja reitti {t(`timelog.attributes.${kmsType}`)}</h3> */}
            {this.renderRoutes()}
          </DialogContent>
          <DialogActions
            style={{
              justifyContent: 'space-between',
              padding: '15px',
            }}
          >
            <DialogButton
              className="accept-button"
              style={{
                borderRadius: '0px',
              }}
              onClick={() => {
                this.save();
                this.cleanUp();
              }}
              disabled={saveButtonDisabled}
              // action="close"
            >
              Tallenna
            </DialogButton>

            <DialogButton
              style={{
                color: '#c7c9cc',
                border: '1px solid #c7c9cc',
                borderRadius: '0px',
              }}
              // action="cancel"
              onClick={() => {
                this.cleanUp();
                toggleRouteDialog();
              }}
            >
              Peruuta
            </DialogButton>
          </DialogActions>
        </Dialog>

        {showDeleteDialog && (
          <Dialog
            className="dialog-background mini-dialog employer-trip-route-dialog"
            open={showDeleteDialog}
            preventOutsideDismiss
            // onClose={(evt) => {
            //   console.log('EVT: ', evt);
            //   evt.stopPropagation();
            //   evt.stopImmediatePropagation();
            //   evt.preventDefault();
            //   console.error('DELETE DIALOG ONCLOSE: ', evt.isPropagationStopped);
            //   if (evt.detail.action === 'accept') {
            //     if (itemToDelete.length === 2) {
            //       console.log('Deleting location');
            //       this.handleDeleteLocation(itemToDelete[0], itemToDelete[1]);
            //     } else if (itemToDelete.length === 1) {
            //       console.log('Deleting route');
            //       this.handleDeleteRoute(itemToDelete[0]);
            //     } else {
            //       console.log('Deleting all?');
            //       this.handleDeleteDialog();
            //     }
            //   }
            //   this.setState({
            //     showDeleteDialog: false,
            //     itemToDelete: [],
            //     deleteMessage: '',
            //   });
            // }}
          >
            <DialogContent
              style={{ color: 'white' }}
            >
              <p>{deleteMessage}</p>
            </DialogContent>
            <DialogActions
              style={{
                justifyContent: 'space-between',
                padding: '15px',
              }}
            >
              <DialogButton
                className="accept-button"
                style={{
                  borderRadius: '0px',
                }}
                // action="accept"
                onClick={() => {
                  if (itemToDelete.length === 2) {
                    this.handleDeleteLocation(itemToDelete[0], itemToDelete[1]);
                  } else if (itemToDelete.length === 1) {
                    this.handleDeleteRoute(itemToDelete[0]);
                  } else {
                    this.handleDeleteDialog();
                  }
                  this.setState({
                    showDeleteDialog: false,
                    itemToDelete: [],
                    deleteMessage: '',
                  });
                }}
              >
                Kyllä
              </DialogButton>
              <DialogButton
                style={{
                  color: '#c7c9cc',
                  border: '1px solid #c7c9cc',
                  borderRadius: '0px',
                }}
                // action="close"
                onClick={() => {
                  this.setState({
                    showDeleteDialog: false,
                    itemToDelete: [],
                    deleteMessage: '',
                  });
                }}
              >
                Peruuta
              </DialogButton>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
}

export default TimelogRoute;

import { action, observable, set } from 'mobx';
import moment, { dateWithoutTime } from '../../utils/moment';
import UserProduct from './UserProduct';

const getFloat = (value) => {
  let newValue;
  if (typeof value === 'string') {
    newValue = Math.round(parseFloat(value || '0.0') * 100) / 100;
  }

  if (newValue === '' || newValue == null || Number.isNaN(newValue) || newValue === 0) {
    newValue = '';
  }

  return newValue;
};

const getDate = (val) => (val != null ? dateWithoutTime(val) : val);

// const getDate = (val) => (val != null ? dateWithoutTime(val) : val);

export default class UserProductCollection {
  @observable id;

  @observable createdAt;

  @observable updatedAt;

  @observable description;

  @observable workOrderId;

  @observable files;

  @observable userProducts;

  @observable workTaskEntryId = null;

  // @observable status;

  @observable _destroy;

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static toJson(o) {
    const payload = {
      id: o.id,
      work_order_id: o.workOrderId,
      work_task_entry_id: o.workTaskEntryId,
      description: o.description,
      date: o.date,
      user_products_attributes: o.userProducts.map((userProduct) => UserProduct.toJson(userProduct)),
      files: o.files,
      _destroy: (o._destroy == null) ? 0 : o._destroy,
    };
    return payload;
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    return Object.assign(this, data);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action changeFloatAttr(attr, value) {
    set(this, attr, getFloat(value));
  }

  @action changeAttr(attr, value) {
    set(this, attr, value);
  }

  @action updatePropertiesFromJson(o) {
    const updatedAt = moment(o.updated_at);

    // update content only if received more up to date content – this may happen e.g. when multiple updates are delayed due to slow network
    if (this.updatedAt != null && this.updatedAt.isAfter(updatedAt)) {
      return null;
    }

    const values = {
      id: o.id,
      workOrderId: o.work_order_id,
      workTaskEntryId: o.work_task_entry_id,
      description: o.description,
      date: getDate(o.date),
      files: o.files.map((file) => ({
        filename: file.filename,
        fileURL: file.url,
        signedId: file.signed_id,
        createdAt: file.created_at,
      })),
      // status: o.status == null ? 'draft' : o.status,
    };

    let userProducts = [];
    if (o.user_products != null && o.user_products.length > 0) {
      userProducts = o.user_products.map((item) => UserProduct.fromJsonProperties(item));
    }
    values.userProducts = userProducts;

    return this.updateProperties(values);
  }

  static fromJsonProperties(object) {
    const userProductCollection = new UserProductCollection({});
    userProductCollection.updatePropertiesFromJson(object);
    return userProductCollection;
  }

  static fromJson(json) {
    return UserProductCollection.fromJsonProperties(JSON.parse(json));
  }
}

/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { TextField } from '@rmwc/textfield';
import { Grid, GridCell } from '@rmwc/grid';
import {
  FormControl,
  TextField as MuiTextField,
  ThemeProvider,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  Dialog, DialogTitle, DialogActions, DialogButton, DialogContent,
} from '@rmwc/dialog';

import theme from '../muiTheme';

const handleEnter = (e) => {
  if (e.key === 'Enter' && document.activeElement.tagName !== 'TEXTAREA') {
    document.activeElement.blur();
  }
};

const nameValidate = (name) => {
  if (!name) {
    return 'Kohteen nimi on pakollinen';
  }
  return null;
};

const companyValidate = (company) => {
  if (!company) {
    return 'Yrityksen nimi on pakollinen';
  }
  return null;
};

const locationValidate = (location) => {
  if (!location) {
    return 'Kuvaus on pakollinen';
  }
  return null;
};


const streetValidate = (street) => {
  if (!street) {
    return 'Katuosoite on pakollinen';
  }
  return null;
};

const cityValidate = (city) => {
  if (!city) {
    return 'Paikkakunta on pakollinen';
  }
  return null;
};

const validate = {
  // attachments: (description, files) => descriptionOrFileValidation(description, files),
  name: (name) => nameValidate(name),
  company: (company) => companyValidate(company),
  location: (location) => locationValidate(location),
  street: (street) => streetValidate(street),
  city: (city) => cityValidate(city),
};

@inject('locationStore', 'loginStore', 't')
@observer
class LocationDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.location,
      name: props.location?.name || props.dialogValue || '',
      errors: {},
      inputChanged: false,
    };
  }

  componentDidMount() {
    const { locationStore } = this.props;

    // Fetch countries when component mounts
    locationStore.getCountries();

    setTimeout(() => {
      const textareas = [...document.getElementsByTagName('textarea')];
      textareas.forEach((textarea) => {
        /* eslint-disable-next-line no-param-reassign */
        textarea.style.height = `${textarea.scrollHeight}px`;
      });
    }, 0);
  }

  componentDidUpdate(prevProps) {
    const { location, dialogValue, open } = this.props;

    // Check if the dialog has just been opened
    if (!prevProps.open && open) {
      /* eslint-disable-next-line react/no-did-update-set-state */
      this.setState({
        id: location?.id || '',
        name: dialogValue || location?.name || '',
        company: location?.company || '',
        location: location?.location || '',
        street: location?.street || '',
        zipCode: location?.zipCode || '',
        city: location?.city || '',
        additionalInfo: location?.additionalInfo || '',
        countryId: location?.countryId || '',
        errors: {},
        inputChanged: false,
      });
    } else if (prevProps.location?.id !== location?.id) {
      // Changed the first, original case to here
      /* eslint-disable-next-line react/no-did-update-set-state */
      this.setState({
        id: location?.id || '',
        name: dialogValue || location?.name || '',
        company: location?.company || '',
        location: location?.location || '',
        street: location?.street || '',
        zipCode: location?.zipCode || '',
        city: location?.city || '',
        additionalInfo: location?.additionalInfo || '',
        countryId: location?.countryId || '',
        errors: {},
      });
    }
  }

  // Even though this could be static it would be odd to call a static method for something like this
  // Maybe move this to an upper level and reuse in components
  /* eslint-disable-next-line class-methods-use-this */
  textareaResize(evt) {
    // Need to reset the height to 1px since the minimum scrollHeight is the element height,
    // meaning we need to reset the height to recalculate the scrollHeight or it won't stop growing
    /* eslint-disable-next-line no-param-reassign */
    evt.target.style.height = '1px';
    /* eslint-disable-next-line no-param-reassign */
    evt.target.style.height = `${evt.target.scrollHeight}px`;
  }

  handleInputChange(field, value) {
    const { errors } = this.state;
    this.setState({
      [field]: value,
      inputChanged: true,
    });

    // Clear the error for this field if it exists
    if (errors[field]) {
      const updatedErrors = { ...errors };
      delete updatedErrors[field];
      this.setState({ errors: updatedErrors });
    }
  }

  addTextField(attr, label, required, styles, textarea, extraOnChange) {
    const { errors } = this.state;

    return (
      <TextField {...{
        style: { ...styles },
        className: 'textarea-hack',
        label,
        required,
        name: attr,
        // rows: 5,
        invalid: errors[attr],
        helpText: errors[attr] && errors[attr],
        type: 'text',
        textarea,
        onChange: (event) => {
          const { value } = event.target;
          this.handleInputChange(attr, value);

          if (extraOnChange) {
            extraOnChange(event);
          }
        },
        rootProps: { ripple: false },
        onKeyPress: handleEnter,
        /* eslint-disable-next-line react/destructuring-assignment */
        value: this.state[attr] != null ? this.state[attr] : '',
      }}
      />
    );
  }

  renderCountryDropdown() {
    const { locationStore, t } = this.props;
    const { countryId, errors } = this.state;

    // If countries are still loading, show a loading message
    if (locationStore.countries.state === 'pending') {
      return (
        <MuiTextField
          label="Maa"
          value="Lataa maat..."
          disabled
          fullWidth
          margin="normal"
          variant="filled"
        />
      );
    }

    // If countries have loaded, show the Autocomplete dropdown
    if (locationStore.countries.state === 'fulfilled') {
      const options = locationStore.countries.value.map((country) => ({
        label: country.country_name,
        value: country.id.toString(),
      }));
      
      return (
        <ThemeProvider theme={theme}>
          <FormControl fullWidth margin="normal" variant="outlined" error={!!errors.countryId}>
            <Autocomplete
              id="country-select"
              options={options}
              getOptionLabel={(option) => option.label}
              value={options.find(option => option.value === countryId) || null}
              onChange={(event, newValue) => {
                this.handleInputChange('countryId', newValue ? newValue.value : '');
              }}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label="Maa"
                  variant="filled"
                  error={!!errors.countryId}
                  helperText={errors.countryId}
                  style={{ backgroundColor: '#FFFFFF' }}
                />
              )}
            />
          </FormControl>
        </ThemeProvider>
      );
    }
    
    // If there was an error loading countries, show an error message
    return (
      <MuiTextField
        label="Maa"
        value={t('common.error')}
        disabled
        fullWidth
        margin="normal"
        variant="filled"
        error
        helperText={t('common.errorLoadingData')}
      />
    );
  }

  renderActionButtons() {
    const {
      inputChanged,
      name,
    } = this.state;
    const { id } = this.props;

    if (!id && !name) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      );
    }
    if (!id && name) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            // onClick={() => {
            //   this.handleValidate(name);
            // }}
            action={name ? 'accept' : null}
          >
            Tallenna
          </DialogButton>

          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      );
    }
    if (id && inputChanged) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            // onClick={() => {
            //   this.handleValidate(name);
            // }}
            action={name ? 'accept' : null}
          >
            Tallenna
          </DialogButton>
          {/* <DialogButton
              type="button"
              style={{
                color: '#c7c9cc',
                border: '1px solid #c7c9cc',
                borderRadius: '0px',
                minWidth: '100px',
              }}
              action="cancel"
            >
              Peruuta
            </DialogButton> */}
        </DialogActions>
      );
    }
    if (id && !inputChanged) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      );
    }
    return null;
  }

  render() {
    const {
      open,
      handleClose,
      saveLocation,
      location,
    } = this.props;

    const {
      name,
      company,
      street,
      city,
      countryId,
    } = this.state;

    const styles = { backgroundColor: '#FFFFFF', width: '100%', margin: '0.3em 0' };

    return (
      <Dialog
        className="modal-size employer-modal"
        open={open}
        onClose={(evt) => {
          if (evt.detail.action === 'accept') {
            let errors = {};

            Object.keys(validate).forEach((key) => {
              /* eslint-disable-next-line react/destructuring-assignment */
              const value = this.state[key];

              // Calling the attr's validation method
              const error = validate[key](value);

              if (error) {
                errors = { ...errors, [key]: error };
              }
            });
            // const error = validate.attachments(description);
            // if (!error) {
            //   saveLocation({ ...this.state, id });
            //   // CALL A CALLBACK (PROP) THAT RETURNS THE SAVED LOCATION HERE?
            //   // OR IN HANDLE ATTACHMENT ACTION?
            //   handleClose();
            // }

            if (Object.keys(errors).length > 0) {
              this.setState({ errors });
            } else {
              // Transform the fields to start with uppercase
              const transformedData = {
                ...this.state,
                name: name.charAt(0).toUpperCase() + name.slice(1),
                company: company.charAt(0).toUpperCase() + company.slice(1),
                street: street.charAt(0).toUpperCase() + street.slice(1),
                city: city.charAt(0).toUpperCase() + city.slice(1),
                country_id: countryId, // Map countryId to country_id for API compatibility
                id: location?.id,
              };
              saveLocation(transformedData);
              handleClose();
            }
          } else {
            handleClose();
          }
        }}
      >
        <DialogTitle>
          { location?.id ? 'Muokkaa kohdetta' : 'Uusi kohde' }
        </DialogTitle>
        <DialogContent>
          <Grid>
            <GridCell span={12} className="employer-work-order">
              {this.addTextField('name', 'Nimi', true, styles, false)}
            </GridCell>
            <GridCell span={12} className="employer-work-order">
              {this.addTextField('company', 'Yritys', true, styles, false)}
            </GridCell>

            <GridCell span={12} className="employer-work-order">
              {this.addTextField('location', 'Kuvaus', true, styles, true, this.textareaResize)}
            </GridCell>

            <GridCell span={12} className="employer-work-order">
              {this.addTextField('street', 'Katuosoite', true, styles, false)}
            </GridCell>

            <GridCell span={6} className="employer-work-order">
              {this.addTextField('zipCode', 'Postinumero', false, styles, false)}
            </GridCell>
            <GridCell span={6} className="employer-work-order">
              {this.addTextField('city', 'Paikkakunta', true, styles, false)}
            </GridCell>

            <GridCell span={12} className="employer-work-order">
              {this.renderCountryDropdown()}
            </GridCell>

            <GridCell span={12} className="employer-work-order">
              {this.addTextField('additionalInfo', 'Lisätietoja', false, styles, true, this.textareaResize)}
            </GridCell>
          </Grid>
        </DialogContent>
        {this.renderActionButtons()}
      </Dialog>
    );
  }
}

export default LocationDialog;

import { action, observable, set } from 'mobx';
import moment from '../utils/moment';
// import TripRoute from './TripRoute';

const getFloat = (value) => {
  let newValue;
  if (typeof value === 'string') {
    newValue = Math.round(parseFloat(value || '0.0') * 100) / 100;
  }

  if (newValue === '' || newValue == null || Number.isNaN(newValue) || newValue === 0) {
    newValue = '';
  }

  return newValue;
};

// List of hardcoded allowance keys that should never have a db_ prefix
const HARDCODED_ALLOWANCE_KEYS = [
  'none', 'full', 'half', 'meal', 'two_meals', 'full_plus_2',
  'full_plus_10', 'full_half', 'se', 'se_double', 'es', 'fr',
  'pt', 'za',
];

// const getDate = (val) => (val != null ? dateWithoutTime(val) : val);

export default class TaxExemptTripExpense {
  @observable id;

  // @observable createdAt;

  // @observable updatedAt;

  @observable status;

  @observable name;

  @observable value;

  @observable employerComment;

  // @observable tripRoutes;

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static toJson(o) {
    // For allowance values, we need special handling
    let valueToSend = o.value;

    // If this is an allowance tax exempt trip expense
    if (o.name === 'allowance' && o.value) {
      // Check if this is a database allowance (starts with db_)
      const isDbAllowance = o.value.toString().startsWith('db_');

      if (isDbAllowance) {
        // For database allowances (with db_ prefix), check if the ID matches a hardcoded key
        // This handles the case where a database allowance has the same ID as a hardcoded key
        const dbId = o.value.toString().substring(3); // Remove 'db_' prefix

        if (HARDCODED_ALLOWANCE_KEYS.includes(dbId)) {
          // If it's actually a hardcoded allowance value, send without prefix
          valueToSend = dbId;
        } else {
          // Otherwise it's a real database allowance
          // Keep full db_ format for actual database allowances
          valueToSend = o.value;
        }
      } else if (HARDCODED_ALLOWANCE_KEYS.includes(o.value)) {
        // It's a hardcoded allowance without prefix, keep it as is
        valueToSend = o.value;
      } else {
        // For any other values, keep as is
        valueToSend = o.value;
      }
    }

    return {
      id: o.id,
      name: o.name,
      value: valueToSend,
      // trip_routes_attributes: o.tripRoutes?.map((tripRoute) => TripRoute.toJson(tripRoute)),
    };
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    return Object.assign(this, data);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action changeFloatAttr(attr, value) {
    set(this, attr, getFloat(value));
  }

  @action changeAttr(attr, value) {
    set(this, attr, value);
  }

  //
  // NOTE: Shouldn't need to be to be used now, the tax exempt trip expenses are packaged into the work hour/timelog
  //
  @action updatePropertiesFromJson(o) {
    const updatedAt = moment(o.updated_at);

    // update content only if received more up to date content – this may happen e.g. when multiple updates are delayed due to slow network
    if (this.updatedAt != null && this.updatedAt.isAfter(updatedAt)) {
      return null;
    }

    const values = {
      id: o.id,
      name: o.name,
      value: o.value,
      status: o.status,
      employerComment: o.employer_comment,
    };

    return this.updateProperties(values);
  }

  static fromJsonProperties(object) {
    const taxExemptTripExpense = new TaxExemptTripExpense({});
    taxExemptTripExpense.updatePropertiesFromJson(object);
    return taxExemptTripExpense;
  }

  static fromJson(json) {
    return TaxExemptTripExpense.fromJsonProperties(JSON.parse(json));
  }
}

import { observable, action } from 'mobx';

export default class Location {
  @observable id;

  @observable name;

  @observable company;

  @observable location;

  @observable city;

  @observable zipCode;

  @observable street;

  @observable additionalInfo;

  @observable countryId;

  constructor(object) {
    this.updateProperties(object);
  }

  @action updatePropertiesFromJson(o) {
    this.updateProperties({
      id: o.id,
      name: o.name,
      company: o.company,
      location: o.location,
      city: o.city,
      zipCode: o.zip_code,
      street: o.street,
      additionalInfo: o.additional_info,
      countryId: o.country_id,
    });
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static fromJsonProperties(object) {
    const location = new Location({});
    location.updatePropertiesFromJson(object);
    return location;
  }

  static fromJson(json) {
    return Location.fromJsonProperties(JSON.parse(json));
  }

  static toJson(o) {
    return {
      id: o.id,
      name: o.name,
      company: o.company,
      location: o.location,
      city: o.city,
      zip_code: o.zipCode,
      street: o.street,
      additional_info: o.additionalInfo,
      country_id: o.countryId,
    };
  }
}

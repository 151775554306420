import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import { GridCell, GridInner } from '@rmwc/grid';
import { Button } from '@rmwc/button';
import { IconButton } from '@rmwc/icon-button';
import {
  ListItemMeta,
} from '@rmwc/list';
import {
  Dialog, DialogActions, DialogButton, DialogContent, DialogTitle,
} from '@rmwc/dialog';
import { Card } from '@rmwc/card';
import { DirectUploadProvider } from 'react-activestorage-provider';
import { handleProgress } from '../models/user/user-ui-avatar';

import UserProductCollection from '../models/product/UserProductCollection';
import UserProduct from '../models/product/UserProduct';
import Product from '../models/product/Product';
import LoadingSpinner from './LoadingSpinner';
import FilePreviewDialog from './FilePreviewDialog';

const handleEnter = (e) => {
  if (e.key === 'Enter' && document.activeElement.tagName !== 'TEXTAREA') {
    document.activeElement.blur();
  }
};

const sortByCreatedAtAsc = (a, b) => {
  // Assuming ISO 8601 format, which is sortable like any string
  if (a.createdAt < b.createdAt) return -1;
  if (a.createdAt > b.createdAt) return 1;
  return 0;
};

@inject('uiStore', 'loginStore', 't', 'productStore')
@observer
class ProductDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProducts: [],
      deleteInfo: {},
      productChanged: false,
      newProduct: new Product(),
      newProductChanged: false,
      newProductDialogOpen: false,
      creatingNewProduct: false,

      showFilePreviewDialog: false,
      filePreviewFileURL: null,
      filePreviewFileName: null,

      showDeleteDialog: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { open, userProductCollection } = this.props;
    // Update selectedProducts if the modal is closed or opened.
    // We do this split from userProductCollection.userProducts into state.selectedProducts to keep temporary changes separate from the original object
    if (prevProps.open !== open && open) {
      // Opening dialog
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedProducts: userProductCollection?.userProducts ? [...userProductCollection?.userProducts] : [],
        productChanged: false,
      });
    } else if (prevProps.open !== open && !open) {
      // Closing dialog
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedProducts: [],
        productChanged: false,
      });
    }
  }

  confirmDelete = (id, workOrderId, name, signedId) => {
    const deleteMessage = `Haluatko varmasti poistaa tiedoston ${name}?`;

    this.setState({
      showDeleteDialog: true,
      deleteInfo: {
        id,
        workOrderId,
        deleteMessage,
        signedId,
      },
    });
  }

  deleteFileFromStateWithSignedId = (signedId) => {
    const { userProductCollection } = this.props;

    const updatedFiles = [...userProductCollection.files].filter((file) => file.signedId !== signedId);
    userProductCollection.changeAttribute('files', updatedFiles);
  }

  closeFilePreviewDialog = () => {
    this.setState({
      showFilePreviewDialog: false,
      filePreviewFileURL: null,
      filePreviewFileName: null,
    });
  }

  openFilePreviewDialog = (file) => {
    this.setState({
      showFilePreviewDialog: true,
      filePreviewFileURL: file.fileURL,
      filePreviewFileName: file.filename,
    });
  }

  addTextField(attr, label, required, styles = null, textarea) {
    const { userProductCollection } = this.props;
    let productChanged = false;

    return (
      <TextField {...{
        style: { ...styles },
        label,
        required,
        name: attr,
        rows: 5,
        type: 'text',
        textarea,
        onChange: (event) => {
          const { value } = event.target;
          productChanged = true;
          userProductCollection.changeAttribute('description', value);
          this.setState({
            productChanged,
          });
        },
        theme: ['textPrimaryOnDark', 'secondaryBg'],
        rootProps: { ripple: false },
        onKeyPress: handleEnter,
        value: userProductCollection[attr] != null ? userProductCollection[attr] : '',
      }}
      />
    );
  }

  addNewProductTextField(attr, label, required, styles = null, textarea) {
    const { newProduct } = this.state;
    let newProductChanged = false;
    const updatedNewProduct = new Product(newProduct);

    return (
      <TextField {...{
        style: { ...styles },
        label,
        required,
        name: attr,
        rows: 5,
        type: 'text',
        textarea,
        onChange: (event) => {
          const { value } = event.target;
          newProductChanged = true;
          updatedNewProduct.changeAttribute(attr, value);
          this.setState({
            newProductChanged,
            newProduct: updatedNewProduct,
          });
        },
        theme: ['textPrimaryOnDark', 'secondaryBg'],
        rootProps: { ripple: false },
        onKeyPress: handleEnter,
        value: newProduct[attr] != null ? newProduct[attr] : '',
      }}
      />
    );
  }

  // handleDeletionDirectly(signedId, userProductCollectionId) {
  //   const { productStore } = this.props;

  //   productStore.deleteUserProductCollectionFile(signedId, userProductCollectionId).then(this.deleteFileFromStateWithSignedId(signedId));
  //   this.setState({
  //     productChanged: true,
  //   });
  // }

  handleDeletion(signedId, userProductCollectionId) {
    const { productStore } = this.props;

    productStore.deleteUserProductCollectionFile(signedId, userProductCollectionId).then(this.deleteFileFromStateWithSignedId(signedId));
  }

  save() {
    const { productStore, workTaskEntry, userProductCollection } = this.props;
    const { selectedProducts } = this.state;

    // const savingProducts = [...selectedProducts];
    // const reconstructedProducts = savingProducts.map((product) => ({ id: product.id, productId: product.productId, count: product.count }));
    const tempClone = new UserProductCollection(userProductCollection);
    tempClone.userProducts = selectedProducts;
    tempClone.workTaskEntryId = workTaskEntry?.id;

    // productStore.updateUserProductCollection(userProductCollection);
    const updatePromise = fromPromise(new Promise((resolve, reject) => productStore.updateUserProductCollection(tempClone, resolve, reject)));
    when(
      () => updatePromise.state !== 'pending',
      () => {
        updatePromise.case({
          pending: () => {
          },
          rejected: (err) => {
            console.error('Tallennus epäonnistui', err);
            // this.setState({
            //   openSnackbar: true,
            //   saveStatusMessage: 'Tallennus epäonnistui'
            // }, () => this.updateEmployeeList())
          },
          fulfilled: (newUserProductCollection) => {
            userProductCollection.updateProperties(newUserProductCollection);
            this.setState({
              selectedProducts: [],
              productChanged: false,
            });
            // Then we close the dialog because the save() button is tied to the dialog onClose event
          },
        });
      },
    );
  }

  toggleSelectedProduct(product) {
    const { selectedProducts } = this.state;

    const foundSelectedProduct = selectedProducts.find((selectedProduct) => selectedProduct.productId === product.id);
    let productChanged = false;

    if (!foundSelectedProduct) {
      productChanged = true;
      this.setState((prevState) => ({
        selectedProducts: [...prevState.selectedProducts, new UserProduct({ productId: product.id, count: 1, name: product.name })],
        productChanged,
      }));
    } else if (foundSelectedProduct && foundSelectedProduct._destroy) {
      // Product has been "deleted" once, add it back with count as 1
      foundSelectedProduct.updateProperties({ _destroy: null, count: 1 });
    } else if (foundSelectedProduct && foundSelectedProduct.count === 0) {
      // Note: selectedProducts with _destroy 1 are not rendered
      foundSelectedProduct.changeAttribute('_destroy', 1);
    }
  }

  updateCount(selectedProduct, mode) {
    let productChanged = false;
    if (mode === 'increment') {
      selectedProduct.changeAttribute('count', selectedProduct.count + 1);
      productChanged = true;
    } else if (mode === 'decrement' && selectedProduct.count > 0) {
      selectedProduct.changeAttribute('count', selectedProduct.count - 1);
      productChanged = true;
    }

    this.setState({
      productChanged,
    });
  }

  handleAttachmentUpload(userProductCollectionBody) {
    const { productStore, userProductCollection } = this.props;

    const updatePromise = fromPromise(new Promise((resolve, reject) => productStore.updateUserProductCollection(userProductCollectionBody, resolve, reject)));
    updatePromise.then((updatedCollection) => {
      // We update the prop directly because productStore.updateUserProductCollection apparently doesn't do the trick
      userProductCollection.changeAttribute('files', updatedCollection.files);
      // this.setState({
      //   productChanged: true,
      // });
    });
  }

  attachmentUpload(disabled, data, inputRef) {
    const { t } = this.props;

    return (
      <DirectUploadProvider
        directUploadsPath="/rails/active_storage/direct_uploads"
        multiple
        onSuccess={(signedIds) => {
          if (Array.isArray(signedIds)) {
            // const [value] = newData;
            const body = {
              ...data,
              files: signedIds,
            };

            this.handleAttachmentUpload(body);
          }
        }}

        render={({ handleUpload, uploads, ready }) => (
          <>
            <input
              className="inputfile"
              disabled={!ready}
              multiple
              onChange={(e) => {
                handleUpload(e.currentTarget.files);
              }}
              ref={inputRef}
              type="file"
            />

            {handleProgress(uploads, t)}

            <ListItemMeta
              style={{
                display: 'flex',
                // justifyContent: checkedFiles.length !== 0 ? 'space-between' : 'end',
                marginTop: '20px',
              }}
            >
              <Button {...{
                disabled,
                type: 'button',
                label: t('products.dialog.fileUpload'),
                icon: 'description',
                outlined: true,
                theme: 'primary',
                onClick: () => inputRef.current.click(),
                style: {
                  // color: buttonColors.green.color,
                  color: 'var(--mdc-theme-primary)',
                  borderRadius: '0px',
                  fontSize: '.75rem',
                  textTransform: 'none',
                },
              }}
              />
            </ListItemMeta>
          </>
        )}
        required
      />
    );
  }

  openNewProductDialog() {
    this.setState({
      newProduct: new Product(),
      newProductChanged: false,
      newProductDialogOpen: true,
      creatingNewProduct: false,
    });
  }

  closeNewProductDialog() {
    this.setState({
      newProduct: new Product(),
      newProductChanged: false,
      newProductDialogOpen: false,
      creatingNewProduct: false,
    });
  }

  createNewProduct() {
    const { productStore } = this.props;
    const { newProduct } = this.state;

    this.setState({
      creatingNewProduct: true,
    }, () => {
      // Note: productStore.updateProduct, despite its name, handles both update and create, depending if the product has id or not
      const updatePromise = fromPromise(new Promise((resolve, reject) => productStore.updateProduct(newProduct, resolve, reject)));
      when(
        () => updatePromise.state !== 'pending',
        () => {
          updatePromise.case({
            pending: () => { },
            rejected: (err) => {
              console.error('Tallennus epäonnistui', err);
              this.setState({
                creatingNewProduct: false,
              });
            },
            fulfilled: (res) => {
              this.closeNewProductDialog();
              this.toggleSelectedProduct(res);
            },
          });
        },
      );
    });
  }

  renderProducts(products) {
    const { selectedProducts } = this.state;
    const elements = [];

    // eslint-disable-next-line no-unused-expressions
    products.value?.forEach((product) => {
      elements.push(
        // <Checkbox
        //   label={product.name}
        //   checked={false}
        //   key={product.id}
        //   className="white-checkbox"
        //   style={{
        //     marginBottom: index === (products.length - 1) ? '10px'
        //   }}
        //   // onChange={(event) => this.toggleChange(event.target.checked, 'filterHours')}
        // />
        <Card
          key={product.id}
          onClick={() => {
            this.toggleSelectedProduct(product);
          }}
          style={{
            backgroundColor: selectedProducts.find((selectedProduct) => selectedProduct.productId === product.id && !selectedProduct._destroy) ? 'rgb(110, 110, 110)' : 'white',
            minWidth: '40px',
            width: 'auto',
            height: 'fit-content',
            margin: '5px',
            padding: '5px',
            display: 'inline-block',
            color: 'black',
            cursor: 'pointer',
            border: '1px solid black',
          }}
        >
          {product.name}
        </Card>,
      );
    });

    return elements;
  }

  renderSelectedProductsSummary(style) {
    const { selectedProducts } = this.state;
    const selectedProductElements = [];
    let productChanged = false;
    selectedProducts.forEach((selectedProduct, index) => {
      if (!selectedProduct._destroy) {
        selectedProductElements.push(
          <GridInner
            key={`${selectedProduct.name}-${selectedProduct.id || 'new'}`}
            style={{
              ...style,
              marginTop: index === 0 ? '10px' : 'unset',
            }}
          >
            <GridCell
              phone={2}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {selectedProduct.name}
            </GridCell>
            <GridCell
              phone={2}
              style={{
                textAlign: 'center',
              }}
            >
              {selectedProduct.count > 1
                && (
                  <IconButton
                    icon="remove_circle_outline"
                    label="remove"
                    style={{
                      verticalAlign: 'bottom',
                      marginBottom: '1px',
                      paddingRight: 0,
                    }}
                    onClick={() => {
                      this.updateCount(selectedProduct, 'decrement');
                    }}
                  />
                )}
              {selectedProduct.count <= 1
                && (
                  <IconButton
                    icon="delete_outline"
                    label="delete"
                    style={{
                      verticalAlign: 'bottom',
                      marginBottom: '1px',
                      paddingRight: 0,
                    }}
                    onClick={() => {
                      selectedProduct.changeAttribute('_destroy', 1);
                      productChanged = true;
                      this.setState({
                        productChanged,
                      });
                    }}
                  />
                )}
              <TextField {...{
                className: 'pk-number-field',
                style: {
                  // Width is adjusted so that it fits a 4 digit number
                  width: '55px',
                  height: '35px',
                  borderRadius: 0,
                  background: 'transparent',
                  textAlign: 'center',
                },
                type: 'number',
                onChange: (event) => {
                  const { value } = event.target;
                  const selectedProductsTemp = [...selectedProducts];
                  selectedProductsTemp[index].count = Number(value);
                  productChanged = true;

                  this.setState({
                    selectedProducts: selectedProductsTemp,
                    productChanged,
                  });
                },
                rootProps: {
                  ripple: false,
                },
                // onKeyPress: handleEnter,
                value: selectedProduct.count,
              }}
              />
              <IconButton
                icon="add_circle_outline"
                label="add"
                style={{
                  verticalAlign: 'bottom',
                  marginBottom: '1px',
                  paddingLeft: 0,
                }}
                onClick={() => {
                  this.updateCount(selectedProduct, 'increment');
                }}
              />
            </GridCell>
          </GridInner>,
        );
      }
    });

    return selectedProductElements;
  }

  renderActionButtons() {
    const { productChanged } = this.state;

    return (
      <DialogActions
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '15px',
        }}
      >
        <DialogButton
          className="accept-button"
          style={{
            borderRadius: '0px',
          }}
          onClick={() => this.save()}
          disabled={!productChanged}
          action="close"
        >
          Tallenna
        </DialogButton>

        <DialogButton
          style={{
            color: '#c7c9cc',
            border: '1px solid #c7c9cc',
            borderRadius: '0px',
          }}
          action="cancel"
        >
          Peruuta
        </DialogButton>
      </DialogActions>
    );
  }

  renderDeleteAttachmentDialog() {
    const { showDeleteDialog, deleteInfo } = this.state;

    // Trying to use the shared ConfirmDeleteDialog component caused an infinite loop on confirm, likely because the props/state attributes were somehow messed up
    // We go with a custom delete confirmation dialog for now
    return (
      <Dialog
        className="trip-route-dialog double-dialog"
        open={showDeleteDialog}
        onClose={(evt) => {
          if (evt.detail.action === 'accept') {
            this.handleDeletion(deleteInfo.signedId, deleteInfo.id);
          }
          this.setState({
            showDeleteDialog: false,
            deleteInfo: {},
          });
        }}
      >
        <DialogContent
          style={{ color: 'white' }}
        >
          <p style={{ padding: '10px' }}>
            {deleteInfo.deleteMessage}
          </p>
        </DialogContent>
        <DialogActions
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="accept-button"
            style={{
              borderRadius: '0px',
            }}
            action="accept"
          >
            Kyllä
          </DialogButton>
          <DialogButton
            type="button"
            className="cancel-button"
            action="close"
          >
            Peruuta
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  renderUploadedFile(file) {
    const { userProductCollection: { id, workOrderId } } = this.props;
    const {
      filename,
      fileURL,
      signedId,
      thumbnailUrl,
    } = file;

    return (
      <div
        key={fileURL}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '8px 0',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <div
            role="button"
            onClick={() => this.openFilePreviewDialog(file)}
            style={{
              cursor: 'pointer',
              width: '65px',
              height: '65px',
              overflow: 'hidden',
              borderRadius: '5px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            }}
          >
            <img
              src={thumbnailUrl || fileURL}
              alt={filename}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </div>
        </div>

        {signedId && (
          <IconButton
            type="button"
            style={{
              color: '#C7C9CC',
              padding: '10px',
            }}
            icon="delete_outlined"
            onClick={() => {
              this.confirmDelete(id, workOrderId, filename, signedId);
            }}
          />
        )}

        {!signedId && <div style={{ height: '48px' }} />}
      </div>
    );
  }

  renderWorkTaskEntryName() {
    const { workTaskEntry } = this.props;
    if (workTaskEntry) {
      return <div style={{ padding: '0 10px 5px 10px' }}>{`Työ: ${workTaskEntry.workTask.identifierOne} - ${workTaskEntry.workTask.identifierTwo}`}</div>;
    }
    return <div style={{ padding: '0 10px 5px 10px' }}>Päivän yleiset</div>;
  }

  renderDialogTitle() {
    const { workTaskEntry, date } = this.props;
    const formattedDate = date.format('D.M.');
    if (workTaskEntry) {
      return `Tuotteet ${formattedDate} ${workTaskEntry.from || '?'}-${workTaskEntry.to || '?'}`;
    }
    return `Tuotteet ${formattedDate}`;
  }

  render() {
    const {
      t,
      open,
      toggleDialog,
      productStore: { products },
      userProductCollection,
      hourlyWorkorder,
      workOrderName,
      employerUpdateMode,
    } = this.props;

    const {
      newProduct,
      newProductDialogOpen,
      newProductChanged,
      selectedProducts,
      creatingNewProduct,
      showFilePreviewDialog,
      filePreviewFileURL,
      filePreviewFileName,
    } = this.state;
    const inputRef = React.createRef();

    const style = { columnGap: '0px' };
    const styles = { width: '100%', margin: '0.3em 0' };

    let sortedFiles = [];
    if (userProductCollection?.files) {
      sortedFiles = [...userProductCollection.files].sort(sortByCreatedAtAsc);
    }

    const newProductValid = newProduct.name;

    return (
      <>
        <Dialog
          className={employerUpdateMode ? 'modal-size trip-route-dialog employer-trip-route-dialog' : 'modal-size trip-route-dialog'}
          open={open}
          onClose={() => {
            toggleDialog();
          }}
        >
          {open && (
            <DialogContent style={{ color: 'white' }}>
              <DialogTitle
                style={{
                  color: 'var(--mdc-theme-primary)',
                  padding: '5px 10px',
                }}
              >
                {this.renderDialogTitle()}
              </DialogTitle>
              {hourlyWorkorder && this.renderWorkTaskEntryName()}
              <Typography
                use="subtitle1"
                style={{ padding: '0 10px 5px 10px' }}
              >
                {workOrderName}
              </Typography>
              {/* SUMMARY START */}
              <GridCell
                style={{
                  backgroundColor: 'var(--mdc-theme-surface, #fff)',
                  padding: '15px 10px',
                  margin: '5px -10px 15px',
                  fontSize: '14px',
                }}
              >
                {selectedProducts.length > 0 && (
                  <>
                    <GridInner
                      style={style}
                      className="green-text"
                    >
                      <GridCell
                        phone={2}
                      >
                        {/* Kirjattu tuote */}
                        {t('products.dialog.selectedProduct')}
                      </GridCell>
                      <GridCell
                        phone={2}
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        {/* Määrä */}
                        {t('products.dialog.amount')}
                      </GridCell>
                    </GridInner>

                    {this.renderSelectedProductsSummary(style)}
                  </>
                )}
              </GridCell>
              {/* SUMMARY END */}

              <div
                style={{
                  margin: '0px -10px 5px -10px',
                  paddingBottom: '5px',
                  borderBottom: '1px solid var(--mdc-theme-primary)',
                }}
              >
                <Typography
                  use="subtitle1"
                  style={{ paddingLeft: '15px' }}
                >
                  {/* Valitse tuotteet */}
                  {t('products.dialog.chooseProducts')}
                </Typography>
              </div>

              <div>
                {products && this.renderProducts(products)}
                {true && (
                  <Card
                    key="new-product"
                    onClick={() => this.openNewProductDialog()}
                    style={{
                      backgroundColor: 'white',
                      minWidth: '40px',
                      width: 'auto',
                      height: 'fit-content',
                      margin: '5px',
                      padding: '5px',
                      display: 'inline-block',
                      color: 'black',
                      cursor: 'pointer',
                      border: '1px solid black',
                    }}
                  >
                    {t('products.dialog.newProduct')}
                  </Card>
                )}
              </div>

              {/* {true && (
                  <div style={{ marginTop: '20px' }}>
                    <Button {...{
                      // disabled,
                      type: 'button',
                      label: 'Uusi tuote',
                      icon: 'add',
                      outlined: true,
                      theme: 'primary',
                      onClick: () => this.openNewProductDialog(),
                      style: {
                        // color: buttonColors.green.color,
                        color: 'var(--mdc-theme-primary)',
                        borderRadius: '0px',
                        fontSize: '.75rem',
                        textTransform: 'none',
                      },
                    }}
                    />
                  </div>
                )} */}

              {/* <div
                  style={{
                    margin: '50px -10px 10px -10px',
                    paddingBottom: '5px',
                    borderBottom: '1px solid var(--mdc-theme-primary)',
                  }}
                >
                  <Typography
                    use="subtitle1"
                    style={{ paddingLeft: '15px' }}
                  >
                    Kuvat
                  </Typography>
                </div>

                {sortedFiles.map((file) => (
                  this.renderUploadedFile(file)
                ))} */}

              {/* <div style={{ marginTop: '20px' }}>
                {this.attachmentUpload(
                  // (name === ''),
                  false, // GOOD DISABLED LOGIC HERE
                  { ...userProductCollection },
                  // {
                  //   id,
                  //   // description,
                  //   // name,
                  //   // workOrderId,
                  // },
                  inputRef,
                )}
              </div> */}

              <div
                style={{
                  margin: '50px -10px 5px -10px',
                  paddingBottom: '5px',
                  borderBottom: '1px solid var(--mdc-theme-primary)',
                }}
              >
                <Typography
                  use="subtitle1"
                  style={{ paddingLeft: '15px' }}
                >
                  Lisätietoa
                </Typography>
              </div>

              {this.addTextField('description', '', false, styles, true)}

              {sortedFiles.map((file) => (
                this.renderUploadedFile(file)
              ))}

              <div style={{ margin: '20px 0', textAlign: 'end' }}>
                {this.attachmentUpload(
                  // (name === ''),
                  false, // GOOD DISABLED LOGIC HERE
                  { ...userProductCollection },
                  // {
                  //   id,
                  //   // description,
                  //   // name,
                  //   // workOrderId,
                  // },
                  inputRef,
                )}
              </div>
            </DialogContent>
          )}

          {this.renderActionButtons()}
        </Dialog>

        {/* NEW PRODUCT DIALOG */}
        <Dialog
          className="modal-size trip-route-dialog"
          open={newProductDialogOpen}
          onClose={() => this.closeNewProductDialog()}
        >
          <DialogContent style={{ color: 'white' }}>
            <DialogTitle
              style={{
                color: 'var(--mdc-theme-primary)',
                padding: '5px 10px',
              }}
            >
              {t('products.dialog.newProductTitle')}
            </DialogTitle>

            {this.addNewProductTextField('name', t('products.attributes.name'), true, { ...styles, width: '100%', marginBottom: '10px' }, false)}
            {this.addNewProductTextField('code', t('products.attributes.code'), false, { ...styles, width: '100%', marginBottom: '10px' }, false)}
            {this.addNewProductTextField('description', t('products.attributes.description'), false, { ...styles, width: '100%' }, true)}
          </DialogContent>

          <DialogActions
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '15px',
            }}
          >
            <DialogButton
              className="accept-button"
              style={{
                borderRadius: '0px',
              }}
              onClick={() => this.createNewProduct()}
              disabled={!newProductChanged || !newProductValid || creatingNewProduct}
            >
              {creatingNewProduct ? <LoadingSpinner color="black" size="mini" /> : 'Tallenna'}
            </DialogButton>

            <DialogButton
              style={{
                color: '#c7c9cc',
                border: '1px solid #c7c9cc',
                borderRadius: '0px',
              }}
              action="cancel"
            >
              Peruuta
            </DialogButton>
          </DialogActions>
        </Dialog>

        <FilePreviewDialog
          open={showFilePreviewDialog}
          fileURL={filePreviewFileURL}
          fileName={filePreviewFileName}
          onClose={this.closeFilePreviewDialog}
        />

        {this.renderDeleteAttachmentDialog()}
      </>
    );
  }
}

export default ProductDialog;

/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { TextField } from '@rmwc/textfield';
import { Checkbox } from '@rmwc/checkbox';
import { Select } from '@rmwc/select';
import { toFloat } from 'validator';
import { Icon } from '@rmwc/icon';
import { isEqual } from 'lodash';

import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab/dist/mdc.tab.css';
import '@rmwc/data-table/data-table.css';
import '@rmwc/icon/icon.css';

import '@material/select/dist/mdc.select.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/list/dist/mdc.list.css';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/icon-button/dist/mdc.icon-button.css';

import { buttonColors } from '../calendar/EmployeeCalendar';
import noop from '../utils/noop';
import moment from '../utils/moment';

import CustomTimeField from '../shared/CustomTimeField';

const handleKeyDown = (e) => {
  e.target.style.height = 'inherit';
  e.target.style.height = `${e.target.scrollHeight}px`;
  // In case you have a limitation
  // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
};

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

@inject('timelogStore', 't', 'uiStore', 'workOrderTripStore', 'employerContextStore')
@observer
class AbsenceEntryRow extends Component {
  // ALLOWANCES = {
  //   none: this.props.t('timelog.allowances.none'),
  //   full: this.props.t('timelog.allowances.1'),
  //   half: this.props.t('timelog.allowances.2'),
  //   meal: this.props.t('timelog.allowances.3'),
  //   two_meals: this.props.t('timelog.allowances.4'),
  //   full_plus_2: this.props.t('timelog.allowances.5'),
  //   full_plus_10: this.props.t('timelog.allowances.6'),
  //   full_half: this.props.t('timelog.allowances.7'),
  // };
  // ABSENCE_TYPES = {
  //   1: 'Saikku',
  //   2: 'Vuosiloma',
  // };

  constructor(props) {
    super(props);

    // const { uiStore: { currentUser: { accountId } } } = this.props;

    // const timeNormal = Number(props.item.timeNormal) || 0;
    // const time50 = Number(props.item.time50) || 0;
    // const time100 = Number(props.item.time100) || 0;
    // const travelTime = Number(props.item.travelTime) || 0;

    // const calcBankHours = timeNormal + time50 + time100 + travelTime;

    this.state = {
      // itemBankHours: calcBankHours,
      // absenceTypesOptions: [],
    };
  }

  // componentDidMount() {
  //   const { absenceTypes } = this.props;
  //   const absenceTypeOptions = {};
  //   absenceTypes.forEach((absenceType) => {
  //     absenceTypeOptions[absenceType.id] = absenceType.nickname;
  //   });

  //   console.log('Absence types: ', absenceTypes);
  //   console.log('Final absence type options: ', absenceTypeOptions);

  //   this.setState({
  //     absenceTypesOptions: absenceTypeOptions,
  //   });
  // }

  // A hack to render a new allowance when it is autofilled by saving a work order trip (only happens after mount by default)
  // Should probably change the structure of the app in general so that all state attributes are derived from the prop, not just this
  componentDidUpdate(prevProps) {
    const { item } = this.props;
    if (!isEqual(item, prevProps.item)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ ...item });
    }
  }

  getDescriptionCells() {
    return (
      <>
        {this.addTextAreaField('description')}
      </>
    );
  }

  // getProducts() {
  //   const { workOrder, item } = this.props;

  //   if (workOrder.workHourType === 'hourly') {
  //     return (
  //       <div
  //         style={{
  //           textAlign: 'center',
  //           width: '100%',
  //           margin: 0,
  //           padding: '0 10px',
  //           display: 'flex',
  //           flexDirection: 'column',
  //           justifyContent: 'center',
  //           fontSize: '14px',
  //         }}
  //       >
  //         <div>
  //           {item.workTaskEntries.map((workTaskEntry) => (
  //             this.renderWorkTaskEntryProducts(workTaskEntry)
  //           ))}
  //           {this.renderWorkTaskEntryProducts()}
  //         </div>
  //       </div>
  //     );
  //   }

  //   return this.renderProducts();
  // }

  doOnBlur = (attr, value, event) => {
    const { item, timelogStore } = this.props;
    const { props: { uiStore: { currentUser: { accountId, workHourBank } } } } = this;

    if (this.state[attr] !== item[attr] && event.target.validity.valid) {
      if (attr === 'timeNormal' || attr === 'time50' || attr === 'time100' || attr === 'travelTime') {
        // First deduct the old value (item[attr]), then add the new value (state[attr])
        // The old value might not exist if the timelog was just created so we default it to 0
        const oldValue = item[attr] || 0;
        const newCalcAmount = (workHourBank.calcAmountToAdd - oldValue) + this.state[attr];
        workHourBank.changeAttribute('calcAmountToAdd', newCalcAmount);
      }

      item.changeAttribute(attr, value);

      // Split driveTime into normal driveTime, 50% driveTime and 100% driveTime in the background
      // TODO: Hardcoded to account id 1 (and 10 for testing)
      if (accountId === 1 || accountId === 10) {
        if (attr === 'driveTime') {
          this.distributeDriveTime(value);
        } else {
          this.distributeDriveTime(this.state.driveTimeCombined);
        }
      }

      timelogStore.updateTimelogEntry(item);
      this.rowUpdateCallback();
    }
  }

  doOnChange = (attr, value, event) => {
    if (this.state[attr] !== value && event.target.validity.valid) {
      this.setState({ [attr]: value });
    }
  }

  addDecimalField(attr, ctx = {}) {
    const { activeTab, disabled, uiStore: { currentUser: { accountId } } } = this.props;
    const {
      from,
      to,
      timeNormal,
      time50,
      time100,
    } = this.state;

    let shouldWarn = false;

    // Hardcoded to accounts 42, 3 and 10 to highlight potentially undesired lunch breaks
    if (accountId === 42 || accountId === 3 || accountId === 10 || accountId === 53) {
      const timeFrom = moment(from, 'H:m');
      const timeTo = moment(to, 'H:m');
      let totalTime;

      // Should be moved to utils
      if (from && to && timeFrom > timeTo) {
        // Night shifts
        const timeToMidnight = moment('24:00', 'H:m').diff(timeFrom) / 3600000;
        const timeFromMidnight = timeTo.diff(moment('00:00', 'H:m')) / 3600000;
        totalTime = timeToMidnight + timeFromMidnight;
      } else {
        totalTime = timeTo.diff(timeFrom) / 3600000;
      }

      if ((attr === 'timeNormal' || attr === 'time50' || attr === 'time100') && (from && from >= '06:30' && from <= '07:30') && (Number((timeNormal + time50 + time100)) === 8.5) && totalTime === 8.5) {
        // Add the warning class (text color) to the last field with hours
        if (timeNormal && !time50 && !time100 && attr === 'timeNormal') {
          shouldWarn = true;
        } else if (time50 && !time100 && attr === 'time50') {
          shouldWarn = true;
        } else if (time100 && attr === 'time100') {
          shouldWarn = true;
        }
      }
    }

    return (
      <div
        className={`${((activeTab === 1 && (accountId === 43 || accountId === 1)) || activeTab === 3) ? 'mobile-column' : ''}`}
        style={{ padding: 0, textAlign: 'center', width: '16.5%' }}
      >
        <TextField {...{
          className: shouldWarn ? 'pk-number-field-warn pk-number-field' : 'pk-number-field',
          onBlur: (event) => {
            this.doOnBlur(attr, event.target.value, event);
          },
          onChange: noop,
          onInput: (event) => {
            let newValue = event.target.value.replace(/[.,]{1,}/, '.');

            if (newValue.substr(-1) !== '.' && newValue !== '') {
              newValue = toFloat(newValue);
              if (newValue >= (ctx.max || 24)) {
                return;
              }
            }

            if ((!Number.isNaN(newValue) || newValue === '')) {
              this.doOnChange(attr, newValue, event);
            }
          },
          disabled,
          pattern: '[0-9.,]*',
          rootProps: { ripple: false },
          style: {
            height: '44px',
            margin: 0,
            minWidth: '40px',
            padding: 0,
            width: '60px',
            backgroundColor: 'var(--mdc-theme-secondary)',
          },
          // theme: ['textPrimaryOnDark', 'secondaryBg'],
          type: 'text',
          onKeyPress: handleEnter,
          value: (this.state != null && this.state[attr] != null && !Number.isNaN(this.state[attr])) ? this.state[attr] : '',
          ...ctx,
        }}
        />

        {this.renderEmployeeVersionValue(attr)}
      </div>
    );
  }

  addValueList(attr, ctx) {
    const {
      // timelogStore,
      item,
    } = this.props;
    const { absenceTypeOptions } = this.state;

    return (
      <div
        className="allowance-column"
        // style={{ padding: 0, textAlign: 'center', width: '82.5%' }}
        style={{ padding: 0, textAlign: 'center' }}
      >
        <Select
          {...{
            className: 'mdc-theme--text-primary-on-dark pk-select',
            disabled: this.props.disabled,
            onBlur: (event) => {
              console.log('Event: ', event);
              // const { target: { value, validity: { valid } } } = event;

              // The values are always valid?
              // if (value === '' || !valid) {
              //   return;
              // }

              // if (value !== item[attr] && valid) {
              //   item.changeAttribute(attr, event.target.value);
              //   timelogStore.updateTimelogEntry(item);
              //   this.rowUpdateCallback();
              // }
            },
            onChange: (event) => {
              if (event.target.value === '') {
                return;
              }
              if (item[attr] !== event.target.value && event.target.validity.valid) {
                event.target.blur();
              }
            },
            options: absenceTypeOptions,
            placeholder: 'Poissaolo',
            rootProps: { ripple: false },
            // theme: ['textPrimaryOnDark', 'secondaryBg'],
            style: {
              backgroundColor: 'var(--mdc-theme-secondary)', color: 'white', padding: '0', borderRadius: '0',
            },
            // Need to use the prop here because the value list doesn't update the state, only the prop through changeAttribute + timelogStore.updateTimelogEntry
            // This whole prop-state relationship is a mess
            value: item && item[attr]?.id ? item[attr].id : '',
            ...ctx,
          }}
        />

        {this.renderEmployeeVersionValue(attr, 'timelog.attributes')}
      </div>
    );
  }

  addCheckboxField(attr) {
    const {
      item,
      activeTab,
      disabled,
      uiStore: { currentUser: { accountId } },
    } = this.props;

    return (
      <div
        className={`${((activeTab === 1 && (accountId === 43 || accountId === 1)) || activeTab === 3) ? 'mobile-column' : ''}`}
        style={{
          padding: 0,
          textAlign: 'center',
          alignSelf: 'center',
          width: '16.5%',
        }}
      >
        <Checkbox {...{
          checked: item[attr] != null ? item[attr] : false,
          className: 'border-on-dark',
          disabled,
          style: {
            marginLeft: 0,
            marginRight: 0,
            height: '44px',
            margin: 0,
            minWidth: '40px',
            padding: 0,
          },
          onChange: (event) => {
            const { timelogStore } = this.props;
            const value = event.target.checked;
            if (item[attr] !== value && event.target.validity.valid) {
              item.changeAttribute(attr, value);
              timelogStore.updateTimelogEntry(item);
              this.rowUpdateCallback();
            }
          },
        }}
        />
      </div>
    );
  }

  addTextAreaField(attr) {
    const { disabled } = this.props;
    return (
      <div
        style={{
          padding: 0,
          textAlign: 'center',
          width: '100%',
        }}
      >
        <TextField {...{
          className: 'no-resize',
          disabled,
          onClick: handleKeyDown,
          onKeyDown: handleKeyDown,
          onBlur: (event) => {
            this.doOnBlur(attr, event.target.value, event);
          },
          onChange: (event) => {
            this.doOnChange(attr, event.target.value, event);
            handleKeyDown(event);
          },
          rootProps: { ripple: false },
          style: {
            minHeight: '44px',
            margin: 0,
            padding: 0,
            width: '100%',
          },
          textarea: true,
          theme: ['textPrimaryOnDark', 'secondaryBg'],
          value: (this.state != null && this.state[attr] != null && !Number.isNaN(this.state[attr])) ? this.state[attr] : '',
        }}
        />
      </div>
    );
  }

  addHourField(attr) {
    const { disabled } = this.props;

    return (
      <div>
        <div
          className="pk-time-field mdc-text-field mdc-text-field--upgraded mdc-text-field--no-label"
          style={{
            height: '44px',
            width: '45px',
            margin: 0,
            padding: 0,
            backgroundColor: 'var(--mdc-theme-secondary)',
            color: 'white',
          }}
        >
          <CustomTimeField
            value={this.state[attr] || '--:--'}
            attr={attr}
            doOnChange={this.doOnChange}
            doOnBlur={this.doOnBlurForHours}
            handleEnter={handleEnter}
            disabled={disabled}
          />
        </div>

        {this.renderEmployeeVersionValue(attr)}
      </div>
    );
  }

  versionsDifferent() {
    const { item: currentVersion, item: { latestEmployeeVersion } } = this.props;
    const irrelevantProperties = ['createdAt', 'updatedAt', 'status', 'employerUpdateComment', 'latestEmployeeVersion'];

    const cleanedCurrentVersion = { ...currentVersion };
    const cleanedLatestEmployeeVersion = { ...latestEmployeeVersion };

    irrelevantProperties.forEach((property) => {
      delete cleanedCurrentVersion[property];
      delete cleanedLatestEmployeeVersion[property];
    });

    return Object.keys(cleanedLatestEmployeeVersion).length > 0 && !isEqual(cleanedCurrentVersion, cleanedLatestEmployeeVersion);
  }

  renderAbsenceEntrySummary() {
    const { item, item: { values, absenceType, calendarEntry }, openAbsenceEntryDialog } = this.props;

    let times = null;
    const fromTime = calendarEntry.from.format('HH:mm');
    const toTime = calendarEntry.to.format('HH:mm');
    if (fromTime !== '00:00' || toTime !== '23:59') {
      if (fromTime === '00:00') {
        times = `päättyy ${toTime}`;
      } else if (toTime === '23:59') {
        times = `alkaa ${fromTime}`;
      } else {
        times = `${fromTime}-${toTime}`;
      }
    }

    return (
      <div style={{ padding: '13px', cursor: 'pointer' }} onClick={() => openAbsenceEntryDialog(item)} role="button">
        <span>
          {absenceType.nickname}
        </span>
        {Object.keys(values).length > 0 && values.map((value) => {
          if (value.value) {
            // Use either valueTemplate's unit (primary), or the saved value's unit (secondary)
            // Intended to make the duration value independent from value templates, if no value template is needed for it
            const unit = absenceType.valueTemplates.find((template) => template.name === value.name)?.unit || value.unit;
            if (unit) {
              // Value with unit
              return (<span>{`, ${value.value} ${unit}`}</span>);
            }
            // Value without unit
            return (<span>{`, ${value.value}`}</span>);
          }
          // No value
          return null;
        })}
        {times && (
          <>
            &nbsp;
            <span>
              {`(${times})`}
            </span>
          </>
        )}
      </div>
    );
  }

  renderEmployeeVersionValue(attr, translationPrefix = null) {
    const {
      t,
      employerUpdateMode,
      item,
      item: { latestEmployeeVersion },
    } = this.props;

    if (latestEmployeeVersion && latestEmployeeVersion[attr] !== item[attr] && ((latestEmployeeVersion.updatedAt !== item.updatedAt) || employerUpdateMode)) { // && latestEmployeeVersion.userId !== currentUser.id
      return (
        <div className="work-hour-old-value">
          {(translationPrefix && latestEmployeeVersion[attr]) ? t(`${translationPrefix}.${latestEmployeeVersion[attr]}`) : (latestEmployeeVersion[attr] || '?')}
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      activeTab,
      item,
      employerAcceptance,
      uiStore: { currentUser: { accountId } },
      // workOrder,
      employerUpdateMode,
      highlight,
    } = this.props;
    const dayStr = item.calendarEntry.from.format('D.M. dd');
    const versionsDifferent = this.versionsDifferent();
    const isDraft = item.status === 'draft'; // item.isDraft();

    return (
      <div
        className={`${((activeTab === 1 && (accountId === 43 || accountId === 1)) || activeTab === 3) ? 'mobile-fit-content' : ''}`}
        style={{ borderTop: '1px gray solid', borderBottom: highlight && '1px solid var(--mdc-theme-primary)', fontSize: '16px' }}
      >

        {/* The employer comment, which may be added by the employer upon rejecting the work hour in employer acceptance mode */}
        {item.employerComment && (item.status === 'rejected' || item.status === 'draft') && (
          <div className="employer-comment">
            <Icon icon="info" />
            <div className="employer-comment-inner">
              {item.employerComment}
            </div>
          </div>
        )}

        {/* {item.taxExemptTripExpenses && (
          item.taxExemptTripExpenses.map((expense) => {
            if (expense.status === 'rejected' && expense.employerComment) {
              return (
                <div key={`${item.id}-expense-employer-comment`}>
                  <div className="employer-comment">
                    <Icon icon="info" />
                    <div className="employer-comment-inner">
                      {expense.employerComment}
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })
        )} */}

        {/* {item.workOrderTripEmployerComment && (
          <div className="employer-comment">
            <Icon icon="info" />
            <div className="employer-comment-inner">
              {item.workOrderTripEmployerComment}
            </div>
          </div>
        )} */}

        {!employerUpdateMode && versionsDifferent && item.employerUpdateComment?.comment && (
          <div className="employer-comment">
            <Icon icon="info" />
            <div className="employer-comment-inner">
              {item.employerUpdateComment?.comment}
            </div>
          </div>
        )}

        <div style={{ width: '100%', display: 'flex' }}>
          <div
            className={`sticky-left ${((activeTab === 1 && (accountId === 43 || accountId === 1)) || activeTab === 3) ? 'mobile-column' : ''}`}
            style={{
              color: item.status === 'accepted' && !isDraft ? buttonColors.green.color : 'var(--mdc-theme-primary)',
              padding: 0,
              textAlign: 'center',
              width: '17.5%',
              textTransform: 'uppercase',
              fontSize: '.875rem', // Copied from the old DataTable implementation
              display: 'flex',
              // flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <span className="vertical-align-middle" style={{ width: '65px' }}>{dayStr}</span>
            {/* style={{ position: 'relative' }} */}
            <div className="timelog-row-icon vertical-align-middle" style={{ position: 'relative' }}>
              {this.props.disabled && (
                <Icon icon="lock_outline" />
              )}
              {!isDraft && item.status === 'pending' && !this.props.disabled && (
                <Icon icon="done" />
              )}
              {!isDraft && item.status === 'accepted' && !this.props.disabled && (
                <Icon icon="done_all" />
              )}
              {!isDraft && item.status === 'rejected' && !this.props.disabled && employerAcceptance && !item.employerComment && (
                <Icon icon="info" />
              )}
              {/* {!isDraft && item.status === 'accepted' && !this.props.disabled && !employerAcceptance && (
                <Icon icon="done" />
              )} */}
            </div>
          </div>
          {activeTab === 0 && this.renderAbsenceEntrySummary()}
        </div>

        <div style={{ width: '100%' }}>
          {employerUpdateMode && Object.keys(item.latestEmployeeVersion).length !== 0 && (item.employerUpdateComment?.comment || versionsDifferent) && (
            <TextField {...{
              className: 'no-resize employer-update-comment-input',
              label: 'Muutosten perustelut:',
              // disabled,
              // onClick: handleKeyDown,
              // onKeyDown: handleKeyDown,
              // onBlur: (event) => {
              //   this.doOnBlur(attr, event.target.value, event);
              // },
              onChange: (event) => {
                // this.doOnChange(attr, event.target.value, event);
                item.employerUpdateComment.comment = event.target.value;
                // handleKeyDown(event);
              },
              rootProps: { ripple: false },
              style: {
                margin: 0,
                padding: 0,
                width: '100%',
              },
              // textarea: true,
              theme: ['textWhite', 'secondaryBg'],
              // value: (this.state != null && this.state[attr] != null && !Number.isNaN(this.state[attr])) ? this.state[attr] : '',
              value: item.employerUpdateComment.comment || '',
            }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default AbsenceEntryRow;

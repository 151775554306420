/* eslint-disable */
import React, { Component } from 'react'; // Fragment
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { inject, observer } from 'mobx-react';
import groupArray from 'group-array';
import {
  IconButton,
  Icon,
  Grid,
} from '@material-ui/core';
// import { sortBy } from 'lodash';
// import { createTheme } from '@material-ui/core/styles';
// import { Icon } from '@rmwc/icon';
// import MUIDataTable, { ExpandButton } from 'mui-datatables';
import MUIDataTable from 'mui-datatables';
// import TableFooter from '@mui/material/TableFooter';
import ReactToPrint from 'react-to-print';
import moment from '../utils/moment';
// import ReportSettingsDialog from './ReportSettingsDialog';
import LoadingSpinner from '../shared/LoadingSpinner';
import DeepViewHeader from '../shared/DeepViewHeader';
import './report.css';
import normalizeFileName from '../utils/NormalizeFileName';
import EmployerProductLog from './EmployerProductLog';

@inject('uiStore', 'loginStore', 't', 'employerWorkOrderStore', 'productStore')
@observer
class ProductReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportData: null,
      columns: [],
      activeTabIndex: 0,
    };
  }

  componentDidMount() {
    const { uiStore } = this.props;
    const workOrderId = uiStore.currentView.id;

    this.getWorkOrderReportByUser(workOrderId);
    const columns = [
      {
        name: 'date',
        label: 'Päivämäärä',
        display: true,
        options: {
          customBodyRender: (value) => moment(value).format('DD.MM.YYYY'),
        },
      },
      {
        name: 'product_name',
        label: 'Tuote',
        display: true,
      },
      {
        name: 'product_code',
        label: 'Tuotekoodi',
        display: true,
      },
      {
        name: 'count',
        label: 'Määrä',
        display: true,
      },
      {
        name: 'product_description',
        label: 'Tuotteen lisätiedot',
        display: true,
      },
    ];

    this.setState({ columns });
  }

  getWorkOrderReportByUser = (workOrderId) => {
    const { productStore } = this.props;
    const reportPromise = fromPromise(new Promise((resolve, reject) => productStore.getProductReport(workOrderId, resolve, reject)));

    when(
      () => reportPromise.state !== 'pending',
      () => {
        reportPromise.case({
          pending: () => {
          },
          rejected: (e) => {
            console.log('REJECTED: ', e);
          },
          fulfilled: (data) => {
            // Need to do the grouping here because the Rails-side grouping didn't work for some reason (selected columns disappeared when grouped)
            const groupedData = [];
            data.userProducts.forEach((userProduct) => {
              const foundGroupedProduct = groupedData.find((groupedProduct) => groupedProduct.product_id === userProduct.product_id);
              if (foundGroupedProduct) {
                foundGroupedProduct.count += userProduct.count;
              } else {
                groupedData.push(userProduct);
              }
            });

            this.setState({
              reportData: groupedData,
              reportWorkOrder: data.workOrder,
            });
          },
        });
      },
    );
  }

  selectTab = (index) => {
    this.setState({ activeTabIndex: index });
  }

  toggleColumnVisibility = (columnName) => {
    const { columns } = this.state;
    const columnIndex = columns.findIndex((column) => column.name === columnName);
    const foundColumn = columnIndex !== -1 ? columns[columnIndex] : null;

    const updatedColumns = [...columns];

    if (foundColumn) {
      // Toggle visibility
      updatedColumns[columnIndex].display = !foundColumn.display;
      this.setState({
        columns: updatedColumns,
      });
    }
  }

  render() {
    const {
      reportData,
      reportWorkOrder,
      columns,
      activeTabIndex,
    } = this.state;
    const { t, uiStore, uiStore: { currentUser } } = this.props;

    const filename = `Tuoteraportti_${reportWorkOrder?.name}_${moment().format('DD-MM-YYYY')}`;
    const normalizedFileName = normalizeFileName(filename);

    const options = {
      filter: false,
      responsive: 'standard',
      search: false,
      viewColumns: false,
      selectableRows: 'none',
      fixedHeader: false,
      sort: true,
      download: true,
      print: false,
      downloadOptions: {
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
        filename: `${normalizedFileName}.csv`,
      },
      textLabels: {
        body: {
          noMatch: 'Kirjattuja tuotteita ei löytynyt',
        },
      },
    };

    return (
      <div className="action-buttons" style={{ height: '100%', width: '100%' }}>
        <DeepViewHeader
          content={reportWorkOrder?.name}
          showPreviousView={uiStore.showEmployerBilling}
        />

        <Grid container>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px 24px 0' }}>
            <div className="report-tabs" style={{ display: 'flex', gap: '16px' }}>
              <span
                role="button"
                onClick={() => this.selectTab(0)}
                className={`heading-tab ${activeTabIndex === 0 ? 'active' : 'inactive'}`}
                style={{ margin: 0 }}
              >
                Tuoteraportti
              </span>
              <span
                role="button"
                onClick={() => this.selectTab(1)}
                className={`heading-tab ${activeTabIndex === 1 ? 'active' : 'inactive'}`}
                style={{ margin: 0 }}
              >
                Tuotekirjaukset
              </span>
            </div>
            {activeTabIndex === 0 && reportWorkOrder ? (
              <ReactToPrint
                trigger={() => (
                  <IconButton
                    aria-label="settings"
                    className="small"
                  >
                    <Icon style={{ color: 'black' }} fontSize="large">print</Icon>
                  </IconButton>
                )}
                content={() => this.componentRef}
              />
            ) : (
              <div style={{ width: '48px', height: '48px' }} /> // Placeholder to maintain layout
            )}
          </Grid>
        </Grid>

        {activeTabIndex === 0 && reportWorkOrder ? (
          <div className="printable" id="report" ref={(el) => (this.componentRef = el)}>
            <Grid
              container
              spacing={2}
              className="billing-report-header pdf-header"
              justifyContent="space-between"
            >
              <Grid item xs={4}>
                <span>{moment().format('DD/MM/YYYY')}</span>
              </Grid>
              <Grid item xs={4}>
                <span>{`Tuntiraportit - ${currentUser?.accountInfo?.officialName}`}</span>
              </Grid>
              <Grid item xs={4}>
                <img style={{ maxWidth: '100%' }} src={currentUser?.accountInfo?.accountPicture} alt="company logo" />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ width: '100%', height: 'fit-content', padding: '16px 0px' }}
              className="billing-report-header"
            >
              <Grid item md={6} xs={12}>
                <div className="header-label">Tilaaja</div>
                <div className="header-value">{reportWorkOrder?.purchaser}</div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className="header-label">Ajalta</div>
                <div className="header-value">{reportWorkOrder?.duration}</div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className="header-label">Kohde</div>
                <div className="header-value">{reportWorkOrder?.location}</div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className="header-label">Nimi</div>
                <div className="header-value">{reportWorkOrder?.name}</div>
              </Grid>
            </Grid>
            <div className="table-wrapper">
              <MUIDataTable
                data={reportData}
                columns={columns}
                options={options}
              />
            </div>
          </div>
        ) : activeTabIndex === 1 ? (
          <EmployerProductLog
            mode="tabReport"
            rawReportData={reportData ? { workOrder: reportWorkOrder, userProducts: reportData } : null}
            isLoading={!reportWorkOrder}
          />
        ) : (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <LoadingSpinner color="black" />
          </div>
        )}
      </div>
    );
  }
}

export default ProductReport;

/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { GridCell, GridInner } from '@rmwc/grid';
import { Icon } from '@rmwc/icon';
import { IconButton } from '@rmwc/icon-button';
import { Switch } from '@rmwc/switch';
import { ThemeProvider } from '@rmwc/theme';
import {
  Transition,
  animated,
  config,
} from 'react-spring';

import moment, { asDate, getTripDuration, getTripDurationWeekless } from '../utils/moment';
import { getAllowanceLabel } from '../utils';

import { buttonColors } from '../calendar/EmployeeCalendar';

import '@rmwc/icon/icon.css';

const convertCombinationValues = (allowanceCounts) => {
  const newAllowanceCounts = { ...allowanceCounts };

  // Convert combination values to their absolute selves (e.g. two_meals = 2x meal)
  if (allowanceCounts['full_half'] && allowanceCounts['full_half'] !== 0) {
    newAllowanceCounts['full_half'] = 0;
    newAllowanceCounts['full'] += 1;
    newAllowanceCounts['half'] += 1;
  }
  if (allowanceCounts['full_plus_10'] && allowanceCounts['full_plus_10'] !== 0) {
    newAllowanceCounts['full_plus_10'] = 0;
    newAllowanceCounts['full'] += 2;
  }
  if (allowanceCounts['two_meals'] && allowanceCounts['two_meals'] !== 0) {
    newAllowanceCounts['two_meals'] = 0;
    newAllowanceCounts['meal'] += 2;
  }
  if (allowanceCounts['se_double'] && allowanceCounts['se_double'] !== 0) {
    newAllowanceCounts['se_double'] = 0;
    newAllowanceCounts['se'] += 2;
  }

  return newAllowanceCounts;
};

@inject('workOrderTripStore', 't', 'uiStore', 'timelogStore')
@observer
class TimelogTripSummary extends Component {
  ALLOWANCES = {
    none: this.props.t('timelog.allowances.none'),
    full: this.props.t('timelog.allowances.1'),
    half: this.props.t('timelog.allowances.2'),
    meal: this.props.t('timelog.allowances.3'),
    two_meals: this.props.t('timelog.allowances.4'),
    full_plus_2: this.props.t('timelog.allowances.5'),
    full_plus_10: this.props.t('timelog.allowances.6'),
    full_half: this.props.t('timelog.allowances.7'),
    se: this.props.t('timelog.allowances.8'),
    es: this.props.t('timelog.allowances.9'),
    fr: this.props.t('timelog.allowances.10'),
    pt: this.props.t('timelog.allowances.11'),
    se_double: this.props.t('timelog.allowances.12'),
  };

  getStartInfo() {
    const { workOrderTrip: { startTrip } } = this.props;

    let summary = '';

    if (startTrip) {
      summary = `${asDate(startTrip.date)} ${startTrip.time}`;

      startTrip.tripRoute?.[0]?.routeLocations?.forEach((location, index) => {
        if (location?.street && location?.city) {
          summary += ` ${location.street}, ${location.city} ${index < startTrip.tripRoute[0].routeLocations.length - 1 ? '-' : ''}`;
        }
      });
    }
    return summary;
  }

  getEndInfo() {
    const { workOrderTrip: { endTrip } } = this.props;

    let summary = '';

    if (endTrip && endTrip.toDate && endTrip.toTime) {
      summary = `${asDate(endTrip.toDate)} ${endTrip.toTime}`;

      endTrip.tripRoute?.[0]?.routeLocations?.forEach((location, index) => {
        if (location?.street && location?.city) {
          summary += ` ${location?.street}, ${location?.city} ${index < endTrip.tripRoute[0].routeLocations.length - 1 ? '-' : ''}`;
        }
      });
    } else {
      summary = 'Menossa';
    }
    return summary;
  }

  getTripLength() {
    const { workOrderTrip: { startTrip, endTrip } } = this.props;

    let tripLength = '';

    if (startTrip.date && startTrip.time && endTrip.toDate && endTrip.toTime) {
      tripLength = getTripDurationWeekless(startTrip, endTrip);
    } else if (startTrip.date && startTrip.time) {
      // Calculate the duration from start to the present moment by giving endTrip as an empty object (method defaults to current time)
      tripLength = `${getTripDurationWeekless(startTrip, {})} (nykyhetkeen asti)`;
    }

    return tripLength;
  }

  getAllowanceSummary() {
    const { uiStore: { currentUser: { workOrders } }, workOrderTrip, workOrderTrip: { startTrip, endTrip } } = this.props;

    const allowanceSummary = [];

    if (startTrip.date && startTrip.time && !endTrip.toDate && !endTrip.toTime) {
      // Trip has no end date or time set
      const startDate = startTrip.date.format('YYYY-MM-DD');

      let allowanceCounts = { full: 0, half: 0, meal: 0, two_meals: 0, full_plus_2: 0, full_plus_10: 0, full_half: 0, se: 0, se_double: 0, es: 0, fr: 0, pt: 0 }

      workOrders.forEach((wo) => wo.workHours.forEach((wh) => {
        // Since the trip is ongoing (no end date), we are assuming that every allowance after trip start belongs to this trip (trips should never overlap)
        wh.allowance && wh.date.isSameOrAfter(startDate) && (workOrderTrip.workOrders.length === 0 || workOrderTrip.workOrders.map((workOrder) => workOrder.id).includes(wo.id))
          ? allowanceCounts[wh.allowance] += 1
          : null
      }));

      allowanceCounts = convertCombinationValues(allowanceCounts);

      for (const [key, value] of Object.entries(allowanceCounts)) {
        if (value > 0) {
          allowanceSummary.push(
            <p
              key={key}
              style={{ margin: '0px', lineHeight: 1.2 }}
            >
              {`${value}x ${getAllowanceLabel(key)}`}
            </p>
          );
        }
      }
    } else if (startTrip.date && startTrip.time && endTrip.toDate && endTrip.toTime) {
      const startDate = startTrip.date.format('YYYY-MM-DD');
      const endDate = endTrip.toDate.format('YYYY-MM-DD');

      let allowanceCounts = { full: 0, half: 0, meal: 0, two_meals: 0, full_plus_2: 0, full_plus_10: 0, full_half: 0, se: 0, se_double: 0, es: 0, fr: 0, pt: 0 };

      workOrders.forEach((wo) => wo.workHours.forEach((wh) => {
        wh.allowance && wh.date.isBetween(startDate, endDate, null, []) && (workOrderTrip.workOrders.length === 0 || workOrderTrip.workOrders.map((workOrder) => workOrder.id).includes(wo.id))
          ? allowanceCounts[wh.allowance] += 1
          : null
      }));

      allowanceCounts = convertCombinationValues(allowanceCounts);

      for (const [key, value] of Object.entries(allowanceCounts)) {
        if (value > 0) {
          allowanceSummary.push(
            <p
              key={key}
              style={{ margin: '0px' }}
            >
              {`${value}x ${getAllowanceLabel(key)}`}
            </p>
          );
        }
      }
    }

    return allowanceSummary;
  }

  render() {
    const {
      workOrderTrip,
      setAllowanceAutofill,
      tripWorkOrders,
      removeFromWorkOrders,
      allowanceAutofillEnabled,
      currentWorkOrderId,
      uiStore: { currentUser: { workOrders } }
    } = this.props;
    const style = { columnGap: 0, lineHeight: 1.2, marginBottom: '8px' };

    const iconStartStyles = {
      fontSize: '22px',
      color: workOrderTrip?.startTrip.status === 'accepted' ? buttonColors.green.color : 'var(--mdc-theme-primary)',
      position: 'absolute',
      left: '40px',
      top: '-5px',
    };

    const iconEndStyles = {
      fontSize: '22px',
      color: workOrderTrip?.endTrip.status === 'accepted' ? buttonColors.green.color : 'var(--mdc-theme-primary)',
      position: 'absolute',
      left: '53px',
      top: '-5px',
    };

    return (
      <GridCell
        style={{
          backgroundColor: 'var(--mdc-theme-surface, #fff)',
          padding: '15px 10px',
          margin: '0 -10px 15px',
          fontSize: '14px'
        }}
      >
        {workOrderTrip?.id && (
          <>
            <GridInner style={style}>
              <GridCell phone={1} style={{ position: 'relative' }}>
                Alkaa:
                &nbsp;
                {workOrderTrip?.startTrip.status === 'pending' && (
                  <Icon
                    icon="done"
                    style={iconStartStyles}
                  />
                )}
                {workOrderTrip?.startTrip.status === 'accepted' && (
                  <Icon
                    icon="done_all"
                    style={iconStartStyles}
                  />
                )}
                {workOrderTrip?.startTrip.status === 'rejected' && (
                  <Icon
                    icon="info"
                    style={{
                      fontSize: '22px',
                      color: 'red',
                      position: 'absolute',
                      left: '40px',
                      top: '-5px',
                    }}
                  />
                )}
              </GridCell>
              <GridCell phone={3}>
                {workOrderTrip?.startTrip && this.getStartInfo()}
              </GridCell>
            </GridInner>

            <GridInner style={style}>
              <GridCell phone={1} style={{ position: 'relative' }}>
                Päättyy:
                &nbsp;
                {workOrderTrip?.endTrip.status === 'pending' && (
                  <Icon
                    icon="done"
                    style={iconEndStyles}
                  />
                )}
                {workOrderTrip?.endTrip.status === 'accepted' && (
                  <Icon
                    icon="done_all"
                    style={iconEndStyles}
                  />
                )}
                {workOrderTrip?.endTrip.status === 'rejected' && (
                  <Icon
                    icon="info"
                    style={{
                      fontSize: '22px',
                      color: 'red',
                      position: 'absolute',
                      left: '53px',
                      top: '-5px',
                    }}
                  />
                )}
              </GridCell>
              <GridCell phone={3}>
                {workOrderTrip?.endTrip && this.getEndInfo()}
              </GridCell>
            </GridInner>

            <GridInner style={style}>
              <GridCell phone={1}>
                Kesto:
              </GridCell>
              <GridCell phone={3}>
                {workOrderTrip?.startTrip && this.getTripLength()}
              </GridCell>
            </GridInner>

            <GridInner style={style}>
              <GridCell phone={1}>
                Päiväraha:
              </GridCell>
              <GridCell phone={3}>
                {workOrderTrip?.startTrip && workOrderTrip?.endTrip && this.getAllowanceSummary()}
              </GridCell>
            </GridInner>
          </>
        )}

        {tripWorkOrders && tripWorkOrders.length > 0 && (
          <GridInner style={{ ...style, marginTop: '10px' }}>
            <GridCell phone={1}>
              {/* Matkan keikat: */}
            </GridCell>
            <GridCell phone={3}>
              <Transition
                items={tripWorkOrders}
                from={{ opacity: 0 }}
                enter={{ opacity: 1 }}
                leave={{ opacity: 0 }}
                // delay={100}
                config={config.default}
                keys={(tripWorkOrder) => `transition_${tripWorkOrder.id}`}
              >
                {(styles, tripWorkOrder) => (
                  <animated.div style={styles}>
                    <div style={{ display: 'flex', paddingBottom: '15px' }}>
                      <div style={{ width: '100%' }}> {/* alignSelf: 'center' */}
                        {tripWorkOrder.name}
                      </div>
                      {tripWorkOrder.id !== currentWorkOrderId || tripWorkOrders.length > 1 && (
                        <IconButton
                          icon="delete_outlined"
                          onClick={() => removeFromWorkOrders(tripWorkOrder.id)}
                          style={{ color: '#c7c9cc', paddingTop: '0', paddingBottom: '0', height: 'fit-content' }}
                        />
                      )}
                    </div>
                  </animated.div>
                )}
              </Transition>
            </GridCell>
          </GridInner>
        )}

        <GridInner style={style}>
          {workOrderTrip?.id && <GridCell phone={1} />}
          <GridCell phone={workOrderTrip?.id ? 3 : 4}>
            <ThemeProvider
              options={{
                primaryOnBackground: 'white',
                secondary: buttonColors.yellow.color,
                textPrimaryOnBackground: 'var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important',
              }}
            >
              <Switch
                className="pk-switch pk-switch-reverse"
                checked={allowanceAutofillEnabled}
                // onChange={() => console.log('Trigger allowance autofill change for trip')}
                onChange={(evt) => (setAllowanceAutofill(evt.currentTarget.checked))}
                label="Päivärahojen automaattitäyttö"
                use="text-hint-on-dark"
              />
            </ThemeProvider>
            {!workOrderTrip?.allowanceAutofillEnabled && allowanceAutofillEnabled && workOrderTrip?.id && (
              <div style={{ color: 'red', paddingLeft: '4px' }}>
                Matkan ajalle itse täyttämäsi päivärahat ylikirjoitetaan.
              </div>
            )}
          </GridCell>
        </GridInner>
      </GridCell>
    );
  }
}

export default TimelogTripSummary;

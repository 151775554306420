/* eslint-disable quotes, camelcase */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

// import {
//   Transition,
//   animated,
//   config,
// } from 'react-spring';

// import {
//   Dialog, DialogTitle, DialogActions, DialogButton, DialogContent,
// } from '@rmwc/dialog';
import {
  IconButton,
  Icon,
  Grid,
  // TableRow,
  // TableCell,
  // TableFooter,
  TextField,
  Button,
} from '@material-ui/core';
// import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
// import { DateUtils } from 'react-day-picker';
// import { sortBy } from 'lodash';
// import { TextField } from '@rmwc/textfield';
// import { Checkbox } from '@rmwc/checkbox';

// import { Button } from '@material-ui/core'; // IconButton
// import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
// import DashboardIcon from '@material-ui/icons/Dashboard';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
// import Brightness1Icon from '@material-ui/icons/Brightness1';
// import StopIcon from '@material-ui/icons/Stop';
import ReactToPrint from 'react-to-print';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
// import { CSVLink } from 'react-csv';
import { get } from 'lodash';
import { getAllowanceLabel } from '../utils';

// import EmployerTimelogCard from './employerTimelogCard';
// import EmployerTimelogTable from './employerTimelogTable';
import TimelogReportFilterDialog, { customFilterComparison, compressFilters } from './TimelogReportFilterDialog';
import LoadingSpinner from '../shared/LoadingSpinner';
// import EmployeeInfoDialog from './EmployeeInfoDialog';
// import WorkOrderInfoDialog from './WorkOrderInfoDialog';
// import WorkOrderTrip from '../timelog/WorkOrderTrip';
// import EmployerWorkOrderTripCard from '../employer-work-order-trips/employerWorkOrderTripCard';
import moment from '../utils/moment';
import normalizeFileName from '../utils/NormalizeFileName';
// import dayjs from '../utils/dayjs';
import './index.css';
import './html-table.css';
import EmployerTimelogTableHtmlWrapper from './EmployerTimelogTableHtmlWrapper';
import TimelogReportSettingsDialog from './TimelogReportSettingsDialog';

// Modified from ChatGPT 4o 23.7.2024
const formatName = (name) => {
  // Trim whitespace from the beginning and end of the string
  const trimmedName = name.trim();

  // Split the string into parts based on the comma
  const parts = trimmedName.split(',');

  // Trim whitespace from each part and capitalize the first letter
  const lastName = parts[0].trim();
  const firstName = parts[1].trim();

  // Capitalize the first letter of each part and lowercase the rest
  const capitalizedLastName = lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
  const capitalizedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();

  // Return the formatted name
  return `${capitalizedLastName}, ${capitalizedFirstName}`;
};

@inject('uiStore', 'timelogStore', 't') // 'actionCableStore'
@observer
class WorkHourReport extends Component {
  // cableApp = {};

  constructor(props) {
    super(props);
    this.employerTimelogTableRef = React.createRef();
    this._dateRangePickerFrom = React.createRef();
    this._dateRangePickerTo = React.createRef();

    this.inputDateFormat = 'DD.MM.YYYY';
    this.queryDateFormat = 'YYYY-MM-DD';

    const {
      timelogStore: {
        timelogReportFrom,
        timelogReportTo,
        timelogReportFilters,
        timelogReportColumnVisibility,
      },
    } = props;

    this.state = {
      originalWorkHoursWithMeta: null,
      currentWorkHoursWithMeta: null,
      // originalWorkOrderTripsWithMeta: null,
      // currentWorkOrderTripsWithMeta: null,
      filterDialogOpen: false,
      filterCount: 0,
      // currentFilters: ['work_hour.status.pending'], // Must contain the FilterDialog defaultFilters
      currentFilters: timelogReportFilters || [],
      columnVisibility: timelogReportColumnVisibility || [],
      from: timelogReportFrom || null, // startOfMonth.format(inputDateFormat),
      to: timelogReportTo || null, // endOfMonth.format(inputDateFormat),
      isLoading: false,
      errors: {},
    };

    this.toggleFilterDialog = this.toggleFilterDialog.bind(this);
    this.filterData = this.filterData.bind(this);
  }

  componentDidMount() {
    // const { actionCableStore } = this.props;
    const { timelogStore: { timelogReportFrom, timelogReportTo } } = this.props;

    if (timelogReportFrom && timelogReportTo) {
      const start = moment(timelogReportFrom, this.inputDateFormat).format(this.queryDateFormat);
      const end = moment(timelogReportTo, this.inputDateFormat).format(this.queryDateFormat);
      this.getTimelogs(start, end);
    } else {
      const startOfMonth = moment().startOf('month').format(this.queryDateFormat);
      const endOfMonth = moment().endOf('month').format(this.queryDateFormat);
      this.getTimelogs(startOfMonth, endOfMonth);
      this.setState({
        from: moment().startOf('month').format(this.inputDateFormat),
        to: moment().endOf('month').format(this.inputDateFormat),
      }, () => {
        this.checkQueryButtonDisabled();
      });
    }
    // this.cable = actionCableStore.cableOn
    //   ? actionCableStore.subscribe('EmployerWorkHoursChannel', this.processUpdateResponse)
    //   : null;
  }

  setActiveFilters = (currentFilters) => {
    const { timelogStore } = this.props;
    this.setState({ currentFilters }, () => {
      timelogStore.setTimelogReportFilters(currentFilters);
    });
  }

  getTimelogs = (from, to) => {
    // const { timelogStore } = this.props;
    const { uiStore: { currentUser: { accountInfo: { absencesEnabled } } } } = this.props;
    const { currentFilters, loadingError } = this.state;

    this.setState({
      isLoading: true,
      loadMode: 'timelogs',
      // offset,
      originalWorkHoursWithMeta: [],
      currentWorkHoursWithMeta: [],
      loadingError: null,
    }, () => {
      // We do not use superagent like every other request because superagent apparently doesn't support streaming at all, or at least the version we use
      // It fails to trigger the events to process separate chunks and only gives the final result
      // Therefore we call the API URL directly with fetch()
      const { protocol, hostname, port } = window.location;
      const baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
      const url = `${baseUrl}/api/users/4/employer_timelog_report_by_dates_batch_stream?from=${from}&to=${to}`;

      // We use the fetch API instead of EventSource to request the stream: EventSource returns 406 due to incorrect Accept header and it cannot be manually set in EventSource
      // Derived from ChatGPT 4o suggestions 15.7.24-16.7.24
      const fetchData = async () => {
        try {
          const response = await fetch(url, {
            headers: {
              // eslint-disable-next-line quote-props
              'Accept': 'application/json',
            },
          });

          if (!response.ok) {
            this.setState({
              loadingError: `Virhekoodi ${response.status}`,
            });
          }

          const reader = response.body.getReader();
          const decoder = new TextDecoder('utf-8');

          let buffer = '';
          // eslint-disable-next-line no-constant-condition
          while (true) {
            // eslint-disable-next-line no-await-in-loop
            const { value, done } = await reader.read();
            if (done) {
              break;
            }

            buffer += decoder.decode(value, { stream: true });

            let boundary;
            // eslint-disable-next-line no-cond-assign
            while ((boundary = buffer.indexOf('\n\n')) !== -1) {
              const chunk = buffer.slice(0, boundary);
              buffer = buffer.slice(boundary + 2);

              if (chunk.startsWith('data: ')) {
                const jsonChunk = JSON.parse(chunk.slice(6));

                // let filteredRows = [...jsonChunk];
                let compressedFilters;

                if (currentFilters.length > 0) {
                  compressedFilters = compressFilters(currentFilters);
                }
                // filteredRows = [...jsonChunk].filter((row) => {
                //   const filtersMatch = customFilterComparison(row, compressedFilters);
                //   if (filtersMatch) {
                //     return true;
                //   }
                //   return false;
                // });
                const filteredRows = [];
                const processedChunk = [...jsonChunk].map((row) => {
                  // Format the date and times for work hours so that they aren't reformatted with every table/row/cell re-render
                  // eslint-disable-next-line no-param-reassign, camelcase
                  row.work_hour.formattedDate = moment(row.work_hour.date).format('dd DD.MM.');
                  // eslint-disable-next-line no-param-reassign, camelcase
                  row.work_hour.fromFormatted = row?.work_hour.from ? moment(row.work_hour.from).format('HH:mm') : '?';
                  // eslint-disable-next-line no-param-reassign, camelcase
                  row.work_hour.toFormatted = row?.work_hour.to ? moment(row.work_hour.to).format('HH:mm') : '?';

                  const allowance = row?.work_hour.tax_exempt_trip_expenses?.find((expense) => expense.name === 'allowance')?.value || row?.work_hour.allowance;
                  if (allowance) {
                    // eslint-disable-next-line no-param-reassign
                    row.work_hour.allowance = getAllowanceLabel(allowance, true);
                  }

                  // eslint-disable-next-line camelcase
                  const timeFrom = row.work_hour.fromFormatted || row.work_hour.from;
                  // eslint-disable-next-line camelcase
                  const timeTo = row.work_hour.toFormatted || row.work_hour.to;
                  // eslint-disable-next-line no-param-reassign
                  row.hours = `${timeFrom}-${timeTo}`;

                  // A hack to make the names look good for the report (no spaces or non-capitalized)
                  // eslint-disable-next-line no-param-reassign
                  row.user.full_name = formatName(row.user.full_name);

                  // Convert the boolean to string
                  const emergencyWork = row?.work_hour.emergency_work;
                  if (emergencyWork) {
                    let newValue = '';
                    if (emergencyWork === 'true') {
                      newValue = 'Kyllä';
                    }
                    // eslint-disable-next-line no-param-reassign
                    row.work_hour.emergency_work = newValue;
                  }

                  let filtersMatch = true;
                  if (currentFilters.length > 0) {
                    filtersMatch = customFilterComparison(row, compressedFilters);
                  }
                  if (filtersMatch) {
                    filteredRows.push(row);
                  }
                  return row;
                });
                // }

                this.setState((prevState) => ({
                  originalWorkHoursWithMeta: [...prevState.originalWorkHoursWithMeta, ...processedChunk],
                  currentWorkHoursWithMeta: [...prevState.currentWorkHoursWithMeta, ...filteredRows],
                }));
              } else if (chunk.startsWith('event: end')) {
                console.log('Timelog stream over');
                this.setState({
                  isLoading: false,
                }, () => {
                  if (absencesEnabled && !loadingError) {
                    this.getAbsences(from, to);
                  }
                });
                break;
              }
            }
          }
        } catch (err) {
          console.error('ERROR: ', err);
          this.setState({
            isLoading: false,
          });
        }
      };

      fetchData();
    });
    // });
  }

  getAbsences = (from, to) => {
    // const { timelogStore } = this.props;
    const { currentFilters } = this.state;

    this.setState({
      isLoading: true,
      loadMode: 'absences',
      loadingError: null,
      // offset,
      // originalWorkHoursWithMeta: [],
      // currentWorkHoursWithMeta: [],
    }, () => {
      // We do not use superagent like every other request because superagent apparently doesn't support streaming at all, or at least the version we use
      // It fails to trigger the events to process separate chunks and only gives the final result
      // Therefore we call the API URL directly with fetch()
      const { protocol, hostname, port } = window.location;
      const baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
      const url = `${baseUrl}/api/users/4/employer_timelog_report_absences_by_dates_batch_stream?from=${from}&to=${to}`;

      // We use the fetch API instead of EventSource to request the stream: EventSource returns 406 due to incorrect Accept header and it cannot be manually set in EventSource
      // Derived from ChatGPT 4o suggestions 15.7.24-16.7.24
      const fetchData = async () => {
        try {
          const response = await fetch(url, {
            headers: {
              // eslint-disable-next-line quote-props
              'Accept': 'application/json',
            },
          });

          if (!response.ok) {
            this.setState({
              loadingError: `Virhekoodi ${response.status}`,
            });
          }

          const reader = response.body.getReader();
          const decoder = new TextDecoder('utf-8');

          let buffer = '';
          // eslint-disable-next-line no-constant-condition
          while (true) {
            // eslint-disable-next-line no-await-in-loop
            const { value, done } = await reader.read();
            if (done) {
              break;
            }

            buffer += decoder.decode(value, { stream: true });

            let boundary;
            // eslint-disable-next-line no-cond-assign
            while ((boundary = buffer.indexOf('\n\n')) !== -1) {
              const chunk = buffer.slice(0, boundary);
              buffer = buffer.slice(boundary + 2);

              if (chunk.startsWith('data: ')) {
                const jsonChunk = JSON.parse(chunk.slice(6));

                let compressedFilters;

                if (currentFilters.length > 0) {
                  compressedFilters = compressFilters(currentFilters);
                }

                const filteredRows = [];
                const processedChunk = [...jsonChunk].map((row) => {
                  // Format the date and times for work hours so that they aren't reformatted with every table/row/cell re-render
                  // eslint-disable-next-line no-param-reassign, camelcase
                  row.work_hour.formattedDate = moment(row.work_hour.date).format('dd DD.MM.');

                  // A hack to make the names look good for the report (no spaces or non-capitalized)
                  // eslint-disable-next-line no-param-reassign
                  row.user.full_name = formatName(row.user.full_name);

                  let filtersMatch = true;
                  if (currentFilters.length > 0) {
                    filtersMatch = customFilterComparison(row, compressedFilters);
                  }
                  if (filtersMatch) {
                    filteredRows.push(row);
                  }
                  return row;
                });

                this.setState((prevState) => ({
                  originalWorkHoursWithMeta: [...prevState.originalWorkHoursWithMeta, ...processedChunk],
                  currentWorkHoursWithMeta: [...prevState.currentWorkHoursWithMeta, ...filteredRows],
                }));
              } else if (chunk.startsWith('event: end')) {
                console.log('Absence stream over');
                this.setState({
                  isLoading: false,
                });
                break;
              }
            }

            // Sort after the query
            this.sortWorkHoursWithMeta();
          }
        } catch (err) {
          console.error('ERROR: ', err);
          this.setState({
            isLoading: false,
          });
        }
      };

      fetchData();
    });
    // });
  }

  setColumnVisibility = (columnVisibility) => {
    this.setState({ columnVisibility }, () => {
      const { timelogStore } = this.props;
      timelogStore.setTimelogReportColumnVisibility(columnVisibility);
    });
  }

  checkQueryButtonDisabled = () => {
    const { from, to } = this.state;

    const fromMoment = moment(from, this.inputDateFormat);
    const toMoment = moment(to, this.inputDateFormat);

    const diffInyears = Math.abs(fromMoment.diff(toMoment, 'years', true));
    if (from && fromMoment.isValid() && to && toMoment.isValid() && toMoment.isAfter(fromMoment) && diffInyears <= 1) {
      // Enabling the button and resetting the rangeOverYearError error
      this.setState({
        queryButtonDisabled: false,
        rangeOverYearError: false,
      }, () => {
        const { timelogStore } = this.props;
        // Save the valid query dates to timelogStore (session)
        timelogStore.setTimelogReportDates(from, to);
      });
    } else {
      // Disabling the button and setting rangeOverYearError to true if that was an issue
      this.setState({
        queryButtonDisabled: true,
        rangeOverYearError: diffInyears >= 1,
      });
    }
  }

  handleDayClickFrom = (day) => {
    this.setState({
      from: moment(day).format(this.inputDateFormat),
    }, () => {
      this.checkQueryButtonDisabled();
    });
  }

  handleDayClickTo = (day) => {
    this.setState({
      to: moment(day).format(this.inputDateFormat),
    }, () => {
      this.checkQueryButtonDisabled();
    });
  }

  handleSettingsDialogClose = () => {
    this.setState({
      settingsDialogOpen: false,
    });
  }

  customDateRangePickerOverlay = ({ classNames, children, ...props }) => (
    <div
      className={classNames.overlayWrapper}
      {...props}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }} className={classNames.overlay}>
        {children}
        <div style={{ paddingBottom: '10px', textAlign: 'center' }}>
          <button type="button" className="accept-button mdc-dialog__button mdc-button" onClick={() => this.closeDateRangePicker()}>Valmis</button>
        </div>
      </div>
    </div>
  );

  closeDateRangePicker = () => {
    // this._dateRangePicker.current.hideDayPicker();

    // Hide both from and to here
    this._dateRangePickerFrom.current.hideDayPicker();
    this._dateRangePickerTo.current.hideDayPicker();
  }

  toggleColumnVisibility = (columnKey) => {
    const { columnVisibility } = this.state;

    const columnIndex = columnVisibility.findIndex((column) => column.key === columnKey);
    const updatedColumnVisibility = [...columnVisibility];

    if (columnIndex !== -1) {
      // Toggle visibility
      updatedColumnVisibility[columnIndex].visible = !columnVisibility[columnIndex].visible;
      this.setState({
        columnVisibility: updatedColumnVisibility,
      }, () => {
        const { timelogStore } = this.props;
        timelogStore.setTimelogReportColumnVisibility(columnVisibility);
      });
    }
  }

  triggerQuery() {
    const { from, to } = this.state;

    const formattedFrom = moment(from, this.inputDateFormat).format(this.queryDateFormat);
    const formattedTo = moment(to, this.inputDateFormat).format(this.queryDateFormat);
    this.getTimelogs(formattedFrom, formattedTo);
  }

  sortWorkHoursWithMeta() {
    const { currentWorkHoursWithMeta, originalWorkHoursWithMeta } = this.state;

    const sortedCurrentWorkHoursWithMeta = [...currentWorkHoursWithMeta].sort((a, b) => {
      // First, compare by work_hour.date
      const dateComparison = new Date(get(a, 'work_hour.date')) - new Date(get(b, 'work_hour.date'));
      if (dateComparison !== 0) {
        return dateComparison;
      }
      // If work_hour.date is the same, compare by user.full_name
      const nameA = get(a, 'user.full_name').toUpperCase(); // Ignore case for sorting
      const nameB = get(b, 'user.full_name').toUpperCase(); // Ignore case for sorting

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });

    const sortedOriginalWorkHoursWithMeta = [...originalWorkHoursWithMeta].sort((a, b) => {
      // First, compare by work_hour.date
      const dateComparison = new Date(get(a, 'work_hour.date')) - new Date(get(b, 'work_hour.date'));
      if (dateComparison !== 0) {
        return dateComparison;
      }
      // If work_hour.date is the same, compare by user.full_name
      const nameA = get(a, 'user.full_name').toUpperCase(); // Ignore case for sorting
      const nameB = get(b, 'user.full_name').toUpperCase(); // Ignore case for sorting

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });

    this.setState({
      currentWorkHoursWithMeta: sortedCurrentWorkHoursWithMeta,
      originalWorkHoursWithMeta: sortedOriginalWorkHoursWithMeta,
    });
  }

  filterData(filteredData, filterCount, activeFilters) {
    const { timelogStore } = this.props;
    timelogStore.setTimelogReportFilters(activeFilters);

    if (filteredData) {
      filteredData.sort((a, b) => this.sortWorkHours(a, b));
    }

    this.setState({
      currentWorkHoursWithMeta: filteredData,
      filterCount,
      // tableRowsSelected: updatedTableRowsSelected,
      // workHoursSelected: updatedWorkHoursSelected,
    });
  }

  toggleFilterDialog() {
    this.setState((prevState) => ({
      filterDialogOpen: !prevState.filterDialogOpen,
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  sortWorkHours(a, b) {
    // If both user name and work hour date are identical, sort by created_at
    if (a.user_name === b.user_name && a.work_hour.date === b.work_hour.date) {
      return a.work_hour.created_at > b.work_hour.created_at ? 1 : -1;
    }

    // Otherwise, sort by work hour date and user name
    if (a.user_name === b.user_name) {
      return (a.work_hour.date > b.work_hour.date) ? 1 : -1;
    }

    return (a.user_name > b.user_name) ? 1 : -1;
  }

  renderDateRange() {
    const { t } = this.props;
    const { from, to } = this.state;
    let finalValue = '';

    // Check if the range has both 'from' and 'to' values
    if (from && to) {
      const rangeFrom = moment(from, this.inputDateFormat);
      const rangeTo = moment(to, this.inputDateFormat);

      let weekNumberString = '';
      // User selected a single day, we need to find the week numbers manually
      const fromWeekNumber = rangeFrom.isoWeek();
      const toWeekNumber = rangeTo.isoWeek();
      // We do not want to add the '-' if there's no second different week number
      const toWeekNumberString = toWeekNumber && toWeekNumber !== fromWeekNumber ? `-${toWeekNumber}` : '';
      weekNumberString = `${t('resource_availability.week_prefix')}${fromWeekNumber + toWeekNumberString}`;

      const dayRange = `${rangeFrom.format('dd DD.MM.YYYY')} - ${rangeTo.format('dd DD.MM.YYYY')}`;
      finalValue = `${weekNumberString} | ${dayRange}`;
    } else {
      finalValue = 'Valitse päivät'; // Default value if range is not set
    }
    return finalValue;
  }

  renderDatePicker(attr, label, styles, ref, extraOverlayClassName = null) {
    const {
      [attr]: stateAttr,
      errors,
    } = this.state;

    return (
      <DayPickerInput
        ref={ref}
        value={stateAttr}
        component={
          (props) => (
            <TextField
              {...props}
              {...{
                autoComplete: 'off',
                className: 'daypicker-input',
                // type: 'button',
                label,
                style: { ...styles, width: '150px', overflow: 'hidden' },
                // onChange: () => {
                //   this.closeDateRangePicker();
                // },
                onBlur: (evt) => {
                  const newDate = moment(evt.target.value, this.inputDateFormat);
                  if (newDate.isValid()) {
                    // If valid, update state
                    this.setState((prevState) => ({
                      [attr]: newDate.format(this.inputDateFormat),
                      errors: { ...prevState.errors, [attr]: false },
                      //
                    }), () => {
                      this.checkQueryButtonDisabled();
                    });
                  } else {
                    // Otherwise, mark as erroneous
                    this.setState((prevState) => ({
                      queryButtonDisabled: true,
                      errors: { ...prevState.errors, [attr]: true },
                    }));
                  }
                  // Can't be here because then the datePicker is closed due to immediate onBlur (clicking outside TextField) before clicking a day can be registered
                  // this.closeDateRangePicker();
                },
              }}
            />
          )
        }
        inputProps={{
          ref: null, // This is needed to remove a "Function components cannot be given refs" warning
          className: '',
          style: { ...styles, width: '150px', textTransform: 'capitalize' },
          name: 'date-range',
          // readOnly: true,
          error: errors[attr],
        }}
        dayPickerProps={{
          // Need to use separate methods because the attr isn't part of the onDayClick parameters
          onDayClick: attr === 'from' ? this.handleDayClickFrom : this.handleDayClickTo,
          locale: 'fi',
          localeUtils: MomentLocaleUtils,
          className: 'employer-daypicker',
          showWeekNumbers: true,
        }}
        formatDate={formatDate}
        parseDate={parseDate}
        overlayComponent={this.customDateRangePickerOverlay}
        keepFocus={false}
        // hideOnDayClick={false}
        hideOnDayClick
        format="DD.MM.YYYY"
        classNames={{
          container: '',
          overlayWrapper: 'DayPickerInput-OverlayWrapper',
          overlay: extraOverlayClassName ? `DayPickerInput-Overlay ${extraOverlayClassName}` : 'DayPickerInput-Overlay',
        }}
      />
    );
  }

  // eslint-disable-next-line no-unused-vars
  renderActionButtons(csvData, headers) {
    const { reportWorkOrder } = this.state;
    const { uiStore: { currentUser } } = this.props;

    const filename = `${currentUser?.accountInfo?.name}_${reportWorkOrder?.name}`;
    // eslint-disable-next-line no-unused-vars
    const normalizedFileName = normalizeFileName(filename);

    return (
      <div style={{ textAlign: 'right' }}>
        {/* <button type="button" className="small MuiButtonBase-root MuiIconButton-root">
          <CSVLink
            data={csvData}
            headers={headers}
            style={{ color: 'black' }}
            filename={`${normalizedFileName}.csv`}
            className="material-icons MuiIcon-root MuiIcon-fontSizeLarge"
            // Separator needs to be explicitly set or else software will interpret the CSV differently based on things like user locale and operating system
            separator=","
          >
            download
          </CSVLink>
        </button> */}

        <ReactToPrint
          // NOTE: Do NOT pass an `onClick` prop to the root node of the returned component as it will be overwritten.
          trigger={() => (
            <IconButton
              aria-label="settings"
              className="small"
              /* For some reason the styles are messed up when TimelogReportSettingsDialog is opened, e.g. the these IconButtons on this index lose their padding when the dialog is opened unless it is inline style */
              /* Checkbox heights are also different compared to the billing/ReportSettingsDialog where this was copied from */
              // Caused by the order of CSS classes being applied changing in these buttons when the dialog is open. Why?
              style={{ padding: '12px' }}
            >
              <Icon style={{ color: 'black' }} fontSize="large">print</Icon>
            </IconButton>
          )}
          content={() => this.componentRef}
        />

        <IconButton
          aria-label="settings"
          className="small"
          onClick={() => this.setState({ settingsDialogOpen: true })}
          /* For some reason the styles are messed up when TimelogReportSettingsDialog is opened, e.g. the these IconButtons on this index lose their padding when the dialog is opened unless it is inline style */
          /* Checkbox heights are also different compared to the billing/ReportSettingsDialog where this was copied from */
          // Caused by the order of CSS classes being applied changing in these buttons when the dialog is open. Why?
          style={{ padding: '12px' }}
        >
          <Icon style={{ color: 'black' }} fontSize="large">settings</Icon>
        </IconButton>

        {/* <IconButton
          aria-label="settings"
          onClick={() => this.setState({ settingsDialogOpen: true })}
        >
          <Icon style={{ color: 'black' }} fontSize="large">settings</Icon>
        </IconButton> */}
      </div>
    );
  }

  render() {
    const { uiStore: { currentUser } } = this.props;
    const { timelogStore: { timelogReportFilters } } = this.props;
    const {
      originalWorkHoursWithMeta,
      // originalWorkOrderTripsWithMeta,
      currentWorkHoursWithMeta,
      // currentWorkOrderTripsWithMeta,
      // filterTrips,
      filterDialogOpen,
      filterCount,
      // defaultInputValue,
      // inputValue,
      isLoading,
      loadMode,
      columnVisibility,
      settingsDialogOpen,
      loadingError,
      rangeOverYearError,
      queryButtonDisabled,
    } = this.state;
    // const { uiStore: { currentUser: { accountInfo: { salaryPeriodCategoriesEnabled } } } } = this.props;

    // const acceptMultipleDisabled = workHoursSelected.find((whData) => !whData.id);

    const styles = {
      backgroundColor: '#FFFFFF',
      width: '100%',
      margin: '0.3em 0',
      maxWidth: '400px',
    };

    const filters = [
      { title: 'Työntekijä', key: 'user.full_name', translate: false },
      { title: 'Tila', key: 'work_hour.status', translate: true },
      // { title: 'Kirjausten hyväksyjä', key: 'accepting_employers', translate: false },
      {
        title: 'Keikka',
        key: 'work_order_info.name',
        translate: false,
        mode: 'workhour',
      },
      {
        title: 'Poissaolo',
        key: 'work_order_info.name',
        translate: false,
        mode: 'absence',
      },
    ];

    const grid = 6;

    // // MOVE THIS OUTSIDE OF RENDER
    // const headers = [...mainColumns].reduce((accumulator, column) => {
    //   // We already have from and to columns manually inserted so we don't want the time column
    //   if (column.options.display && column.name !== 'time') {
    //     accumulator.push(
    //       {
    //         label: column.label,
    //         // remove the workHour prefix (used in the table as a nested accessor)
    //         key: column.name.split('.').pop(),
    //       },
    //     );
    //   }
    //   return accumulator;
    // }, []);
    // // Add the user's fullName column as the first one
    // headers.unshift(
    //   {
    //     label: 'Työntekijä',
    //     key: 'fullName',
    //   },
    //   {
    //     label: 'Pvm',
    //     key: 'date',
    //   },
    //   {
    //     label: 'Alku',
    //     key: 'from',
    //   },
    //   {
    //     label: 'Loppu',
    //     key: 'to',
    //   },
    // );


    // // MOVE THIS OUTSIDE OF RENDER
    // let csvData = [];
    // currentWorkHoursWithMeta?.forEach((entry) => {
    //   if (!entry.isGroup) {
    //     const timelogEntry = { fullName: entry.user.fullName, ...entry.work_hour };
    //     if (timelogEntry.allowance) {
    //       timelogEntry.allowance = `${t(`timelog.attributes.${timelogEntry.allowance}_short`)}`;
    //     }
    //     if (!timelogEntry.emergency_work) {
    //       timelogEntry.emergency_work = null;
    //     } else {
    //       timelogEntry.emergency_work = 'Kyllä';
    //     }

    //     timelogEntry.date = timelogEntry.date.format(inputDateFormat);

    //     csvData.push(timelogEntry);
    //   }
    // });
    // csvData = sortBy([...csvData], ['fullName', 'date'], ['desc', 'desc']);

    return (
      <div className="employer-timelog-wrapper">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

          <div>
            <div style={{ display: 'flex' }}>
              <div style={{ fontSize: '16px', margin: 'auto 0', marginLeft: '20px' }}>
                <div className="header-label" style={{ lineHeight: 1 }}>
                  Alku
                  <span style={{ fontSize: '12px', color: 'grey', marginLeft: '5px' }}>(PV.KK.VVVV)</span>
                </div>
                {this.renderDatePicker('from', '', styles, this._dateRangePickerFrom, 'daypicker-overlay-z-index')}
              </div>

              <div style={{ fontSize: '16px', margin: 'auto 0', marginLeft: '20px' }}>
                <div className="header-label" style={{ lineHeight: 1 }}>
                  Loppu
                  <span style={{ fontSize: '12px', color: 'grey', marginLeft: '5px' }}>(PV.KK.VVVV)</span>
                </div>
                {this.renderDatePicker('to', '', styles, this._dateRangePickerTo, 'daypicker-overlay-z-index')}
              </div>

              {isLoading && (
                <div style={{ display: 'flex', marginLeft: '10px' }}>
                  <div style={{ height: 'fit-content', margin: 'auto 0' }}>
                    <LoadingSpinner color="black" size="mini" />
                  </div>
                  <div
                    style={{
                      fontSize: '16px',
                      fontWeight: 'normal',
                      margin: 'auto 0',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {`Haetaan ${loadMode === 'timelogs' ? 'tuntikirjauksia' : 'poissaoloja'}...`}
                  </div>
                </div>
              )}

              {!isLoading && (
                <Button
                  type="button"
                  onClick={() => this.triggerQuery()}
                  className="employer-accept-button mdc-button"
                  // Disabled if:
                  // From is null or invalid
                  // To is null or invalid
                  // To is before From
                  disabled={queryButtonDisabled}
                  startIcon={<SearchIcon />}
                  style={{
                    // width: '100%',
                    // maxWidth: '350px',
                    // width: '335px',
                    margin: 'auto 0',
                    marginLeft: '30px',
                    width: '80px',
                    bottom: '-5px',
                  }}
                >
                  Hae
                </Button>
              )}

              {!isLoading && loadingError && (
                <div
                  style={{
                    color: 'red',
                    marginLeft: '30px',
                  }}
                >
                  {loadingError}
                </div>
              )}
            </div>

            <div style={{ display: 'flex', marginLeft: '20px' }}>
              {rangeOverYearError && (
                <span style={{ fontSize: '12px', color: 'red' }}>Suurin haettava aikaväli on yksi vuosi.</span>
              )}
            </div>
          </div>

          <div
            style={{
              padding: '10px',
              width: '100%',
              // textAlign: 'end',
              // maxWidth: '350px',
              display: 'flex',
              // justifyContent: viewMode === 'table' ? 'space-between' : 'flex-end',
              // flexWrap: viewMode === 'table' ? 'wrap-reverse' : 'initial',
            }}
            className="filter-button"
          >
            <Button
              type="button"
              onClick={() => this.toggleFilterDialog()}
              className="employer-reject-button mdc-button"
              startIcon={<FilterListIcon />}
              style={{
                // width: '100%',
                // maxWidth: '350px',
                width: '335px',
                margin: 'auto 0',
                marginRight: '10px',
              }}
            >
              Suodata
              {filterCount !== 0 && (
                <span style={{ marginLeft: '5px' }}>
                  (
                  {filterCount}
                  )
                </span>
              )}
            </Button>

            <div style={{ textAlign: 'right' }}>
              {/* {this.renderActionButtons(csvData, headers)} */}
              {this.renderActionButtons(null, null)}
            </div>
          </div>
        </div>

        <div>
          {/* eslint-disable-next-line no-return-assign */}
          <div className="printable" id="report" ref={(el) => (this.componentRef = el)}>
            <>
              <Grid
                container
                spacing={2}
                className="billing-report-header pdf-header"
                justifyContent="space-between"
              >
                <Grid item xs={4}>
                  <span>{moment().format('DD/MM/YYYY')}</span>
                </Grid>
                <Grid item xs={4}>
                  <span style={{ textTransform: 'uppercase' }}>Kirjausraportit</span>
                  <span>{` - ${currentUser?.accountInfo?.officialName}`}</span>
                </Grid>
                <Grid item xs={4}>
                  {/* <span>{currentUser?.accountInfo?.accountPicture}</span> */}
                  <img style={{ maxWidth: '100%' }} src={currentUser?.accountInfo?.accountPicture} alt="company logo" />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ width: '100%', height: 'fit-content', padding: '16px 0px' }} className="billing-report-header">
                {/* <Grid item md={grid} xs={12} className="report-purchaser">
                  <div className="header-label">Tilaaja</div>
                  <div className="header-value">asd</div>
                </Grid> */}
                <Grid item md={grid} xs={12} className="daypicker-input-value show-print">
                  <div className="header-label">Ajalta</div>
                  <div className="header-value capitalized">{this.renderDateRange()}</div>
                </Grid>
                {/* <Grid item md={grid} xs={12} className="report-duration hidden-print">
                  <div className="header-label" style={{ lineHeight: 1 }}>Ajalta</div>
                  <div
                    className="header-value daypicker-value"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {this.renderDateRangePicker('', styles)}
                    {isLoading && (
                      <div style={{ display: 'flex', marginLeft: '10px' }}>
                        <LoadingSpinner color="black" size="mini" />
                        <div
                          style={{
                            fontSize: '16px',
                            fontWeight: 'normal',
                            margin: 'auto 0',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {`Ladataan ${loadMode === 'timelogs' ? 'tuntikirjauksia' : 'poissaoloja'}...`}
                        </div>
                      </div>
                    )}
                  </div>
                </Grid> */}
                {/* <Grid item md={grid} xs={12} className="report-location">
                  <div className="header-label">Kohde</div>
                  <div className="header-value">{reportWorkOrder?.location}</div>
                </Grid>
                <Grid item md={grid} xs={12} className="report-name">
                  <div className="header-label">Nimi</div>
                  <div className="header-value">{reportWorkOrder?.name}</div>
                </Grid> */}
              </Grid>
              <div style={{ overflow: 'scroll', padding: '10px 0' }}>
                <EmployerTimelogTableHtmlWrapper currentWorkHoursWithMeta={currentWorkHoursWithMeta} emitColumnVisibility={this.setColumnVisibility} columnVisibility={columnVisibility} />
              </div>
            </>
          </div>
        </div>
        <div>
          {/* Require originalWorkHoursWithMeta for the rendering here because otherwise FilterDialog renders with null originalWorkHoursWithMeta and would require some ComponentDidUpdate code */}
          {originalWorkHoursWithMeta && (
            <TimelogReportFilterDialog
              open={filterDialogOpen}
              toggleDialog={this.toggleFilterDialog}
              originalData={originalWorkHoursWithMeta}
              dataTitle="Suodata kirjaukset"
              // dataTitle2="Suodata matkat"
              filterOptions={{
                // NOTE: Using lodash's get() let's you use nested attributes as a single string like so: 'work_order.date'
                // Multiple filters can be combined (OR operator) by separating the attrs with '||'
                filters,
                // defaultFilters: [
                //   {
                //     // filter: 'work_hour.status.pending',
                //     filter: {
                //       key: 'work_hour.status',
                //       value: 'pending',
                //     },
                //     // Required for generating buttons for these default filters so that users can turn them off when there's no matching data
                //     // IMPORTANT: Needs to match the filterOptions.filters object
                //     filterMeta: {
                //       title: 'Tila',
                //       key: 'work_hour.status',
                //       translate: true,
                //     },
                //   },
                //   {
                //     // IMPORTANT: filter parts constructed with user-given strings need to be wrapped in single quote
                //     // This is to prevent things from breaking when they use .
                //     // filter: `accepting_employers.${currentUser.lastName}, ${currentUser.firstName}`,
                //     filter: {
                //       key: 'accepting_employers',
                //       value: `${currentUser.lastName}, ${currentUser.firstName}`,
                //     },
                //     filterMeta: {
                //       title: 'Kirjausten hyväksyjä',
                //       key: 'accepting_employers',
                //       translate: false,
                //     },
                //   },
                // ],
                defaultFilters: [],
              }}
              ignoredFilters={[
                {
                  key: 'work_hour.status',
                  value: 'null',
                },
              ]}
              filterData={this.filterData}
              // filterData2={this.filterData2}
              translationPrefix="work_hour_filters"
              setActiveFilters={this.setActiveFilters}
              previousFilters={timelogReportFilters}
              timelogReportMode
            />
          )}

          {/* {this.renderRejectWorkHourDialog()} */}
          {/* {this.renderWorkOrderTripAcceptanceDialog()} */}
          {/* <EmployeeInfoDialog open={showEmployeeInfoDialog} employee={employeeInfoDialogData} onClose={this.closeEmployeeInfoDialog} />
          <WorkOrderInfoDialog open={showWorkOrderInfoDialog} workOrderInfo={workOrderInfoDialogData} onClose={this.closeWorkOrderInfoDialog} /> */}
        </div>
        {/* For some reason the styles are messed up with this dialog, e.g. the IconButtons on this index lose their padding when the dialog is opened unless it is inline style */}
        {/* Checkbox heights are also different compared to the billing/ReportSettingsDialog where this was copied from */}
        {/* The cause of the style issue is change in the order that the CSS classes are applied, but why? */}
        <TimelogReportSettingsDialog columns={columnVisibility} toggleColumnVisibility={this.toggleColumnVisibility} open={settingsDialogOpen} handleClose={this.handleSettingsDialogClose} />
      </div>
    );
  }
}

export default WorkHourReport;

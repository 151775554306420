/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Icon } from '@rmwc/icon';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
} from '@rmwc/dialog';
import MUIDataTable from 'mui-datatables';
import { cloneDeep, isEqual } from 'lodash';
import moment, { shortDateRange } from '../utils/moment';
import truncate from '../utils/truncate';
import { buttonColors } from '../calendar/EmployeeCalendar';
// import { relativeTimeRounding } from 'moment';
// import EmployerTimelogViewDialog from './EmployerTimelogViewDialog';
// import EmployeeInfoDialog from './EmployeeInfoDialog';
import './EmployerAbsenceTable.css';
import AbsenceEntryDialog from '../absences/absence-entry-dialog';
import AbsenceEntry from '../models/AbsenceEntry';
import AbsenceType from '../models/AbsenceType';
import { getAllowanceLabel } from '../utils';

@inject('timelogStore', 'uiStore', 't')
@observer
class EmployerAbsenceTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rejectingStart: false,
      rejectingEnd: false,
      commentStart: '',
      commentEnd: '',
      sendingSMSStart: false,
      sendingSMSEnd: false,
      sorting: { column: 'name', dir: 1 },
      currentlyChecked: [],
      currentTimelogTrip: {},
      rowsSelected: [],
      columns: [],
      employerTimelogViewDialogOpen: false,
      employerUpdateDialogOpen: false,
      editingAbsenceEntry: {},
      highlightAbsenceEntryId: null,
      firstRender: true,

      showRejectWorkHourDialog: false,
      rejectingWorkHour: null,
      rejectingWorkOrderId: null,
      rejectingSendSMS: false,
      rejectingEmployerCommentSingle: '',

      showEmployeeInfoDialog: false,

      absenceEntryDialogOpen: false,
      currentAbsenceEntry: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  // componentDidMount() {
  //   const { workHours } = this.props;

  //   const columnDefinitions = this.constructColumns(workHours);
  //   const tableOptions = this.constructTableOptions(workHours, columnDefinitions);

  //   this.setState({
  //     columns: columnDefinitions,
  //     tableOptions: tableOptions,
  //     columnVisibility: columnDefinitions.map(() => true),
  //   }, () => {
  //     this.hideEmptyColumns(workHours);
  //     return true;
  //   });
  // }

  componentDidMount() {
    // This is a hack to dynamically create CSS classes to get the left border color
    // Reason behind this is that we can't add <td> border as an inline style and the color comes from DB
    // Adding a border to <tr> will mess up the table, adding a box-shadow inset doesn't work on Safari
    const { salaryPeriods } = this.props;
    const style = document.createElement('style');
    let salaryPeriodClasses = '';
    salaryPeriods.forEach((salaryPeriod) => {
      salaryPeriodClasses += `.table-row-${salaryPeriod.nameShort} > td:first-of-type {
        box-shadow: inset 5px 0 0 0 ${salaryPeriod.color};
        margin-left: -5px;
      }`;
    });
    style.innerHTML = salaryPeriodClasses;
    document.head.appendChild(style);
  }

  componentDidUpdate(prevProps) {
    const { currentFilters } = this.props;
    // We want to reset the checkboxes if filters change

    if (currentFilters !== prevProps.currentFilters) {
      this.setState({
        currentlyChecked: [],
        rowsSelected: [],
        // On filter change, we want to completely reset the table rendering so that hideEmptyColumns is called on the new values
        firstRender: true,
      });
    }
  }

  constructColumns(data) {
    const { t, uiStore: { currentUser, currentUser: { accountInfo: { workOrderTripsEnabled } } } } = this.props;
    const { columnVisibility } = this.props;

    const staticColumns = [
      // {
      //   name: 'work_hour.status',
      //   label: t('timelog.attributes.status'),
      //   options: {
      //     // display: employerWorkOrderStore.billingDisplayColumns.timeStatus === undefined ? true : employerWorkOrderStore.billingDisplayColumns.timeStatus,
      //     customBodyRender: (value) => {
      //       console.log('VALUE: ', value);

      //       let colorClass;
      //       if (value === 'pending') {
      //         colorClass = 'invitations-warning';
      //       } else if (value === 'accepted') {
      //         colorClass = 'invitations-ok';
      //       }

      //       return (
      //         <div className={colorClass} style={{ width: 'fit-content' }}>
      //           {t(`timelog.attributes.statuses.${value}`)}
      //         </div>
      //       );
      //     },
      //     sort: false,
      //     filterType: 'checkbox',
      //   },
      // },
      {
        name: 'user.full_name',
        label: 'Työntekijä',
        options: {
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const user = data[dataIndex].user;

            return (
              <span
                role="button"
                onClick={(e) => {
                  e.stopPropagation();
                  this.openEmployeeInfoDialog(user)
                }}
                style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
              >
                {user.full_name}
              </span>
            );
          }
        },
      },
      {
        name: 'calendar_entry.from',
        label: 'Pvm',
        options: {
          filter: false,
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const absenceEntry = data[dataIndex];
            const rowIdentifier = `${absenceEntry?.id || `${absenceEntry?.user.id}-${absenceEntry?.calendar_entry.from}-${absenceEntry?.absence_type.id}`}-statusHover`;
            const result = this.renderCustomTooltip(
              rowIdentifier,
              this.renderDateWithStatus(absenceEntry?.calendar_entry.from, absenceEntry?.status),
              this.renderStatusTextWithComment(absenceEntry?.status, null), // workHour?.work_hour.employer_comment
              null,
              '65px',
            );

            return (
              result
            );
          },
          setCellProps: (value) => {
            return {
              style: {
                paddingLeft: '5px',
                paddingRight: '5px',
              },
            };
          },
        },
      },
      {
        name: 'time',
        label: 'Kesto',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const result = this.renderDuration(data[dataIndex]);
            return (
              result
            );
          },
          setCellProps: () => {
            return { style: { minWidth: '120px' } };
          },
        },
      },
      {
        name: 'absence_type.official_name',
        label: 'Poissaolo',
        options: {
          filter: false,
          sort: false,
          // customBodyRenderLite: (dataIndex) => {
          //   const result = this.renderDuration(data[dataIndex]);
          //   return (
          //     result
          //   );
          // },
          setCellProps: () => {
            return { style: { minWidth: '120px' } };
          },
        },
      },
      {
        name: 'description',
        label: 'Lisätietoa',
        options: {
          filter: false,
          sort: false,
          // customBodyRenderLite: (dataIndex) => {
          //   const result = this.renderDuration(data[dataIndex]);
          //   return (
          //     result
          //   );
          // },
          setCellProps: () => {
            return { style: { minWidth: '120px' } };
          },
        },
      },
      // {
      //   name: 'work_hour.travel_time',
      //   label: 'Matka h työajan ulkop.',
      //   options: {
      //     filter: false,
      //     search: false,
      //     sort: false,
      //     customBodyRenderLite: (dataIndex) => {
      //       const result = this.renderValues(data[dataIndex], 'travel_time');
      //       return (
      //         result
      //       );
      //     },
      //   },
      // },
      // {
      //   name: 'work_hour.drive_time',
      //   label: 'Matka h työajalla',
      //   options: {
      //     filter: false,
      //     search: false,
      //     sort: false,
      //     customBodyRenderLite: (dataIndex) => {
      //       const result = this.renderValues(data[dataIndex], 'drive_time');
      //       return (
      //         result
      //       );
      //     },
      //   },
      // },
    ];

    // combines the static columns that everyone has with the optional (or "dynamic") columns into one array
    // let finalColumns = staticColumns.concat(optionalColumns);

    // Retain the column visibility setting even when the columns are reconstructed with new data
    // E.g. user has hid columns manually and we don't want to reveal them

    // columnVisibility isn't set yet on the first column construction (componentDidMount)
    // if (columnVisibility) {
    //   // Force the previous visibility settings on the reconstructed columns
    //   finalColumns = finalColumns.map((finalCol, index) => ({ ...finalCol, options: { ...finalCol.options, display: columnVisibility[finalCol.name] } }));
    // }

    return staticColumns;
  }

  constructTableOptions(absenceEntries, columns) {
    const {
      t,
      setAbsenceEntriesSelected,
      rowsSelected,
      uiStore: { currentUser: { accountInfo: { salaryPeriodCategoriesEnabled } } },
      daysTillDeadlines,
      disableActions,
    } = this.props;

    const options = {
      filter: false,
      filterType: 'dropdown',
      search: true,
      viewColumns: true,
      selectableRows: !disableActions && 'multiple',
      fixedHeader: true,
      fixedSelectColumn: false,
      // sort: false,
      download: false,
      print: false,
      responsive: 'standard',
      enableNestedDataAccess: ".",
      tableBodyMaxHeight: 'calc(100vh - 360px)',
      rowsPerPage: 500,
      rowsPerPageOptions: [50, 100, 500, 1000],
      rowsSelected: rowsSelected,
      onRowSelectionChange: (_rowsSelectedData, allRows, rowsSelected) => {
        // const selectedWorkhourIds = allRows.map((row) => workHours[row.dataIndex].work_hour.id);
        const selectedAbsenceEntries = allRows.map((row) => {
          const absenceEntry = absenceEntries[row.dataIndex];
          return {
            id: absenceEntry.id,
            absence_period_id: absenceEntry.absence_period.id,
            user_id: absenceEntry.user.id,
            date: absenceEntry.calendar_entry.from,
          };
        });
        if (setAbsenceEntriesSelected) {
          setAbsenceEntriesSelected(selectedAbsenceEntries, rowsSelected);
        }
      },
      selectToolbarPlacement: 'none',
      setTableProps: () => {
        return {
          size: 'small',
        }
      },
      textLabels: {
        body: {
          noMatch: t(`employer_table.body.noMatch`),
        },
        toolbar: {
          search: t(`employer_table.toolbar.search`),
          downloadCsv: t(`employer_table.toolbar.downloadCsv`),
          print: t(`employer_table.toolbar.print`),
          viewColumns: t(`employer_table.toolbar.viewColumns`),
          filterTable: t(`employer_table.toolbar.filterTable`),
        },
        pagination: {
          next: t(`employer_table.pagination.next`),
          previous: t(`employer_table.pagination.previous`),
          rowsPerPage: t(`employer_table.pagination.rowsPerPage`),
          displayRows: t(`employer_table.pagination.displayRows`),
        },
        filter: {
          all: t(`employer_table.filter.all`),
          title: t(`employer_table.filter.title`),
          reset: t(`employer_table.filter.reset`),
        },
        viewColumns: {
          title: t(`employer_table.viewColumns.title`),
          titleAria: t(`employer_table.viewColumns.titleAria`),
        },
        selectedRows: {
          text: t(`employer_table.selectedRows.text`),
          delete: t(`employer_table.selectedRows.delete`),
          deleteAria: t(`employer_table.selectedRows.deleteAria`),
        },
      },
      onCellClick: (_colData, cellMeta, _colIndex, _rowIndex, _dataIndex) => {
        // We exclude the kms field because it already has a click event
        if (!disableActions) {
          this.setState({
            employerUpdateDialogOpen: true,
            editingAbsenceEntry: absenceEntries[cellMeta.dataIndex],
          });
        }
      },
      onViewColumnsChange: this.handleViewColumnsChange,
      setRowProps: (_row, dataIndex) => {
        if (salaryPeriodCategoriesEnabled) {
          return { className: `table-row-${absenceEntries[dataIndex].salary_period.nameShort}`}
        }
        return null;
      },
      // draggableColumns: {
      //   enabled: true,
      //   transitionTime: 300,
      // },
    };

    return options;
  }

  hideEmptyColumns(originalColumns, workHours) {
    // const { mode, setIndexState, indexState } = this.props;
    // const { columns } = this.state; // mainColumns
    const { columnVisibility, setColumnVisibility } = this.props;

    const columns = [...originalColumns];

    // The normal, 50 and 100 hours need to be here as default columns at the moment because combining work task entries' hours messes with this method
    const columnsWithValues = ['user.full_name', 'work_order_info.name', 'work_hour.date', 'hours', 'work_hour.time_normal', 'work_hour.time_50', 'work_hour.time_100'];

    workHours.forEach((workHourObj) => {
      // Filter mainColumns with columnsWithValues to prevent looping over keys that we know are needed
      const filteredColumns = columns.filter((col) => !columnsWithValues.includes(col.name));

      filteredColumns.forEach((col) => {
        const key = col.name.split('.').pop();
        let currentValue = null;
        let oldValue = null;

        if (key === 'allowance') {
          currentValue = workHourObj.work_hour.tax_exempt_trip_expenses?.find((expense) => expense.name === 'allowance')?.value;
          oldValue = workHourObj.old_work_hour.tax_exempt_trip_expenses?.find((expense) => expense.name === 'allowance')?.value;
        } else if (key === 'work_order_trip') {
          currentValue = workHourObj[key];
        } else {
          currentValue = workHourObj.work_hour[key];
          // A hack to hide the column even if old value is a string "false", e.g. in emergency_work, which isn't rendered when the current value is falsy
          oldValue = workHourObj.old_work_hour[key] === 'false' ? null : workHourObj.old_work_hour[key];
        }

        if ((currentValue || oldValue) && !columnsWithValues.includes(col.name)) {
          columnsWithValues.push(col.name);
        }
      });
    });

    const hiddenEmptyColumns = columns.map((col) => ({ ...col, options: { ...col.options, display: columnsWithValues.includes(col.name) } }));

    if (!columnVisibility) {
      const newColumnVisibility = {};

      hiddenEmptyColumns.forEach((col) => {
        newColumnVisibility[col.name] = col.options.display;
      });

      setColumnVisibility(newColumnVisibility);
    }

    return hiddenEmptyColumns;
  }

  employerAccept(workOrderId, workHourId) {
    const { timelogStore, uiStore } = this.props;
    const user = uiStore.currentUser;
    timelogStore.employerAccept(user, workOrderId, workHourId);
  }

  cancelReject() {
    // Maybe reset the input field here or something
    this.setState({ rejecting: false });
  }

  handleChange(event) {
    this.setState({ employerComment: event.target.value });
  }

  handleViewColumnsChange = (changedColumn, action) => {
    const { columnVisibility, setColumnVisibility } = this.props;

    // When the column visibility is changed, we want to mirror it in a state variable
    // The state variable is used to maintain old visibility settings when the columns are re-constructed on filter change
    if ((action === 'remove' || action === 'add') && changedColumn) {
      const newColumnVisibility = { ...columnVisibility, [changedColumn]: !columnVisibility[changedColumn] };
      setColumnVisibility(newColumnVisibility);
    }
  }

  renderAllowances(workHour) {
    const value = workHour?.work_hour.tax_exempt_trip_expenses?.find((expense) => expense.name === 'allowance')?.value;
    const oldValue = workHour?.old_work_hour.tax_exempt_trip_expenses?.find((expense) => expense.name === 'allowance')?.value;

    return (
      <div>
        {value && <div>{getAllowanceLabel(value, true)}</div>}
        {oldValue && !isEqual(value, oldValue) && <div className="employer-acceptance-table-old-value" style={{ position: 'relative' }}>{getAllowanceLabel(oldValue, true)}</div>}
      </div>
    );
  }

  renderStatusTextWithComment(status, employerComment) {
    const { t } = this.props;
    return (
      <div>
        <div>{t(`timelog.attributes.statuses.${status}`)}</div>
        {status === 'rejected' && (
          <>
            <br />
            <div className="tooltip-employer-comment">
              "
              {employerComment}
              "
            </div>
          </>
        )}
      </div>
    );
  }

  renderCustomTooltip(visibilityIdentifier, tooltippedNode, tooltipContent, customBottom = null, customWidth = null) {
    return (
      <div
        className="custom-tooltip-wrapper"
        key={`tooltip-${visibilityIdentifier}`}
        style={{ width: customWidth ? customWidth : null, minWidth: customWidth ? customWidth : null }}
      >
        <div
          className={
            this.state[visibilityIdentifier] ? 'custom-tooltip custom-tooltip-open' : 'custom-tooltip'
          }
          style={{
            bottom: customBottom,
          }}
        >
          {tooltipContent}
        </div>

        <div
          onMouseEnter={() => this.setState({ [visibilityIdentifier]: true })}
          onMouseLeave={() => this.setState({ [visibilityIdentifier]: false })}
          // className="custom-tooltip-underline"
        >
          {/* {moment(workHour.work_hour.date).format('DD.MM.')} */}
          {tooltippedNode}
        </div>
      </div>
    );
  }

  renderKms(workHour) {
    const result = workHour?.work_hour.kms;
    const oldResult = workHour?.old_work_hour.kms;

    return (
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (workHour?.work_hour.trip_routes && workHour?.work_hour.trip_routes.length > 0) {
            // this.showTripDialog(workHour.work_hour.trip_routes, workHour.old_work_hour_trip_routes, workHour.work_hour.date, workHour.user_name);
            this.setState({
              tripRouteDialogOpen: true,
              currentTimelogTrip: {
                tripRoutes: workHour?.work_hour.trip_routes,
                oldTripRoutes: workHour?.old_work_hour.trip_routes,
                date: workHour?.work_hour.date,
                user: workHour?.user,
              },
            });
          }
        }}
      >
        <div style={{ textDecorationLine: 'underline', textDecorationStyle: 'dotted' }}>{result}</div>
        {oldResult && !isEqual(result, oldResult) ? (
          <div className="employer-acceptance-table-old-value">{oldResult}</div>
        ) : (
          null
        )}
      </div>
    );
  }

  renderWorkHourTasks(workHour, time) {
    let result = 0;
    let oldResult = 0;
    const workTasks = workHour.work_hour.work_task_entries;
    const oldWorkTasks = workHour.old_work_hour.work_task_entries;

    for (let i = 0; i < workTasks.length; i++) {
      result += Number(workTasks[i][time]);
    }
    if (result === 0) {
      result = '';
    }

    if (oldWorkTasks && oldWorkTasks.length > 0) {
      for (let i = 0; i < oldWorkTasks.length; i++) {
        oldResult += Number(oldWorkTasks[i][time]);
      }
    }

    if (oldResult === result || oldResult === 0) {
      oldResult = '';
    }

    return (
      <div
        style={{ cursor: 'pointer' }}
      >
        <div style={{ textDecorationLine: 'underline', textDecorationStyle: 'dotted' }}>{result}</div>
        <div className="employer-acceptance-table-old-value">{oldResult}</div>
      </div>
    );
  }

  renderValues(workHour, type) {
    const result = workHour?.work_hour[type];
    const oldResult = workHour?.old_work_hour[type];

    return (
      <div>
        <div>{result}</div>
        {oldResult && !isEqual(result, oldResult) && (
          <div className="employer-acceptance-table-old-value">{oldResult}</div>
        )}
      </div>
    );
  }

  renderDuration(absenceEntry) {
    const timeFrom = absenceEntry?.calendar_entry.from ? moment(absenceEntry.calendar_entry.from).format('HH:mm') : '?';
    const timeTo = absenceEntry?.calendar_entry.from ? moment(absenceEntry.calendar_entry.to).format('HH:mm') : '?';
    // const oldTimeFrom = workHour?.old_work_hour && workHour.old_work_hour.from ? moment(workHour.old_work_hour.from).format('HH:mm') : '?';
    // const oldTimeTo = workHour?.old_work_hour && workHour.old_work_hour.to ? moment(workHour.old_work_hour.to).format('HH:mm') : '?';
    let result;
    const durationValue = absenceEntry?.values.find((val) => val.name === 'duration');
    if (durationValue) {
      result = `${timeFrom}-${timeTo} (${durationValue.value} ${durationValue.unit})`;
    } else if (timeFrom !== '00:00' || timeTo !== '23:59') {
      result = `${timeFrom}-${timeTo}`;
    }
    // let oldResult = '';
    // // checks if old work hour exists
    // if (workHour?.work_hour.status === 'pending' && ((workHour?.old_work_hour.from && !isEqual(workHour?.work_hour.from, workHour?.old_work_hour.from)) || (workHour?.old_work_hour.to && !isEqual(workHour?.old_work_hour.to, workHour?.work_hour.to)))) {
    //   oldResult = `${oldTimeFrom} -  ${oldTimeTo}`;
    // }

    return (
      <>
        <div style={{ whiteSpace: 'nowrap' }}>{result}</div>
        {/* <div className="employer-acceptance-table-old-value">{oldResult}</div> */}
      </>
    );
  }

  renderDateWithStatus(date, status) {
    return (
      <div style={{ position: 'relative' }}>
        <span
          style={{ textTransform: 'capitalize' }}
          className={`work-hour-status-${status}`}
        >
          {moment(date).format('dd DD.MM.')}
        </span>

        <div style={{ position: 'absolute', top: '-10px', right: '-12px' }}>
          {status === 'pending' && (
            <Icon icon="done" />
          )}
          {status === 'accepted' && (
            <Icon icon="done_all" />
          )}
          {status === 'rejected' && (
            <Icon icon="info_outlined" />
          )}
        </div>
      </div>
    );
  }

  renderRouteLocations(routeLocations, oldRouteLocations) {
    const routeLocationWrappers = [];
    routeLocations.sort((a, b) => (a.location_order.valueOf() - b.location_order.valueOf()));
    oldRouteLocations?.sort((a, b) => (a.location_order.valueOf() - b.location_order.valueOf()));

    const currentRouteLocationElements = [];
    routeLocations?.forEach((routeLocation, index) => {
      currentRouteLocationElements.push(
        <div key={routeLocation.id} style={{ paddingTop: index === 0 ? '0' : '10px' }}>
          <div>{routeLocation.street}</div>
          <div>{routeLocation.zip_code}</div>
          <div>{routeLocation.city}</div>
        </div>,
      );
    });

    routeLocationWrappers.push(
      <div key="current_wrapper">
        {currentRouteLocationElements}
      </div>,
    );

    // If route has changed, print the old route too
    if (!isEqual(routeLocations, oldRouteLocations)) {
      const oldRouteLocationElements = [];
      oldRouteLocations?.forEach((routeLocation, index) => {
        oldRouteLocationElements.push(
          <div key={routeLocation.id + '_old_location'} style={{ paddingTop: index === 0 ? '0' : '10px', position: 'relative' }} className="employer-acceptance-table-old-value">
            <div>{routeLocation.street}</div>
            <div>{routeLocation.zip_code}</div>
            <div>{routeLocation.city}</div>
          </div>,
        );
      });

      routeLocationWrappers.push(
        <div key="old_wrapper">
          {oldRouteLocationElements}
        </div>,
      );
    }

    return routeLocationWrappers;
  }

  renderTripRoutes(tripRoutes, oldTripRoutes, key) {
    const tripRouteElements = [];
    tripRoutes.forEach((tripRoute, index) => {
      if (tripRoute.kms_type === key) {
        tripRouteElements.push(
          <div key={tripRoute.id + 'trip_route'} style={{ paddingLeft: '10px' }}>
            <div>
              Reitti
              &nbsp;
              {index + 1}
              :
            </div>
            <div style={{ paddingLeft: '20px', paddingTop: '5px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Selite:</span>
                <span style={{ textAlign: 'end', hyphens: 'auto', marginBottom: '5px' }}>{tripRoute.description}</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Etäisyys (km):</span>
                <span>{tripRoute.kms}</span>
              </div>
              {/* <div>Selite: {tripRoute.description}</div>
              <div>Etäisyys (km): {tripRoute.kms}</div> */}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0 10px 25px' }}>
              {this.renderRouteLocations(tripRoute.route_locations, oldTripRoutes?.[index]?.route_locations)}
            </div>
          </div>,
        );
      }
    });
    return tripRouteElements;
  }

  renderTable() {
    const { uiStore: { currentUser }, absenceEntries, t } = this.props;
    const { firstRender } = this.state;

    let columns = null;

    const ogColumns = this.constructColumns(absenceEntries);
    // A stupid hack to do "first render" stuff here because MUIDataTable doesn't work correctly (e.g. updates wrong rows) if the columns aren't re-constructed with each render
    if (firstRender) {
      this.setState({
        // columnVisibility: ogColumns.map(() => true),
        firstRender: false,
      });
      // columns = this.hideEmptyColumns(ogColumns, workHours);
      columns = ogColumns;
    } else {
      columns = ogColumns;
    }

    const tableOptions = this.constructTableOptions(absenceEntries, columns);

    return (
      <MUIDataTable
        data={absenceEntries}
        columns={columns}
        options={tableOptions}
      />
    );
  }

  closeEmployerTimelogViewDialog = () => {
    this.setState({
      employerTimelogViewDialogOpen: false,
    });
  }

  closeEmployeeInfoDialog = () => {
    this.setState({
      showEmployeeInfoDialog: false,
      employeeInfoDialogData: null,
    });
  }

  openEmployeeInfoDialog(employeeInfo) {
    this.setState({
      showEmployeeInfoDialog: true,
      employeeInfoDialogData: employeeInfo,
    });
  }

  // employerRejectSingle(workOrderId, workHour, sendSMS) {
  //   const { timelogStore, uiStore, updateWorkHour } = this.props;
  //   const { rejectingEmployerCommentSingle } = this.state;
  //   const user = uiStore.currentUser;

  //   this.setState({
  //     // loading: true,
  //   }, () => {
  //     new Promise((resolve, reject) => timelogStore.employerReject(user, workOrderId, workHour, rejectingEmployerCommentSingle, sendSMS, resolve, reject))
  //       .then((response) => {
  //         // Success
  //         this.setState({
  //           // loading: false,
  //           showRejectWorkHourDialog: false,
  //           rejectingWorkHour: null,
  //           rejectingWorkOrderId: null,
  //           rejectingSendSMS: false,
  //           rejectingEmployerCommentSingle: '',
  //         });
  //         updateWorkHour(response.body.id, response.body.status, response.body.employer_comment)
  //       })
  //       .catch((err) => {
  //         // Failure
  //         this.setState({
  //           // loading: false,
  //         }, console.log('ERROR: ', err));
  //       });
  //   });
  // }

  // This was copied and modified from EmployerTimelogCard.renderTrip()
  renderTrip(workOrderTrip) {
    const { openWorkOrderTripAcceptanceDialog } = this.props;
    const tripDateRange = shortDateRange(workOrderTrip.start_trip.date, workOrderTrip.end_trip.to_date);

    return (
      <div
        key={workOrderTrip.id}
        onClick={(e) => {
          e.stopPropagation();
          openWorkOrderTripAcceptanceDialog(workOrderTrip.id);
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
        }}
        role="button"
      >
        {tripDateRange}
      </div>
    );
  }

  openEditAbsenceEntryDialog(absenceEntry) {
    this.setState({
      currentAbsenceEntry: absenceEntry,
      absenceEntryDialogOpen: true,
    });
  }

  closeEditAbsenceEntryDialog = () => {
    this.setState({
      currentAbsenceEntry: null,
      absenceEntryDialogOpen: false,
    });
  }

  handleDeleteAbsenceEntry = (absenceEntry) => {
    const { deleteAbsenceEntry } = this.props;
    deleteAbsenceEntry(absenceEntry, this.closeEditAbsenceEntryDialog);
  }

  render() {
    const {
      sorting,
      currentlyChecked,
      rejecting,
      sendSMS,
      tripRouteDialogOpen,
      currentTimelogTrip,
      currentUserId,
      // currentWorkOrderId,
      employerTimelogViewDialogOpen,
      employerUpdateDialogOpen,
      editingAbsenceEntry,
      highlightAbsenceEntryId,
      showEmployeeInfoDialog,
      employeeInfoDialogData,
      absenceEntryDialogOpen,
      currentAbsenceEntry,
    } = this.state;
    const { updateAbsenceEntry } = this.props;

    return (
      <>
        <Dialog
          className="employer-delete-modal work-order-trip-card-dialog"
          open={employerUpdateDialogOpen}
          onClose={() => {
            this.setState({
              employerUpdateDialogOpen: false,
              editingAbsenceEntry: {}
            });
          }}
          style={{ zIndex: 111 }}
        >
          <DialogTitle
            style={{
              // color: 'var(--mdc-theme-primary)',
              padding: '5px 10px',
            }}
          >
            {editingAbsenceEntry && `${editingAbsenceEntry.user?.full_name} - ${editingAbsenceEntry.absence_type?.official_name}, ${editingAbsenceEntry.calendar_entry ? moment(editingAbsenceEntry.calendar_entry.from).format('dd DD.MM.YY') : ''}`}
          </DialogTitle>

          {/* {editingAbsenceEntry.work_hour?.work_task_entries && editingAbsenceEntry.work_hour?.work_task_entries.length > 0 ? (
            <DialogContent style={{ padding: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {editingAbsenceEntry.work_hour.work_task_entries.map((worktask) => {
                      return (
                        <div
                          key={worktask.id + 'work_task'}
                          style={{ marginBottom: '15px', fontSize: '16px', marginLeft: '10px' }}
                        >
                          <div>
                            {worktask.task_identifier_one}
                            {' - '}
                            {worktask.task_identifier_two}
                          </div>
                          <div style={{ marginLeft: '10px', fontWeight: '600' }}>
                            {worktask.from ? moment(worktask.from).format('HH:mm') : '?'}
                            -
                            {worktask.to ? moment(worktask.to).format('HH:mm') : '?'}
                          </div>
                          <div style={{ marginLeft: '10px' }}>
                            {worktask.time_normal && (
                              <span>
                                {'Normaali: '}
                                {worktask.time_normal}
                              </span>
                            )}
                            {worktask.time_50 && (
                              <span style={{ marginLeft: worktask.time_normal ? '20px' : '0' }}>
                                {'50%: '}
                                {worktask.time_50}
                              </span>
                            )}
                            {worktask.time_100 && (
                              <span style={{ marginLeft: worktask.time_normal || worktask.time_50 ? '20px' : '0' }}>
                                {'100%: '}
                                {worktask.time_100}
                              </span>
                            )}
                          </div>
                        </div>);
                    })}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {editingAbsenceEntry.old_work_hour.work_task_entries && !isEqual(editingAbsenceEntry.work_hour.work_task_entries, editingAbsenceEntry.old_work_hour.work_task_entries) && (
                    editingAbsenceEntry.old_work_hour.work_task_entries.map((worktask) => {
                      return (
                        <div
                          key={worktask.id + 'old_work_task'}
                          style={{
                            marginBottom: '15px',
                            fontSize: '14px',
                            marginLeft: '15px',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div className="employer-acceptance-table-old-value" style={{ position: 'relative' }}>
                            {worktask.task_identifier_one}
                            -
                            {worktask.task_identifier_two}
                          </div>
                          <div className="employer-acceptance-table-old-value" style={{ marginLeft: '10px', fontWeight: '600', position: 'relative' }}>
                            {worktask.from ? moment(worktask.from).format('HH:mm') : '?'}
                            -
                            {worktask.to ? moment(worktask.to).format('HH:mm') : '?'}
                          </div>
                          <div className="employer-acceptance-table-old-valu" style={{ marginLeft: '10px', position: 'relative' }}>
                            {worktask.time_normal && (
                              <span>
                                Normaali:
                                {worktask.time_normal}
                              </span>
                            )}
                            {worktask.time_50 && (
                              <span style={{ marginLeft: worktask.time_normal ? '20px' : '0' }}>
                                50%:
                                {worktask.time_50}
                              </span>
                            )}
                            {worktask.time_100 && (
                              <span style={{ marginLeft: worktask.time_normal || worktask.time_50 ? '20px' : '0' }}>
                                100%:
                                {worktask.time_100}
                              </span>
                            )}
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </DialogContent>
          ) : (
            null
          )} */}

          <DialogActions
            style={{
              justifyContent: 'space-between',
              padding: '15px',
            }}
          >
            <DialogButton
              className="employer-accept-button"
              // style={{ width: '87px' }}
              style={{ width: '100px' }}
              onClick={() => {
                this.setState((prevState) => ({
                  employerUpdateDialogOpen: false,
                  editingAbsenceEntry: {},
                  highlightAbsenceEntryId: prevState.editingAbsenceEntry.id,
                  // currentWorkOrderId: prevState.editingAbsenceEntry.work_hour.work_order_id,
                  currentUserId: prevState.editingAbsenceEntry.user.id,
                }), () => {
                  // We have to do a manual AbsenceEntry conversion here because the editingAbsenceEntry doesn't match AbsenceEntry directy
                  // E.g. editingAbsenceEntry has salary_period key and an already converted CalendarEntry
                  const editingAbsenceEntryObj = new AbsenceEntry({
                    user: editingAbsenceEntry.user,
                    id: editingAbsenceEntry.id,
                    calendarEntry: editingAbsenceEntry.calendar_entry,
                    status: editingAbsenceEntry.status,
                    values: editingAbsenceEntry.values,
                    description: editingAbsenceEntry.description,
                    absenceType: AbsenceType.fromJsonProperties(editingAbsenceEntry.absence_type),
                  });
                  this.openEditAbsenceEntryDialog(editingAbsenceEntryObj);
                });
              }}
            >
              Korjaa itse
            </DialogButton>

            {/* <DialogButton
              className="employer-accept-button"
              // style={{ width: '140px' }}
              style={{ width: '100px' }}
              onClick={() => {
                const { editingAbsenceEntry } = this.state;

                const rejectingWorkHour = editingAbsenceEntry.work_hour;
                const editingAbsenceEntryWorkOrderId = editingAbsenceEntry.work_hour.work_order_id;
                const rejectDialogTitle = `${editingAbsenceEntry.user.full_name} - ${editingAbsenceEntry.work_order_info.name}, ${moment(editingAbsenceEntry.work_hour.date).format('dd DD.MM.YY')}`;

                this.setState(({
                  employerUpdateDialogOpen: false,
                  editingAbsenceEntry: {},
                }), () => {
                  openRejectWorkHourDialog(rejectingWorkHour, editingAbsenceEntryWorkOrderId, rejectDialogTitle, 'single');
                });
                // this.setState({
                //   showRejectWorkHourDialog: true,
                //   rejectingWorkOrderId: workOrderId,
                //   rejectingWorkHourId: workHour.id,
                // });
              }}
            >
              Pyydä korjaus
            </DialogButton> */}

            <DialogButton
              className="employer-reject-button"
              style={{
                borderRadius: '0px',
                margin: 0,
                width: '100px',
              }}
              action="close"
            >
              Sulje
            </DialogButton>
          </DialogActions>
        </Dialog>

        {this.renderTable()}

        <AbsenceEntryDialog
          open={absenceEntryDialogOpen}
          absenceEntry={currentAbsenceEntry}
          onClose={this.closeEditAbsenceEntryDialog}
          employerMode
          employerUpdateCallback={updateAbsenceEntry}
          handleDeleteAbsenceEntry={this.handleDeleteAbsenceEntry}
        />

        {/* <EmployerTimelogViewDialog workOrderIds={[currentWorkOrderId]} employeeId={currentUserId} open={employerTimelogViewDialogOpen} onClose={this.closeEmployerTimelogViewDialog} highlightAbsenceEntryId={highlightAbsenceEntryId} afterUpdate={afterUpdate} /> */}
        {/* <EmployeeInfoDialog open={showEmployeeInfoDialog} employee={employeeInfoDialogData} onClose={this.closeEmployeeInfoDialog} /> */}
      </>
    );
  }
}

export default EmployerAbsenceTable;

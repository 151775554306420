/* eslint-disable */
import React, { Component } from 'react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  IconButton,
  Icon,
  Grid,
  TextField,
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
  Typography,
  Fab,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import ReactToPrint from 'react-to-print';
import moment from '../utils/moment';
import LoadingSpinner from '../shared/LoadingSpinner';
import DeepViewHeader from '../shared/DeepViewHeader';
import EmployerProductLogDialog from './EmployerProductLogDialog';
import './report.css';
import normalizeFileName from '../utils/NormalizeFileName';

@inject('uiStore', 'loginStore', 't', 'employerWorkOrderStore', 'productStore')
@observer
class EmployerProductLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportData: [],
      groupedData: [],
      columns: [],
      editDialogOpen: false,
      selectedProduct: null,
      expandedRows: new Set(),
      isLoading: true,
      showDeleteDialog: false,
      productToDelete: null,
      deleteErrorResponse: null,
    };
  }

  componentDidMount() {
    const { uiStore } = this.props;
    const workOrderId = uiStore.currentView.id;

    this.getWorkOrderReportByUser(workOrderId);
    const columns = [
      {
        name: ' ',
        label: '',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => {
            const rowIndex = tableMeta.rowIndex;
            const { groupedData } = this.state;
            const collection = groupedData[rowIndex];
            if (!collection) return null;
            return (
              <IconButton
                onClick={() => this.handleExpandRow(collection.id)}
                size="small"
              >
                <Icon>
                  {this.state.expandedRows.has(collection.id) ? 'expand_less' : 'expand_more'}
                </Icon>
              </IconButton>
            );
          },
        },
      },
      {
        name: 'date',
        label: 'Päivämäärä',
        options: {
          customBodyRender: (value, tableMeta) => {
            const { groupedData } = this.state;
            const collection = groupedData[tableMeta.rowIndex];
            return collection ? collection.date : '';
          },
        },
      },
      {
        name: 'user',
        label: 'Kirjaaja',
        options: {
          customBodyRender: (value, tableMeta) => {
            const { groupedData } = this.state;
            const collection = groupedData[tableMeta.rowIndex];
            return collection ? `${collection.user_first_name} ${collection.user_last_name}` : '';
          },
        },
      },
      {
        name: 'products',
        label: 'Tuotteet',
        options: {
          customBodyRender: (value, tableMeta) => {
            const { groupedData } = this.state;
            const collection = groupedData[tableMeta.rowIndex];
            if (!collection) return '';
            return `${collection.products.length} kirjausta`;
          },
        },
      },

    ];

    this.setState({ columns });
  }

  handleEditClick = (product) => {
    this.setState({
      editDialogOpen: true,
      selectedProduct: product,
    });
  };

  handleExpandRow = (collectionId) => {
    this.setState(prevState => {
      const expandedRows = new Set(prevState.expandedRows);
      if (expandedRows.has(collectionId)) {
        expandedRows.delete(collectionId);
      } else {
        expandedRows.add(collectionId);
      }
      return { expandedRows };
    });
  };

  handleDeleteClick = (product) => {
    this.setState({
      showDeleteDialog: true,
      productToDelete: product,
      deleteErrorResponse: null,
    });
  };

  closeDeleteDialog = () => {
    this.setState({
      showDeleteDialog: false,
      productToDelete: null,
      deleteErrorResponse: null,
    });
  };

  handleConfirmDelete = () => {
    const { productStore, uiStore } = this.props;
    const { productToDelete } = this.state;

    productStore.requests.UserProductCollections.update(
      uiStore.currentUser,
      {
        id: productToDelete.user_product_collection_id,
        userProducts: [{
          id: productToDelete.id,
          _destroy: 1
        }]
      }
    )
    .then(() => {
      const workOrderId = uiStore.currentView.id;
      this.getWorkOrderReportByUser(workOrderId);
      this.closeDeleteDialog();
    })
    .catch((error) => {
      console.error('Error deleting product:', error);
      this.setState({
        deleteErrorResponse: 'Virhe poistettaessa tuotetta. Yritä uudelleen.'
      });
    });
  };

  handleDialogClose = () => {
    this.setState({
      editDialogOpen: false,
      selectedProduct: null,
    });
  };

  handleProductUpdate = (updatedProduct) => {
    const { productStore, uiStore } = this.props;
    const updatePromise = fromPromise(
      new Promise((resolve, reject) => {
        productStore.requests.UserProductCollections.update(
          uiStore.currentUser,
          {
            id: updatedProduct.user_product_collection_id,
            userProducts: [{
              id: updatedProduct.id,
              product_id: updatedProduct.product_id,
              count: updatedProduct.count
            }]
          }
        )
        .then(resolve)
        .catch(reject);
      })
    );

    when(
      () => updatePromise.state !== 'pending',
      () => {
        updatePromise.case({
          pending: () => {},
          rejected: (e) => {
            console.error('Update failed:', e);
          },
          fulfilled: () => {
            const workOrderId = uiStore.currentView.id;
            this.getWorkOrderReportByUser(workOrderId);
            this.handleDialogClose();
          },
        });
      },
    );
  };

  getWorkOrderReportByUser = (workOrderId) => {
    const { productStore } = this.props;
    
    this.setState({ isLoading: true });
    
    const reportPromise = fromPromise(new Promise((resolve, reject) => productStore.getProductReport(workOrderId, resolve, reject)));
  
    when(
      () => reportPromise.state !== 'pending',
      () => {
        reportPromise.case({
          pending: () => {},
          rejected: (e) => {
            console.log('REJECTED: ', e);
            this.setState({ isLoading: false, groupedData: [], reportData: [] });
          },
          fulfilled: (data) => {
            try {
              const reportData = data.userProducts.map((userProduct) => ({
                ...userProduct,
                // Keep the full files array instead of just the first thumbnail
                files: userProduct.files || [],
              }));

              // Group data by user_product_collection_id
              const groupedByCollection = {};
              reportData.forEach(product => {
                if (!groupedByCollection[product.user_product_collection_id]) {
                  groupedByCollection[product.user_product_collection_id] = {
                    id: product.user_product_collection_id,
                    date: product.date,
                    user_first_name: product.user_first_name,
                    user_last_name: product.user_last_name,
                    products: [],
                  };
                }
                groupedByCollection[product.user_product_collection_id].products.push(product);
              });

              const groupedData = Object.values(groupedByCollection);
              console.log('groupedData: ', groupedData);

              this.setState({
                reportData,
                groupedData,
                reportWorkOrder: data.workOrder,
                isLoading: false,
              });
            } catch (error) {
              console.error('Error processing data:', error);
              this.setState({ isLoading: false, groupedData: [], reportData: [] });
            }
          },
        });
      },
    );
  }

  renderDeleteDialog() {
    const { showDeleteDialog, deleteErrorResponse } = this.state;

    return (
      <Dialog
        className="employer-delete-modal"
        open={showDeleteDialog}
        onClose={this.closeDeleteDialog}
      >
        <DialogContent style={{ fontSize: '16px' }}>
          <p>Haluatko varmasti poistaa tämän kirjauksen?</p>
          {deleteErrorResponse && (
            <p style={{ color: 'red' }}>
              {deleteErrorResponse}
            </p>
          )}
        </DialogContent>

        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <Button
            type="button"
            className="employer-accept-button"
            onClick={this.handleConfirmDelete}
          >
            Kyllä
          </Button>

          <Button
            type="button"
            className="employer-reject-button"
            onClick={this.closeDeleteDialog}
          >
            Peruuta
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const {
      reportData,
      reportWorkOrder,
      columns,
    } = this.state;
    const { t, uiStore, uiStore: { currentUser }, mode } = this.props;

    const filename = `Tuoteraportti_${reportWorkOrder?.name}_${moment().format('DD-MM-YYYY')}`;
    const normalizedFileName = normalizeFileName(filename);

    const options = {
      filter: false,
      responsive: 'standard',
      search: false,
      viewColumns: false,
      selectableRows: 'none',
      fixedHeader: false,
      sort: true,
      download: true,
      print: false,
      downloadOptions: {
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
        filename: `${normalizedFileName}.csv`,
      },
      textLabels: {
        body: {
          noMatch: 'Kirjattuja tuotteita ei löytynyt',
        },
      },
    };

    return (
      <div className="action-buttons" style={{ height: '100%', width: '100%' }}>
        {mode !== 'tabReport' && (
          <DeepViewHeader
            content={reportWorkOrder?.name}
            showPreviousView={uiStore.showEmployerBilling}
          />
        )}

        {reportWorkOrder ? (
          <div className="printable" id="report" ref={(el) => (this.componentRef = el)}>
            <Grid
              container
              spacing={2}
              className="billing-report-header pdf-header"
              justifyContent="space-between"
            >
              <Grid item xs={4}>
                <span>{moment().format('DD/MM/YYYY')}</span>
              </Grid>
              <Grid item xs={4}>
                <span>{`Tuntiraportit - ${currentUser?.accountInfo?.officialName}`}</span>
              </Grid>
              <Grid item xs={4}>
                <img style={{ maxWidth: '100%' }} src={currentUser?.accountInfo?.accountPicture} alt="company logo" />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ width: '100%', height: 'fit-content', padding: '16px 0px' }}
              className="billing-report-header"
            >
              <Grid item md={6} xs={12}>
                <div className="header-label">Tilaaja</div>
                <div className="header-value">{reportWorkOrder?.purchaser}</div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className="header-label">Ajalta</div>
                <div className="header-value">{reportWorkOrder?.duration}</div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className="header-label">Kohde</div>
                <div className="header-value">{reportWorkOrder?.location}</div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className="header-label">Nimi</div>
                <div className="header-value">{reportWorkOrder?.name}</div>
              </Grid>
            </Grid>
            <div className="table-wrapper">
              {this.state.isLoading ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                  <LoadingSpinner color="black" />
                </div>
              ) : (
                <MUIDataTable
                  data={this.state.groupedData}
                  columns={columns}
                  options={{
                    ...options,
                    setTableProps: () => ({
                      size: 'small'
                    }),
                    customRowRender: (row, dataIndex) => {
                      const collection = this.state.groupedData[dataIndex];
                      const isExpanded = this.state.expandedRows.has(collection.id);
                      
                      return [
                        <TableRow key={`row-${collection.id}`}>
                          <TableCell padding="none" style={{ width: '48px' }}>
                            <IconButton
                              size="small"
                              onClick={() => this.handleExpandRow(collection.id)}
                            >
                              <Icon>
                                {isExpanded ? 'expand_less' : 'expand_more'}
                              </Icon>
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">{moment(collection.date).format('YYYY-MM-DD')}</Typography>
                          </TableCell>
                          <TableCell>{`${collection.user_first_name} ${collection.user_last_name}`}</TableCell>
                          <TableCell>{`${collection.products.length} kirjausta`}</TableCell>
                        </TableRow>,
                        <TableRow key={`expand-${collection.id}`}>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                                {/* Shared information section */}
                                {collection.products[0] && (
                                  <Box sx={{ marginBottom: 2 }}>
                                    <Grid container spacing={2}>
                                      {/* Description section */}
                                      <Grid 
                                        item 
                                        xs={12} 
                                        md={collection.products[0].files?.length > 0 ? 7 : 12}
                                      >
                                        <Box
                                          sx={{
                                            backgroundColor: '#fff',
                                            borderRadius: '4px',
                                            boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
                                              padding: '12px 16px',
                                              display: 'flex',
                                              alignItems: 'center',
                                              gap: 1,
                                            }}
                                          >
                                            <Icon style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 20 }}>description</Icon>
                                            <Typography variant="subtitle2" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                                              Lisätiedot
                                            </Typography>
                                          </Box>
                                          <Box sx={{ padding: '16px', flexGrow: 1 }}>
                                            <Typography 
                                              style={{ 
                                                whiteSpace: 'pre-line',
                                                color: collection.products[0].description ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.38)'
                                              }}
                                            >
                                              {collection.products[0].description || 'Ei lisätietoja'}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Grid>

                                      {/* Images section */}
                                      {collection.products[0].files && collection.products[0].files.length > 0 && (
                                        <Grid item xs={12} md={5}>
                                          <Box
                                            sx={{
                                              backgroundColor: '#fff',
                                              borderRadius: '4px',
                                              boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
                                              height: '100%',
                                              display: 'flex',
                                              flexDirection: 'column',
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
                                                padding: '12px 16px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                              }}
                                            >
                                              <Icon style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 20 }}>photo_library</Icon>
                                              <Typography variant="subtitle2" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                                                Liitetyt kuvat ({collection.products[0].files.length})
                                              </Typography>
                                            </Box>
                                            <Box sx={{ padding: '16px' }}>
                                              <Grid container spacing={1}>
                                                {collection.products[0].files.map((file, index) => (
                                                  <Grid item key={index}>
                                                    <Box
                                                      onClick={() => window.open(file.original_url || file.url, '_blank')}
                                                      sx={{
                                                        position: 'relative',
                                                        cursor: 'pointer',
                                                        '&:hover': {
                                                          '& .image-overlay': {
                                                            opacity: 1,
                                                          },
                                                          '& img': {
                                                            transform: 'scale(1.05)',
                                                          }
                                                        }
                                                      }}
                                                    >
                                                      <img
                                                        src={file.thumbnail_url}
                                                        alt={file.filename}
                                                        title={file.filename}
                                                        style={{ 
                                                          width: '70px', 
                                                          height: '70px', 
                                                          objectFit: 'cover', 
                                                          borderRadius: '4px',
                                                          transition: 'transform 0.2s ease',
                                                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                                        }}
                                                      />
                                                      <Box
                                                        className="image-overlay"
                                                        sx={{
                                                          position: 'absolute',
                                                          top: 0,
                                                          left: 0,
                                                          right: 0,
                                                          bottom: 0,
                                                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          justifyContent: 'center',
                                                          opacity: 0,
                                                          transition: 'opacity 0.2s ease',
                                                          borderRadius: '4px',
                                                          pointerEvents: 'none',
                                                        }}
                                                      >
                                                        <Icon style={{ color: 'white', fontSize: 20 }}>zoom_in</Icon>
                                                      </Box>
                                                    </Box>
                                                  </Grid>
                                                ))}
                                              </Grid>
                                            </Box>
                                          </Box>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Box>
                                )}

                                {/* Product-specific information table */}
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Tuote</TableCell>
                                      <TableCell>Määrä</TableCell>
                                      <TableCell align="right">Toiminnot</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {collection.products.map((product) => (
                                      <TableRow key={product.id}>
                                        <TableCell>
                                          <div><strong>{product.product_name}</strong></div>
                                          <div style={{ color: 'rgba(0, 0, 0, 0.6)' }}>{product.product_code}</div>
                                        </TableCell>
                                        <TableCell>{product.count}</TableCell>
                                        <TableCell align="right">
                                          <IconButton
                                            onClick={() => this.handleEditClick(product)}
                                            size="small"
                                            style={{ marginRight: 8 }}
                                          >
                                            <Icon>edit</Icon>
                                          </IconButton>
                                          <IconButton
                                            onClick={() => this.handleDeleteClick(product)}
                                            size="small"
                                            color="secondary"
                                          >
                                            <Icon>delete</Icon>
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      ];
                    }
                  }}
                />
              )}
            </div>
          </div>
        ) : (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <LoadingSpinner color="black" />
          </div>
        )}

        {this.state.editDialogOpen && this.state.selectedProduct && (
          <EmployerProductLogDialog
            open={this.state.editDialogOpen}
            onClose={this.handleDialogClose}
            product={this.state.selectedProduct}
            onSave={this.handleProductUpdate}
          />
        )}
        {this.renderDeleteDialog()}
      </div>
    );
  }
}

export default EmployerProductLog;
import { observable, action } from 'mobx';
import { fromPromise } from 'mobx-utils';
import Location from '../models/Location';

export default class LocationStore {
  @observable locations = fromPromise.resolve([]);

  @observable countries = fromPromise.resolve([]);

  @observable locationFilters = []

  @observable locationDisplayColumns = [];

  constructor(uiStore, requests) {
    this.uiStore = uiStore;
    this.requests = requests;
  }

  @action setLocationFilters(column, filters) {
    this.locationFilters[column] = filters;
  }

  @action emptyFilters() {
    this.locationFilters = [];
  }

  @action setLocationDisplayColumns(column, display) {
    this.locationDisplayColumns[column] = display;
  }

  @action createLocation(item, resolve, reject) {
    return this.requests.Locations.create(item).then((location) => {
      const newLocation = Location.fromJsonProperties(location);
      this.locations.value = [].concat(newLocation, this.locations.value);

      resolve(newLocation);
    }).catch((err) => reject(err));
  }

  @action updateLocation(item, resolve, reject) {
    this.requests.Locations.update(item).then((updatedLocation) => {
      const foundLocation = this.locations.value.find((location) => location.id === updatedLocation.id);
      if (foundLocation) {
        foundLocation.updatePropertiesFromJson(updatedLocation);
      }

      resolve(foundLocation);
    }).catch((err) => reject(err));
  }

  @action async getLocations(user) {
    // this.locations = await this.requests.Locations.getAll(user);

    // this.locations = fromPromise(new Promise((resolve) => this.requests.Locations.getAll(user).then((json) => json.map((location) => Location.fromJsonProperties(location))).then(resolve)));

    this.locations = fromPromise(new Promise((resolve) => this.requests.Locations.getAll(user).then((json) => {
      const locationObjects = json.map((location) => Location.fromJsonProperties(location));
      resolve(locationObjects);
    })));
  }

  @action async getCountries() {
    this.countries = fromPromise(new Promise((resolve) => {
      this.requests.Countries.getAll().then((json) => {
        resolve(json);
      }).catch((error) => {
        console.error('Error fetching countries:', error);
        resolve([]);
      });
    }));
  }

  getCountryById(id) {
    if (this.countries.state === 'fulfilled') {
      return this.countries.value.find((country) => country.id === id);
    }
    return null;
  }

  // @action getLocations() {
  //   const ctxUser = this.uiStore.currentUser;

  //   return this.requests.Locations.getAll(ctxUser)
  //     .then(
  //       action((json) => {
  //         const locations = json.map((wo) => Location.fromJsonProperties(wo));

  //         console.log('LOCATIONS: ', locations);

  //         this.locations = locations;
  //       }),
  //     )
  //     .catch((err) => {
  //       console.log('error', err);
  //     });
  // }
}

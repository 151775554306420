/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { isEqual, cloneDeep } from 'lodash';

import { GridCell, GridInner } from '@rmwc/grid';
import { MenuSurfaceAnchor, Menu, MenuItem } from '@rmwc/menu';
import { TextField } from '@rmwc/textfield';
import { Button, SvgIcon } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
// We import the factory icon like this because it is from a newer mui version than what we use at the time of writing
import { ReactComponent as FactoryIcon } from '../assets/images/factory.svg';
import { ReactComponent as WarehouseIcon } from '../assets/images/warehouse.svg';

import '@rmwc/avatar/avatar.css';
import '@material/typography/dist/mdc.typography.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
// import '@material/notched-outline/dist/mdc.notched-DialogButtonoutline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

@inject('uiStore', 'chatStore', 'loginStore', 't')
@observer
class AutocompletableLocation extends Component {
  isPossibleDoubleClick = false;
  // currentLocation = {};
  // quickAddressMatchFound = false;

  constructor(props) {
    super(props);

    // Set location indexes (the incorrect place to do this since this component is meant for single locations)
    const routeWithIndexedLocation = [...props.tripRoute];
    routeWithIndexedLocation[0].routeLocations[props.locationIndex].locationOrder = props.locationIndex;

    this.state = {
      showDeleteDialog: false,
      showAdvancedDialog: false,
      matches: [],
      matchObjects: [],
      tripRoute: routeWithIndexedLocation,
      currentLocation: {},
      quickAddressMatchFound: false,
      manualAddressInputActive: false,
      firstRender: true,
      currentCategoryAddressIndexes: {},
    };
  }

  componentDidMount() {
    // Call findQuickAddressMatch on mount to ensure default selection
    this.findQuickAddressMatch();
  }

  // When first rendering the AutocompletableLocation, see if the existing address matches any of the quickAddresses
  findQuickAddressMatch() {
    const { quickAddresses, tripRoute, locationIndex } = this.props;
    const currentLocation = cloneDeep(tripRoute[0].routeLocations[locationIndex]);
    let defaultDepartureAddress;
    let defaultDestinationAddress;
    let quickAddressMatchFound = false;

    // First check if this location is marked for manual input
    if (currentLocation._manualAddressInputActive) {
      this.setState({
        currentLocation,
        manualAddressInputActive: true,
        selectedQuickAddressName: 'manual'
      });
      return; // Exit early - manual input takes precedence
    }

    // Check if we have address data to match
    if (currentLocation.street || currentLocation.city) {
      // Try to find matching quick address
      for (const [quickAddressCategory, addresses] of Object.entries(quickAddresses)) {
        const foundDefaultDepartureAddress = addresses.find((addr) => addr.defaultDepartureAddress);
        if (foundDefaultDepartureAddress) {
          defaultDepartureAddress = foundDefaultDepartureAddress;
        }

        const founddefaultDestinationAddress = addresses.find((addr) => addr.defaultDestinationAddress);
        if (founddefaultDestinationAddress) {
          defaultDestinationAddress = founddefaultDestinationAddress;
        }

        const categoryMatchIndex = addresses.findIndex((addr) => addr.street == currentLocation.street && addr.zipCode == currentLocation.zipCode && addr.city == currentLocation.city);

        if (categoryMatchIndex !== -1) {
          quickAddressMatchFound = true;
          this.setState({
            currentLocation,
            selectedQuickAddressName: addresses[categoryMatchIndex].name,
            manualAddressInputActive: false,
            currentCategoryAddressIndexes: { [quickAddressCategory]: categoryMatchIndex }
          });
          break;
        }
      }

      // If no match found and we have address data, show as manual input
      if (!quickAddressMatchFound && (currentLocation.street || currentLocation.city)) {
        this.setState({
          currentLocation,
          manualAddressInputActive: true,
          selectedQuickAddressName: 'manual'
        });
      }
    }

    // Use default addresses for new entries
    if (!currentLocation.street && !currentLocation.city) {
      // For the first location (departure), use the default departure address (Kohde)
      if (locationIndex === 0) {
        // Find the location category which should have the default departure address
        for (const [quickAddressCategory, addresses] of Object.entries(quickAddresses)) {
          const defaultAddress = addresses.find(addr => addr.defaultDepartureAddress);
          if (defaultAddress) {
            this.selectQuickAddress(defaultAddress, locationIndex, 0, quickAddressCategory, addresses.length);
            return; // Exit after selecting the default address
          }
        }
      }
      // For the second location (destination), use manual input by default
      else if (locationIndex === 1) {
        this.beginManualAddressInput(locationIndex);
        return; // Exit after setting manual input
      }
      
      // Fallback to the original logic if no specific defaults were applied
      if (!quickAddressMatchFound && defaultDepartureAddress && locationIndex === 0 && !currentLocation.id) {
        this.selectQuickAddress(defaultDepartureAddress, locationIndex);
      }
      if (!quickAddressMatchFound && defaultDestinationAddress && locationIndex === 1 && !currentLocation.id) {
        this.selectQuickAddress(defaultDestinationAddress, locationIndex);
      }
    }
  }

  componentDidUpdate(prevProps) {
    // Check if the tripRoute prop has changed
    if (prevProps.tripRoute !== this.props.tripRoute) {
      const { tripRoute, locationIndex, forceManualInput } = this.props;
      const location = tripRoute[0].routeLocations[locationIndex];
      
      // Check if this is a copied address or already marked for manual input
      if (location._isCopied || location._manualAddressInputActive) {
        this.setState({
          manualAddressInputActive: true,
          currentLocation: location,
          selectedQuickAddressName: 'manual',
          // Update the tripRoute in state to ensure the fields are populated
          tripRoute: cloneDeep(tripRoute)
        });
      }
      // If forceManualInput is true, we'll force the component to display the manual input fields
      else if (forceManualInput) {
        this.setState({
          manualAddressInputActive: true,
          currentLocation: location,
          selectedQuickAddressName: 'manual',
          // Update the tripRoute in state to ensure the fields are populated
          tripRoute: cloneDeep(tripRoute)
        });
      } else {
        // Reset the state and find quick address matches again
        this.setState({
          firstRender: true,
          tripRoute: cloneDeep(tripRoute)
        }, () => {
          // Call findQuickAddressMatch after state update to ensure default selection
          this.findQuickAddressMatch();
        });
      }
    }
    
    // Check if the location has changed (e.g., when a quick address button is clicked)
    const prevLocation = prevProps.tripRoute && prevProps.tripRoute[0] && prevProps.tripRoute[0].routeLocations && prevProps.tripRoute[0].routeLocations[this.props.locationIndex];
    const currentLocation = this.props.tripRoute && this.props.tripRoute[0] && this.props.tripRoute[0].routeLocations && this.props.tripRoute[0].routeLocations[this.props.locationIndex];
    
    if (prevLocation && currentLocation && 
        (prevLocation.street !== currentLocation.street || 
         prevLocation.city !== currentLocation.city || 
         prevLocation.zipCode !== currentLocation.zipCode)) {
      
      // If the location has changed and it's marked for manual input, maintain that state
      if (currentLocation._manualAddressInputActive) {
        this.setState({
          manualAddressInputActive: true,
          currentLocation,
          selectedQuickAddressName: 'manual'
        });
      }
      // Otherwise, try to find a matching quick address
      else {
        // Find the matching quick address
        const { quickAddresses } = this.props;
        let matchFound = false;
        
        for (const [category, addresses] of Object.entries(quickAddresses)) {
          const matchIndex = addresses.findIndex(
            addr => addr.street === currentLocation.street && 
                    addr.city === currentLocation.city
          );
          
          if (matchIndex !== -1) {
            matchFound = true;
            this.setState({
              manualAddressInputActive: false,
              currentLocation: addresses[matchIndex],
              selectedQuickAddressName: addresses[matchIndex].name,
              currentCategoryAddressIndexes: { [category]: matchIndex }
            });
            break;
          }
        }
        
        // If no match found, default to manual input
        if (!matchFound && (currentLocation.street || currentLocation.city)) {
          this.setState({
            manualAddressInputActive: true,
            currentLocation,
            selectedQuickAddressName: 'manual'
          });
        }
      }
    }
  }

  capitalizeString(str) {
    str = str.charAt(0).toUpperCase() + str.slice(1);
    return str
  }

  doOnBlur(locationIndex) {
    const { tripRoute: stateTripRoute, routeIndex } = this.state;
    const { loginStore, updateLocation, updateRouteLocation, routeIndex: propsRouteIndex, tripRoute: propsTripRoute } = this.props;
    
    // Use the state's tripRoute for the update to ensure we have the latest changes
    // But also check if the location in state has been modified compared to props
    const stateLocation = stateTripRoute[0].routeLocations[locationIndex];
    const propsLocation = propsTripRoute[0].routeLocations[locationIndex];
    
    // First update the parent component with our changes
    if (stateLocation && (stateLocation.street !== propsLocation.street || 
                          stateLocation.city !== propsLocation.city || 
                          stateLocation.zipCode !== propsLocation.zipCode)) {
      // Use updateRouteLocation to update the specific location
      updateRouteLocation(propsRouteIndex, locationIndex, stateLocation);
    } else {
      // If no changes to the specific location, update the whole route
      updateLocation(stateTripRoute, routeIndex);
    }
    
    // Add to autocomplete locations for future use
    if (stateLocation && stateLocation.street && stateLocation.city) {
      loginStore.newAutocompleteAddress(stateLocation);
    }
  }

  setMatchingAddresses(attr, value) {
    const { locationIndex, uiStore, defaultMatches } = this.props;
    const { tripRoute } = this.state;
    const autocompleteLocations = uiStore.currentUser.autocompleteLocations;

    value = this.capitalizeString(value);

    const newTripRoute = [...tripRoute];
    const locationToChange = newTripRoute[0].routeLocations[locationIndex];
    locationToChange[attr] = value;
    
    // Ensure we preserve the manual input flag
    if (!locationToChange._manualAddressInputActive) {
      locationToChange._manualAddressInputActive = true;
    }

    const stateName = attr + locationIndex + 'Open';

    let results = [];
    let matches = [];

    if (value) {
      if (attr === 'street') {
        results = autocompleteLocations.filter(
          // (target) => value && (target[attr].indexOf(value) === 0 || target["name"]?.indexOf(value) === 0),
          (target) => value && target[attr] && (target[attr]?.toLowerCase().includes(value.toLowerCase()) || target["name"]?.toLowerCase().includes(value.toLowerCase()) || target["city"]?.toLowerCase().includes(value.toLowerCase()))
        );
        matches = results.map((result) => result[attr]);
      } else {
        results = autocompleteLocations.filter(
          // (target) => value && target[attr] && target[attr].indexOf(value) === 0,
          (target) => value && target[attr] && target[attr]?.toLowerCase().includes(value.toLowerCase())
        );
        matches = [... new Set(results.map(q => q[attr]))]
      }
    } else if (defaultMatches) {
      results = defaultMatches;
      matches = [... new Set(defaultMatches.map(q => q[attr]))];
    }

    this.setState({
      tripRoute: newTripRoute,
      matches: matches,
      matchObjects: results,
      [stateName]: results.length > 0 ? true : false,
      // Update currentLocation to keep track of manual changes
      currentLocation: locationToChange
    });
  }

  doOnSelect(attr, event, locationIndex) {
    const { tripRoute } = this.props;
    const { matchObjects } = this.state;

    const newTripRoute = [...tripRoute];
    const locationToChange = newTripRoute[0].routeLocations[locationIndex];
    locationToChange[attr] = this.state.matches[event.detail.index];

    if (attr === 'street' && matchObjects) {
      locationToChange['zipCode'] = matchObjects[event.detail.index].zipCode
      locationToChange['city'] = matchObjects[event.detail.index].city
    }
    
    // Ensure we preserve the manual input flag
    locationToChange._manualAddressInputActive = true;

    this.setState({
      tripRoute: newTripRoute,
      currentLocation: locationToChange
    }, () => {
      // Update the parent component immediately
      const { updateRouteLocation, routeIndex } = this.props;
      if (updateRouteLocation) {
        updateRouteLocation(routeIndex, locationIndex, locationToChange);
      }
      document.activeElement.blur();
    });
  }

  addLocationTextField(attr, label, styles = null, routeIndex, locationIndex, className = null) {
    const { disabled } = this.props;
    const { tripRoute, matchObjects, matches } = this.state;
    // const { tripRoute } = this.state.directionTrip;
    const locations = tripRoute[0].routeLocations;

    const stateName = attr + locationIndex + "Open";

    const ref = React.createRef();

    return (
      <>
        <MenuSurfaceAnchor>
          <Menu
            open={this.state[stateName]}
            anchorCorner="bottomLeft"
            style={{
              width: '100vw',
              maxWidth: '100%',
            }}
            onClose={() =>
              this.setState({
                [stateName]: false
              })}
            onSelect={event => {
              this.doOnSelect(attr, event, locationIndex)
            }}
            focusOnOpen={false}
            ref={ref}
            className="autocomplete-location-menu"
          >
            {attr !== 'street' && matches.slice(0, 5).map((match, index) => (
              <MenuItem
                key={index}
                style={{
                  color: "white",
                  textAlign: "center",
                  borderBottom: index != 5 ? '1px #1e232f solid' : null,
                  paddingBottom: index != 5 ? '2px' : null,
                }}
                theme={['textPrimaryOnDark', 'secondaryBg']}
              >
                <span>{match}</span>
              </MenuItem>
            ))}
            {attr === 'street' && matchObjects.slice(0, 5).map((matchObj, index) => (
              <MenuItem
                key={index}
                style={{
                  color: "white",
                  textAlign: "center",
                  borderBottom: index != 5 ? '1px #1e232f solid' : null,
                  paddingBottom: index != 5 ? '2px' : null,
                }}
                theme={['textPrimaryOnDark', 'secondaryBg']}
              >
                <div className="location-suggestion-wrapper">
                  <span>
                    {`${matchObj?.street}, ${matchObj?.zipCode != null ? matchObj?.zipCode : ''} ${matchObj?.city != null ? matchObj?.city : ''}`}
                  </span>
                  <span style={{ marginLeft: '10px', fontSize: '14px' }}>
                    {/*
                      The tempName comes from when a location is passed in the defaultMatches prop
                      We use tempName instead of name since changing name to indicate the suggestion context would add the location into the locations list
                    */}
                    {matchObj.name || matchObj.tempName}
                  </span>
                </div>
              </MenuItem>
            ))}
          </Menu>
          <TextField {...{
            style: { ...styles },
            label,
            // required: true,
            type: "text",
            disabled,
            onClick: (event) => {
              const { value } = event.target;
              this.setMatchingAddresses(attr, value);
            },
            onChange: (event) => {
              const { value } = event.target;
              this.setMatchingAddresses(attr, value);
            },
            onKeyDown: (event) => {
              if (event.key === 'ArrowDown' && matches.length > 0) {
                ref.current.foundation.adapter_.focusItemAtIndex(0)
              }
            },
            onBlur: () => { this.doOnBlur(locationIndex) },
            rootProps: { ripple: false },
            theme: ['textPrimaryOnLight', 'secondaryBg'],
            onKeyPress: handleEnter,
            className,
            value: locations[locationIndex][attr] != null ? locations[locationIndex][attr] : '',
          }}
          />
        </MenuSurfaceAnchor>
      </>
    );
  }

  addSelectedQuickAddressReadOnlyField(currentLocation, styles = null, className = null) {
    const { selectedQuickAddressLabel } = this.props;

    let value = '';
    if (currentLocation.street && currentLocation.zipCode && currentLocation.city) {
      value = `${currentLocation.street} ${currentLocation.zipCode} ${currentLocation.city}`;
    } else if (currentLocation.street && currentLocation.zipCode) {
      value = `${currentLocation.street} ${currentLocation.zipCode}`;
    } else if (currentLocation.street && currentLocation.city) {
      value = `${currentLocation.street} ${currentLocation.city}`;
    }

    let label = '';
    if (currentLocation.name) {
      label = `${selectedQuickAddressLabel} (${currentLocation.name})`;
    } else {
      label = selectedQuickAddressLabel;
    }

    return (
      <TextField {...{
        style: { ...styles },
        label: label,
        // required: true,
        type: "text",
        disabled: true,
        rootProps: { ripple: false },
        theme: ['textPrimaryOnLight', 'secondaryBg'],
        onKeyPress: handleEnter,
        className,
        value: value,
      }}
      />
    );
  }

  selectQuickAddress(quickAddress, locationIndex, nextCategoryIndex, category, categorySize) {
    // Note: using prop instead of state here, since these AutocompletableLocations don't see each other and using the state tripRoute may changes made by another AutocompletableLocation
    const { tripRoute } = this.props;

    const newTripRoute = cloneDeep(tripRoute);
    const locationToChange = newTripRoute[0].routeLocations[locationIndex];

    locationToChange['street'] = quickAddress.street;
    locationToChange['zipCode'] = quickAddress.zipCode;
    locationToChange['city'] = quickAddress.city;
    locationToChange['name'] = quickAddress.name;
    
    // Remove the copied address properties to allow normal quick address behavior
    delete locationToChange['_isCopied'];
    delete locationToChange['_manualAddressInputActive'];

    let newCategoryAddressIndex = 0;
    if (nextCategoryIndex !== undefined) {
      newCategoryAddressIndex = nextCategoryIndex;
    }

    this.setState({
      tripRoute: newTripRoute,
      manualAddressInputActive: false,
      selectedQuickAddressName: quickAddress.name,
      currentLocation: quickAddress,
      // Implicitly reset the indexes of the non-selected address category
      currentCategoryAddressIndexes: { [category]: newCategoryAddressIndex }
    }, () => {
      const { updateRouteLocation, routeIndex } = this.props;
      updateRouteLocation(routeIndex, locationIndex, locationToChange);
    });
  }

  beginManualAddressInput(locationIndex) {
    const { tripRoute, routeIndex } = this.props;
    // Don't call selectQuickAddress here as it can cause issues
    const newTripRoute = cloneDeep(tripRoute);
    const locationToChange = newTripRoute[0].routeLocations[locationIndex];
    
    // Always clear fields when switching from a quick address to manual input
    // This ensures that when a user clicks "Muu" after a quick address is selected,
    // they start with empty fields
    if (!this.state.manualAddressInputActive) {
      // Explicitly set fields to empty strings, not null
      locationToChange['street'] = '';
      locationToChange['zipCode'] = '';
      locationToChange['city'] = '';
      locationToChange['name'] = '';
    }
    
    // Always set the _manualAddressInputActive flag to ensure it's respected
    // This is critical for the first location (index 0) which otherwise defaults to Kohde
    locationToChange['_manualAddressInputActive'] = true;
    delete locationToChange['_isCopied'];
    
    this.setState({
      tripRoute: newTripRoute,
      currentLocation: locationToChange,
      manualAddressInputActive: true,
      selectedQuickAddressName: 'manual',
      quickAddressMatchFound: false,
      currentCategoryAddressIndexes: {}
    }, () => {
      const { updateRouteLocation } = this.props;
      // Make sure to update the parent component with the modified location
      updateRouteLocation(routeIndex, locationIndex, locationToChange);
    });
  }

  render() {
    const { t, uiStore, tripRoute, locationIndex, locationLabel, quickAddresses } = this.props;
    const { manualAddressInputActive, currentLocation, quickAddressMatchFound, selectedQuickAddressName, firstRender, currentCategoryAddressIndexes } = this.state;

    const icons = {
      'home': <HomeIcon />,
      'location': (
        <SvgIcon>
          <FactoryIcon />
        </SvgIcon>
      ),
      'accomodation': <BusinessIcon />,
      'companyLocation': (
        <SvgIcon>
          <WarehouseIcon />
        </SvgIcon>
      ),
    };

    // Track if any category is matched to determine if "Muu" should be highlighted
    let anyCategoryMatched = false;

    return (
      <>
        <GridCell
          key={locationIndex}
        >
          {quickAddresses && Object.keys(quickAddresses).length > 0 && (
            <GridInner>
              <GridCell
                span={12}
                style={{ textAlign: 'end' }}
              >
                {Object.keys(quickAddresses).map((quickAddressCategory) => {
                  const currentCategoryAddressIndex = currentCategoryAddressIndexes[quickAddressCategory] || 0;

                  // Index is set to zero when current index hasn't been set yet. This ensures that index 0 is set at first click
                  // Out of bounds index are also zeroed out
                  // Otherwise, we set the index to current index + 1
                  const nextCategoryAddressIndex = ((currentCategoryAddressIndex + 1) >= quickAddresses[quickAddressCategory].length || currentCategoryAddressIndexes[quickAddressCategory] === undefined) ? 0 : currentCategoryAddressIndex + 1;

                  const qAddress = quickAddresses[quickAddressCategory][nextCategoryAddressIndex];
                  
                  // Check if this category matches the selected quick address
                  let categoryMatch = false;
                  
                  // If we have a selected quick address name that's not 'manual', check if it matches any address in this category
                  if (selectedQuickAddressName && selectedQuickAddressName !== 'manual') {
                    categoryMatch = quickAddresses[quickAddressCategory].some(address => address.name === selectedQuickAddressName);
                  }
                  
                  // Also check if the current location matches any address in this category and manual input is not active
                  if (!categoryMatch && !manualAddressInputActive && currentLocation && currentLocation.street && currentLocation.city) {
                    categoryMatch = quickAddresses[quickAddressCategory].some(
                      address => address.street === currentLocation.street && 
                                address.city === currentLocation.city
                    );
                  }

                  // Update the anyCategoryMatched flag
                  if (categoryMatch) {
                    anyCategoryMatched = true;
                  }

                  return (
                    <Button
                      key={quickAddressCategory}
                      style={{
                        color: '#FFC900',
                        border: categoryMatch ? '1px solid #FFC900' : '1px solid transparent',
                        borderRadius: 0,
                        marginRight: '10px',
                        minWidth: '50px',
                      }}
                      onClick={() => this.selectQuickAddress(qAddress, locationIndex, nextCategoryAddressIndex, quickAddressCategory, quickAddresses[quickAddressCategory].length)}
                    >
                      {icons[quickAddressCategory]}
                      {categoryMatch && quickAddresses[quickAddressCategory].length > 1 && (
                        <span style={{ marginLeft: '5px', fontSize: '16px', lineHeight: 0 }}>
                          {currentCategoryAddressIndex + 1}
                        </span>
                      )}
                    </Button>
                  );
                })}
                <Button
                  style={{
                    color: '#FFC900',
                    border: manualAddressInputActive && !anyCategoryMatched ? '1px solid #FFC900' : '1px solid transparent',
                    borderRadius: 0,
                    marginRight: '10px',
                  }}
                  onClick={() => this.beginManualAddressInput(locationIndex)}
                >
                  Muu
                </Button>
              </GridCell>
            </GridInner>
          )}

          {manualAddressInputActive && (
            <GridInner
              style={{ rowGap: '0px' }}
            >
              <GridCell
                span={12}
              >
                {this.addLocationTextField(
                  'street',
                  locationLabel,
                  { width: '100%' },
                  null,
                  locationIndex
                )}
              </GridCell>
              <GridCell
                desktop={6}
                phone={2}
              >
                {this.addLocationTextField('city', 'Paikkakunta', { width: '100%' }, null, locationIndex)}
              </GridCell>
              <GridCell
                desktop={6}
                phone={2}
              >
                {this.addLocationTextField('zipCode', 'Postinro.', { width: '100%' }, null, locationIndex)}
              </GridCell>
            </GridInner>
          )}

          {!manualAddressInputActive && (
            <GridInner
              style={{ rowGap: '0px' }}
            >
              <GridCell
                span={12}
              >
                {this.addSelectedQuickAddressReadOnlyField(currentLocation, { width: '100%' }, 'disabled-input-ios-safari')}
              </GridCell>
            </GridInner>
          )}
        </GridCell>
      </>
    )
  }
}

export default AutocompletableLocation;

/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import { GridCell, GridInner } from '@rmwc/grid';
import {
  Dialog, DialogActions, DialogButton, DialogContent, DialogTitle,
} from '@rmwc/dialog';
import { Card } from '@rmwc/card';
import moment from 'moment';
import Product from '../models/product/Product';
import LoadingSpinner from '../shared/LoadingSpinner';
import { Select } from '@rmwc/select';
import '@material/select/dist/mdc.select.css';
import './EmployerProductLogDialog.css';

@inject('uiStore', 't', 'productStore')
@observer
class EmployerProductLogDialog extends Component {
  constructor(props) {
    super(props);
    const { product } = props;

    this.state = {
      selectedProductId: product.product_id || '',
      count: product.count || 0,
      hasChanges: false,
      workOrderProducts: [],
      errors: {
        count: false,
        product: false
      }
    };

    // Keep the original product data for reference
    this.originalProduct = product;
  }

  componentDidMount() {
    const { product, productStore } = this.props;

    // Fetch products assigned to this workorder
    productStore.requests.Products.getAll().then((products) => {
      if (products) {
        // Find the product by either product_id or matching name/code
        const matchingProduct = products.find(p => 
          p.id === product.product_id || 
          (p.name === product.product_name && p.code === product.product_code)
        );

        this.setState({ 
          workOrderProducts: products,
          selectedProductId: matchingProduct?.id || product.product_id || ''
        });
      }
    });
  }

  handleBlur = (attr, event) => {
    const { errors } = this.state;
    if (!event.target.value) {
      this.setState({
        errors: { ...errors, [attr]: 'Tuote on pakollinen' }
      });
    }
  }

  handleChange = (field, value) => {
    this.setState(prevState => {
      const newState = {
        [field]: value,
        hasChanges: true,
        errors: { ...prevState.errors }
      };

      // Validate count
      if (field === 'count') {
        const countNum = parseInt(value, 10);
        newState.errors.count = isNaN(countNum) || countNum <= 0;
      }

      return newState;
    });
  };

  handleProductSelect = (event) => {
    const newValue = parseInt(event.target.value, 10);

    this.setState({
      selectedProductId: newValue,
      hasChanges: true,
      errors: {
        ...this.state.errors,
        product: !newValue
      }
    });
  };

  handleSave = () => {
    const { onSave, productStore } = this.props;
    const { selectedProductId, count, workOrderProducts } = this.state;

    console.log(selectedProductId, count);

    console.log(workOrderProducts)

    // Validate product selection
    if (!selectedProductId) {
      this.setState({
        errors: { product: 'Tuote on pakollinen' }
      });
      return;
    }

    // Find the selected product safely
    const selectedProduct = workOrderProducts.find(p => p.id === selectedProductId);

    console.log('DIALOG: Selected product:', selectedProduct);

    // Ensure selectedProduct exists before accessing its properties
    if (!selectedProduct) {
      this.setState({
        errors: { product: 'Virheellinen tuote' }
      });
      return;
    }

    const updatedProduct = {
      ...this.originalProduct,
      product_id: selectedProduct.id,
      count: parseInt(count, 10),
      user_product_collection_id: this.originalProduct.user_product_collection_id,
      id: this.originalProduct.id
    };

    console.log('DIALOG: Updated product:', updatedProduct);

    onSave(updatedProduct);
  };

  isValid = () => {
    const { selectedProductId, count } = this.state;
    const countNum = parseInt(count, 10);
    return !!selectedProductId && !isNaN(countNum) && countNum > 0;
  }

  render() {
    const { open, onClose, t } = this.props;
    const { 
      selectedProductId, 
      count, 
      workOrderProducts, 
      errors 
    } = this.state;

    // Format the date from the original product
    const formattedDate = this.originalProduct?.date 
      ? moment(this.originalProduct.date).format('DD.MM.YYYY') 
      : 'Ei päivämäärää';

    return (
      <Dialog 
        open={open} 
        onClose={onClose} 
        className="certificate-dialog employer-product-log-dialog"
      >
        <DialogTitle style={{ color: 'black' }}>Muokkaa kirjausta</DialogTitle>
        <DialogContent>
          <GridInner>
            <GridCell span={12} style={{ marginBottom: '16px', marginLeft: '6px' }}>
              <Typography use="body1">
                {formattedDate}
              </Typography>
            </GridCell>

            <GridCell span={12} style={{ marginBottom: '15px' }}>
              <Select
                label={t('products.attributes.name')}
                rootProps={{
                  style: { width: '100%' }
                }}
                name="product_id"
                value={selectedProductId || ''}
                invalid={!!this.state.errors.product}
                helpText={this.state.errors.product}
                placeholder="Valitse tuote"
                onBlur={(event) => this.handleBlur('product', event)}
                onChange={this.handleProductSelect}
              >
                {workOrderProducts.map((product) => (
                  <option 
                    key={product.id} 
                    value={product.id}
                  >
                    {product.name} {product.code ? `(${product.code})` : ''}
                  </option>
                ))}
              </Select>
            </GridCell>

            <GridCell span={12} style={{ marginBottom: '15px' }}>
              <TextField
                label={t('products.dialog.amount')}
                type="number"
                style={{ width: '100%' }}
                value={count}
                onChange={(e) => this.handleChange('count', e.target.value)}
                invalid={!!this.state.errors.count}
                helpText={this.state.errors.count ? t('common.invalid_count') : ''}
                inputProps={{
                  min: 1,
                  step: 1
                }}
              />
            </GridCell>
          </GridInner>
        </DialogContent>
        <DialogActions>
          <DialogButton 
            action="save" 
            className="accept-button" 
            onClick={this.handleSave} 
            disabled={!this.isValid()}
          >
            Tallenna
          </DialogButton>
          <DialogButton action="close" onClick={onClose}>
            Peruuta
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EmployerProductLogDialog;

/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Card, CardActions, CardActionButtons, CardActionButton,
} from '@rmwc/card';
import { TextField } from '@rmwc/textfield';
import { Checkbox } from '@rmwc/checkbox';
import { CollapsibleList, SimpleListItem } from '@rmwc/list';
import {
  Dialog, DialogActions, DialogButton, DialogContent, DialogTitle,
} from '@rmwc/dialog';
import { Button } from '@material-ui/core';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import moment, { getTripDurationWeekless } from '../utils/moment';
import { isEqual } from 'lodash';
import WorkOrderTrip from '../timelog/WorkOrderTrip';
import StartTrip from '../timelog/StartTrip';
import EndTrip from '../timelog/EndTrip';
import { buttonColors } from '../calendar/EmployeeCalendar';
// import EmployerTimelogDialogCard from '../employer-timelog/employerTimelogDialogCard';
import EmployerTimelogCard from '../employer-timelog/employerTimelogCard';
import EmployerTimelogViewDialog from '../employer-timelog/EmployerTimelogViewDialog';
import LoadingSpinner from '../shared/LoadingSpinner';

import './employerWorkOrderTripCard.css'
import { getAllowanceLabel } from '../utils';

// const renderRouteLocations = (routeLocations, oldRouteLocations) => {
//   const routeLocationWrappers = [];
//   const sortedRouteLocations = routeLocations.sort((a, b) => (a.locationOrder.valueOf() - b.locationOrder.valueOf()));
//   const sortedOldRouteLocations = oldRouteLocations?.sort((a, b) => (a.locationOrder.valueOf() - b.locationOrder.valueOf()));

//   const currentRouteLocationElements = [];
//   sortedRouteLocations.forEach((routeLocation, index) => {
//     currentRouteLocationElements.push(
//       <div key={routeLocation.id} style={{ marginTop: index === 0 ? '0' : '10px' }}>
//         <span>{routeLocation.street}</span>
//         {routeLocation.zipCode && <span style={{ marginLeft: '5px' }}>{routeLocation.zipCode}</span>}
//         {routeLocation.city && <span style={{ marginLeft: '5px' }}>{routeLocation.city}</span>}
//       </div>,
//     );
//   });

//   routeLocationWrappers.push(
//     <div key="current_wrapper">
//       {currentRouteLocationElements}
//     </div>,
//   );

//   const oldRouteLocationElements = [];
//   // If route has changed, render the old route too
//   if (sortedOldRouteLocations && !isEqual(sortedRouteLocations, sortedOldRouteLocations)) {
//     sortedOldRouteLocations.forEach((routeLocation, index) => {
//       oldRouteLocationElements.push(
//         <div key={`${routeLocation.id}_old_location`} style={{ marginTop: index === 0 ? '0' : '10px' }} className="old-value">
//           <span>{routeLocation.street}</span>
//           {routeLocation.zipCode && <span style={{ marginLeft: '5px' }}>{routeLocation.zipCode}</span>}
//           {routeLocation.city && <span style={{ marginLeft: '5px' }}>{routeLocation.city}</span>}
//         </div>,
//       );
//     });

//     routeLocationWrappers.push(
//       <div key="old_wrapper" style={{ marginTop: '10px' }}>
//         {sortedOldRouteLocations}
//       </div>,
//     );
//   }

//   return routeLocationWrappers;
// };

@inject('timelogStore', 'uiStore', 't', 'employerContextStore')
@observer
class EmployerWorkOrderTripCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startLoading: false,
      endLoading: false,
      rejectingStart: false,
      rejectingEnd: false,
      commentStart: '',
      commentEnd: '',
      sendingSMSStart: false,
      sendingSMSEnd: false,
      showTimelogDialog: false,
      openedTimelogWithMeta: null,
      rejectingTimelog: false,
      showRejectTripDialog: false,
      rejectTripDialogTitle: '',

      rejectingTripExpenses: null,
      showRejectTripExpenseDialog: false,
      rejectTripExpenseDialogTitle: '',
      rejectingTripExpenseSendSMS: false,

      employerTimelogViewDialogOpen: false,
      // rejectingTripExpenseEmployerComments: {},

      mode: 'accepting',
      hasEdited: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  employerAccept(workOrderId, workHourId) {
    const { timelogStore, uiStore } = this.props;
    const user = uiStore.currentUser;
    timelogStore.employerAccept(user, workOrderId, workHourId);
  }

  // startRejecting(mode) {
  //   if (mode === 'start') {
  //     this.setState({ rejectingStart: true });
  //   } else {
  //     this.setState({ rejectingEnd: true });
  //   }
  // }

  rejectStartTrip(tripId, sendSMS, employerComment) {
    const { timelogStore, updateWorkOrderTrip } = this.props;
    // const { commentStart } = this.state;

    this.setState({
      startLoading: true,
    }, () => {
      new Promise((resolve, reject) => timelogStore.employerStartTripReject(tripId, employerComment, sendSMS, resolve, reject))
        .then((responseWorkOrderTrip) => {
          // Success
          this.setState({
            rejectingStart: false, sendingSMSStart: false, showRejectTripDialog: false,
          }, () => {
            const newWorkOrderTrip = WorkOrderTrip.fromJsonProperties(responseWorkOrderTrip);
            updateWorkOrderTrip(newWorkOrderTrip);
          });
        })
        .catch((err) => {
          // Failure
          this.setState({
            startLoading: false,
          }, console.error(err));
        });
    });
  }

  rejectEndTrip(tripId, sendSMS, employerComment) {
    const { timelogStore, updateWorkOrderTrip } = this.props;

    this.setState({
      endLoading: true,
    }, () => {
      new Promise((resolve, reject) => timelogStore.employerEndTripReject(tripId, employerComment, sendSMS, resolve, reject))
        .then((responseWorkOrderTrip) => {
          // Success
          this.setState({
            rejectingEnd: false, sendingSMSEnd: false, showRejectTripDialog: false,
          }, () => {
            const newWorkOrderTrip = WorkOrderTrip.fromJsonProperties(responseWorkOrderTrip);
            updateWorkOrderTrip(newWorkOrderTrip);
          });
        })
        .catch((err) => {
          // Failure
          this.setState({
            endLoading: false,
          }, console.error(err));
        });
    });
  }

  rejectTaxExemptTripExpenses(expenseComments) {
    const { timelogStore, workOrderTrip, updateWorkOrderTrip } = this.props;
    const { rejectingTripExpenseSendSMS } = this.state;
    const updatedExpenses = [];

    for (const expenseId in expenseComments) {
      updatedExpenses.push({ id: expenseId, employer_comment: expenseComments[expenseId] });
    }

    new Promise((resolve, reject) => timelogStore.employerRejectTripExpenses(updatedExpenses, rejectingTripExpenseSendSMS, resolve, reject))
      .then((res) => {
        // Success
        const updatedTaxExemptTripExpenses = {...workOrderTrip.taxExemptTripExpenses};

        // TODO: Updates all Expenses, not just the rejected category?
        for (const expenseCategory in updatedTaxExemptTripExpenses) {
          for (const salaryPeriod in updatedTaxExemptTripExpenses[expenseCategory]) {
            for (const workOrder in updatedTaxExemptTripExpenses[expenseCategory][salaryPeriod]) {
              // taxExemptTripExpenseIds = taxExemptTripExpenseIds.concat(workOrderTrip.taxExemptTripExpenses[expenseCategory][salaryPeriod][workOrder].map((expense) => expense.id));
              updatedTaxExemptTripExpenses[expenseCategory][salaryPeriod][workOrder].map((expense, index) => {
                if (res.find((updatedExpense) => updatedExpense.id == expense.id)) {
                  if (index === 0) {
                    expense.employer_comment = expenseComments[expense.id];
                  }
                  expense.status = 'rejected';
                }
                return expense;
              });
            }
          }
        }
        const newWorkOrderTrip = {...workOrderTrip};
        newWorkOrderTrip.taxExemptTripExpenses = updatedTaxExemptTripExpenses;
        updateWorkOrderTrip(new WorkOrderTrip(newWorkOrderTrip));

        // Update all workOrderTrip's expenses here
        this.closeRejectTripExpenseDialog();
      })
      .catch((err) => {
        // Failure
        console.log('ERROR: ', err);
      });
  }

  rejectTripRoutes(tripRouteComments) {
    const { timelogStore, workOrderTrip, updateWorkOrderTrip } = this.props;
    const { rejectingTripRouteSendSMS } = this.state;
    const updatedTripRoutes = [];

    for (const tripRouteId in tripRouteComments) {
      updatedTripRoutes.push({ id: tripRouteId, employer_comment: tripRouteComments[tripRouteId] });
    }

    new Promise((resolve, reject) => timelogStore.employerRejectTripRoutes(updatedTripRoutes, rejectingTripRouteSendSMS, resolve, reject))
      .then((res) => {
        // Success
        const updatedTripRoutes = {...workOrderTrip.tripRoutes};

        // TODO: Updates all Expenses, not just the rejected category?
        for (const salaryPeriod in updatedTripRoutes) {
          for (const workOrder in updatedTripRoutes[salaryPeriod]) {
            // taxExemptTripExpenseIds = taxExemptTripExpenseIds.concat(workOrderTrip.taxExemptTripExpenses[expenseCategory][salaryPeriod][workOrder].map((expense) => expense.id));
            updatedTripRoutes[salaryPeriod][workOrder].map((tripRoute, index) => {
              if (res.find((updatedRoute) => updatedRoute.id == tripRoute.id)) {
                if (index === 0) {
                  tripRoute.changeAttr('employerComment', tripRouteComments[tripRoute.id]);
                }
                tripRoute.changeAttr('status', 'rejected');
              }
              return tripRoute;
            });
          }
        }

        // workOrderTrip.changeAttr('tripRoutes', updatedTripRoutes);
        // updateWorkOrderTrip(workOrderTrip);
        const newWorkOrderTrip = {...workOrderTrip};
        newWorkOrderTrip.tripRoutes = updatedTripRoutes;
        updateWorkOrderTrip(new WorkOrderTrip(newWorkOrderTrip));

        this.closeRejectTripRoutesDialog();
      })
      .catch((err) => {
        // Failure
        console.log('ERROR: ', err);
      });
  }

  // acceptStartTrip(trip) {
  //   const { timelogStore, updateWorkOrderTrip } = this.props;

  //   this.setState({
  //     startLoading: true,
  //   }, () => {
  //     new Promise((resolve, reject) => timelogStore.employerStartTripAccept(trip.id, resolve, reject))
  //       .then((responseWorkOrderTrip) => {
  //         // Success
  //         this.setState({
  //           startLoading: false,
  //         }, () => {
  //           const newWorkOrderTrip = WorkOrderTrip.processTripsMeta(responseWorkOrderTrip);
  //           updateWorkOrderTrip(newWorkOrderTrip);
  //         });
  //       })
  //       .catch((err) => {
  //         // Failure
  //         this.setState({
  //           startLoading: false,
  //         }, console.log('ERROR: ', err));
  //       });
  //   });
  // }

  // acceptEndTrip(trip) {
  //   const { timelogStore, updateWorkOrderTrip } = this.props;

  //   this.setState({
  //     endLoading: true,
  //   }, () => {
  //     new Promise((resolve, reject) => timelogStore.employerEndTripAccept(trip.id, resolve, reject))
  //       .then((responseWorkOrderTrip) => {
  //         // Success
  //         this.setState({
  //           endLoading: false,
  //         }, () => {
  //           const newWorkOrderTrip = WorkOrderTrip.processTripsMeta(responseWorkOrderTrip);
  //           updateWorkOrderTrip(newWorkOrderTrip);
  //         });
  //       })
  //       .catch((err) => {
  //         // Failure
  //         this.setState({
  //           endLoading: false,
  //         }, console.log('ERROR: ', err));
  //       });
  //   });
  // }

  acceptTrip() {
    const { timelogStore, workOrderTrip, updateWorkOrderTrip } = this.props;

    // Get all IDs from the categorized tax exempt trip expenses (nested objects: expense type, work order name)
    let taxExemptTripExpenseIds = [];
    for (const expenseCategory in workOrderTrip.taxExemptTripExpenses) {
      for (const salaryPeriod in workOrderTrip.taxExemptTripExpenses[expenseCategory]) {
        for (const workOrder in workOrderTrip.taxExemptTripExpenses[expenseCategory][salaryPeriod]) {
          taxExemptTripExpenseIds = taxExemptTripExpenseIds.concat(workOrderTrip.taxExemptTripExpenses[expenseCategory][salaryPeriod][workOrder].map((expense) => expense.id));
        }
      }
    }

    let tripRouteIds = [];
    for (const salaryPeriod in workOrderTrip.tripRoutes) {
      for (const workOrder in workOrderTrip.tripRoutes[salaryPeriod]) {
        tripRouteIds = tripRouteIds.concat(workOrderTrip.tripRoutes[salaryPeriod][workOrder].map((tripRoute) => tripRoute.id));
      }
    }

    new Promise((resolve, reject) => timelogStore.employerAcceptTrip(workOrderTrip.id, taxExemptTripExpenseIds, tripRouteIds, resolve, reject))
      .then((responseWorkOrderTrip) => {
        const newWorkOrderTrip = WorkOrderTrip.fromJsonProperties(responseWorkOrderTrip);
        updateWorkOrderTrip(newWorkOrderTrip);
      })
      .catch((err) => {
        // Failure
        console.log('ERROR: ', err);
      });
  }

  openWorkOrderTrip = () => {
    const { workOrderTrip, openTripDialog, employerContextStore } = this.props;
    employerContextStore.setCurrentEmployeeId(workOrderTrip.userId);
    openTripDialog(workOrderTrip);
  }

  rejectStartOrEndTrip = (title, startOrEndTrip) => {
    this.setState({
      rejectingTrip: startOrEndTrip,
      rejectTripDialogTitle: title,
      showRejectTripDialog: true,
    });
  }

  editTripExpenses = () => {
    this.setState({
      employerTimelogViewDialogOpen: true,
      initialTabIndex: 2,
    });
  }

  editTripRoutes = () => {
    this.setState({
      employerTimelogViewDialogOpen: true,
      initialTabIndex: 1,
    });
  }

  openRejectTripExpensesDialog = (title, expensesByWorkOrder) => {
    const rejectingTripExpenseEmployerComments = {};
    Object.keys(expensesByWorkOrder).forEach((workOrderName) => {
      expensesByWorkOrder[workOrderName].forEach((expense) => {
        // The employer comment should only apply to the first expense per work order
        // const expenseComment = expenses[workOrderName][0].employer_comment;
        rejectingTripExpenseEmployerComments[expense.id] = expense.employer_comment;
      });
      // const expenseId = expenses[workOrderName][0].id;
      // const expenseComment = expenses[workOrderName][0].employer_comment;
      // rejectingTripExpenseEmployerComments[expenseId] = expenseComment;
    });

    this.setState({
      rejectingTripExpenses: expensesByWorkOrder,
      rejectTripExpenseDialogTitle: title,
      showRejectTripExpenseDialog: true,
      rejectingTripExpenseEmployerComments: rejectingTripExpenseEmployerComments,
    });
  }

  openRejectTripRoutesDialog = (title, tripRoutes) => {
    const rejectingTripRouteComments = {};
    Object.keys(tripRoutes).forEach((workOrderName) => {
      const tripRouteId = tripRoutes[workOrderName][0].id;
      const tripRouteComment = tripRoutes[workOrderName][0].employerComment;
      rejectingTripRouteComments[tripRouteId] = tripRouteComment;
    });

    this.setState({
      rejectingTripRoutes: tripRoutes,
      rejectTripRoutesDialogTitle: title,
      showRejectTripRoutesDialog: true,
      rejectingTripRouteEmployerComments: rejectingTripRouteComments,
    });
  }

  calculateDurationFromKm(km) {
    if (km) {
      // 60 km/h
      const kmsPerHour = 60;
      const durationInHours = km / kmsPerHour;
      const onlyHours = Math.floor(durationInHours);
      // We get the latter decimal portion of durationInHours, then multiply it by 60 and round down to get minutes
      const onlyMinutes = Math.floor((durationInHours % 1) * kmsPerHour);

      if (onlyHours > 0 && onlyMinutes > 0) {
        return (
          <span
            style={{ marginLeft: '20px', fontSize: '12px' }}
          >
            {`${onlyHours} h ${onlyMinutes} min (${kmsPerHour} km/h)`}
          </span>
        );
      } else if (onlyMinutes > 0) {
        return (
          <span
            style={{ marginLeft: '20px', fontSize: '12px' }}
          >
            {`${onlyMinutes} min (${kmsPerHour} km/h)`}
          </span>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  // renderButtons(trip, mode) {
  //   const { workOrderTrip } = this.props;
  //   let sendSMS;
  //   let rejecting;
  //   if (mode === 'start') {
  //     sendSMS = this.state.sendingSMSStart;
  //     rejecting = this.state.rejectingStart;
  //   } else {
  //     sendSMS = this.state.sendingSMSEnd;
  //     rejecting = this.state.rejectingEnd;
  //   }

  //   return (
  //     <div>
  //       {trip.status !== 'draft' && (
  //         <>
  //           <div className="employer-send-sms-checkbox" style={{ marginTop: '10px' }}>
  //             <div
  //               style={{
  //                 width: '94%',
  //                 paddingLeft: '9px',
  //               }}
  //             >
  //               {rejecting ? (
  //                 <div>
  //                   <Checkbox
  //                     label="Muistuta SMS-viestillä"
  //                     checked={sendSMS}
  //                     onChange={() => {
  //                       mode === 'start' ?
  //                       this.setState((prevState) => ({
  //                         sendingSMSStart: !prevState.sendingSMSStart,
  //                       })) : this.setState((prevState) => ({
  //                         sendingSMSEnd: !prevState.sendingSMSEnd,
  //                       }));
  //                     }}
  //                   />
  //                   <div
  //                     style={{ display: rejecting ? 'initial' : 'none' }}
  //                   >
  //                     <TextField
  //                       style={{ width: '100%' }}
  //                       label="Kirjoita ohje tai lisätietoa"
  //                       icon="info"
  //                       value={mode === 'start' ? this.state.commentStart : this.state.commentEnd}
  //                       onChange={(e) => this.handleChange(e, mode)}
  //                     />
  //                   </div>
  //                 </div>
  //               ) : null}
  //             </div>
  //           </div>
  //           <CardActions>
  //             <CardActionButtons className="work-hour-card-buttons">
  //               {/* Hide the accept button if the work hour has already been accepted or we are in the reject mode */}
  //               {!rejecting && trip.status !== 'accepted' && (
  //                 <CardActionButton
  //                   className="employer-accept-button"
  //                   // style={{ width: '87px' }}
  //                   onClick={() => mode === 'start' ? this.acceptStartTrip(trip) : this.acceptEndTrip(trip)}
  //                 >
  //                   Hyväksy
  //                 </CardActionButton>
  //               )}

  //               {!rejecting && trip.status === 'accepted' && mode === 'start' && workOrderTrip.start_trip_accepted_by && (
  //                 <div style={{ fontSize: '12px' }}>
  //                   <div style={{ whiteSpace: 'nowrap' }}>
  //                     <span style={{ color: buttonColors.green.color, fontWeight: 'bold' }}>Hyväksytty: </span>
  //                     <span>{workOrderTrip.start_trip_accepted_by}</span>
  //                   </div>
  //                   <div style={{ textTransform: 'capitalize' }}>
  //                     {workOrderTrip.start_trip_accepted_at && moment(workOrderTrip.start_trip_accepted_at).local().format('dd DD.MM.YYYY HH:mm')}
  //                   </div>
  //                   {/* <div>{workOrderTrip.start_trip_accepted_at}</div> */}
  //                 </div>
  //               )}

  //               {!rejecting && trip.status === 'accepted' && mode === 'end' && workOrderTrip.end_trip_accepted_by && (
  //                 <div style={{ fontSize: '12px' }}>
  //                   <div style={{ whiteSpace: 'nowrap' }}>
  //                     <span style={{ color: buttonColors.green.color, fontWeight: 'bold' }}>Hyväksytty: </span>
  //                     <span>{workOrderTrip.end_trip_accepted_by}</span>
  //                   </div>
  //                   <div style={{ textTransform: 'capitalize' }}>
  //                     {workOrderTrip.end_trip_accepted_at && moment(workOrderTrip.end_trip_accepted_at).local().format('dd DD.MM.YYYY HH:mm')}
  //                   </div>
  //                   {/* <div>{workOrderTrip.end_trip_accepted_at}</div> */}
  //                 </div>
  //               )}

  //               {rejecting ? (
  //                 <CardActionButton
  //                   className="employer-reject-button"
  //                   // style={{ width: '87px' }}
  //                   // onClick={() => this.employerReject(workHourWithMeta.work_hour.work_order_id, workHourWithMeta.work_hour.id)}
  //                   // onClick={() => this.employerReject(workHourWithMeta.work_hour.work_order_id, workHourWithMeta.work_hour.id, sendSMS)}
  //                   onClick={() => mode === 'start' ? this.rejectStartTrip(trip.id, sendSMS) : this.rejectEndTrip(trip.id, sendSMS)}
  //                 >
  //                   Lähetä
  //                 </CardActionButton>
  //               ) : null}

  //               {rejecting ? (
  //                 <CardActionButton
  //                   className="employer-cancel-button"
  //                   style={{ width: '117px' }}
  //                   onClick={() => this.cancelReject(mode)}
  //                 >
  //                   Peruuta
  //                 </CardActionButton>
  //               ) : (
  //                 <CardActionButton
  //                   className="employer-reject-button"
  //                   style={{ width: '117px' }}
  //                   onClick={() => this.startRejecting(mode)}
  //                 >
  //                   Korjattava
  //                 </CardActionButton>
  //               )}
  //             </CardActionButtons>
  //           </CardActions>
  //         </>
  //       )}
  //     </div>
  //   );
  // }

  // cancelReject(mode) {
  //   // Maybe reset the input field here or something
  //   if (mode === 'start') {
  //     this.setState({ rejectingStart: false });
  //   } else {
  //     this.setState({ rejectingEnd: false });
  //   }
  // }

  handleChange(event, mode) {
    if (mode === 'start') {
      this.setState({ commentStart: event.target.value });
    } else {
      this.setState({ commentEnd: event.target.value });
    }
  }

  handleExpenseCommentChange(expenseId, value) {
    this.setState((prevState) => ({
      rejectingTripExpenseEmployerComments: { ...prevState.rejectingTripExpenseEmployerComments, [expenseId]: value },
    }));
  }

  handleTripRouteCommentChange(tripRouteId, value) {
    this.setState((prevState) => ({
      rejectingTripRouteEmployerComments: { ...prevState.rejectingTripRouteEmployerComments, [tripRouteId]: value },
    }));
  }

  renderRejectTripDialog() {
    const {
      showRejectTripDialog,
      rejectTripDialogTitle,
      rejectingTrip,
      rejectingSendSMS,
      rejectingEmployerComment,
    } = this.state;

    return (
      <Dialog
        style={{ padding: '0' }}
        className="employer-delete-modal work-order-trip-card-dialog"
        open={showRejectTripDialog}
        onClose={() => {
          this.setState({
            showRejectTripDialog: false,
            rejectingEmployerComment: '',
            rejectingSendSMS: false,
          });
        }}
      >
        <DialogTitle>{rejectTripDialogTitle}</DialogTitle>
        <DialogContent
          style={{ color: 'white' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <TextField
              style={{
                width: '94%',
              }}
              label="Kirjoita korjauksen ohje tai lisätietoa"
              icon="info"
              value={rejectingEmployerComment}
              onChange={this.handleChange}
            />
          </div>
          <div className="employer-send-sms-checkbox">
            <div
              style={{
                width: '94%',
                paddingLeft: '9px',
              }}
            >
              <Checkbox
                label="Muistuta SMS-viestillä"
                checked={rejectingSendSMS || false}
                onChange={() => {
                  this.setState((prevState) => ({
                    rejectingSendSMS: !prevState.rejectingSendSMS,
                  }));
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            onClick={() => {
              if (rejectingTrip instanceof StartTrip) {
                this.rejectStartTrip(rejectingTrip.id, rejectingSendSMS);
              } else if (rejectingTrip instanceof EndTrip) {
                this.rejectEndTrip(rejectingTrip.id, rejectingSendSMS);
              }
            }}
          >
            Lähetä
          </DialogButton>

          <DialogButton
            type="button"
            className="employer-reject-button"
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  closeRejectTripExpenseDialog() {
    this.setState({
      showRejectTripExpenseDialog: false,
      rejectingTripExpenseEmployerComments: {},
      rejectingTripExpenseSendSMS: false,
    });
  }

  closeRejectTripRoutesDialog() {
    this.setState({
      showRejectTripRoutesDialog: false,
      rejectingTripRouteEmployerComments: {},
      rejectingTripRouteSendSMS: false,
    })
  }

  closeEmployerTimelogViewDialog = () => {
    this.setState({
      employerTimelogViewDialogOpen: false,
    });
  }

  renderRejectTripExpenseDialog() {
    const { t } = this.props;
    const {
      rejectingTripExpenses,
      showRejectTripExpenseDialog,
      rejectTripExpenseDialogTitle,
      rejectingTripExpenseSendSMS,
      rejectingTripExpenseEmployerComments,
    } = this.state;

    return (
      <Dialog
        style={{ padding: '0' }}
        className="employer-delete-modal work-order-trip-card-dialog tax-exempt-trip-expense-reject-dialog"
        open={showRejectTripExpenseDialog}
        onClose={() => {
          this.closeRejectTripExpenseDialog();
        }}
      >
        <DialogTitle>{rejectTripExpenseDialogTitle}</DialogTitle>
        <DialogContent
          style={{ color: 'white' }}
        >
          {rejectingTripExpenses && Object.keys(rejectingTripExpenses).map((workOrderName) => {
            const firstExpenseIdOfWorkOrder = rejectingTripExpenses[workOrderName][0]?.id;

            return (
              <div style={{ margin: '20px' }}>
                <div className="work-order-name">{workOrderName}</div>
                {this.renderExpenses(rejectingTripExpenses[workOrderName], false)}
                {/* REJECTING FIELDS HERE */}
                <TextField
                  style={{
                    width: '100%',
                  }}
                  label="Kirjoita korjauksen ohje tai lisätietoa"
                  icon="info"
                  // value={rejectingTripExpenseEmployerComments[firstExpenseIdOfWorkOrder] || ''}
                  onChange={(event) => this.handleExpenseCommentChange(firstExpenseIdOfWorkOrder, event.target.value)}
                />
              </div>
            );
          })}

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
          </div>
          <div className="employer-send-sms-checkbox">
            <div
              style={{
                width: '94%',
                paddingLeft: '9px',
              }}
            >
              <Checkbox
                label="Muistuta SMS-viestillä"
                checked={rejectingTripExpenseSendSMS || false}
                onChange={() => {
                  this.setState((prevState) => ({
                    rejectingTripExpenseSendSMS: !prevState.rejectingTripExpenseSendSMS,
                  }));
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            onClick={() => {
              // console.log('Reject multiple work hours');
              this.rejectTaxExemptTripExpenses(rejectingTripExpenseEmployerComments);
            }}
          >
            Lähetä
          </DialogButton>

          <DialogButton
            type="button"
            className="employer-reject-button"
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  renderRejectTripRoutesDialog() {
    const { t } = this.props;
    const {
      rejectingTripRoutes,
      showRejectTripRoutesDialog,
      rejectTripRoutesDialogTitle,
      rejectingTripRouteSendSMS,
      rejectingTripRouteEmployerComments,
    } = this.state;

    return (
      <Dialog
        style={{ padding: '0' }}
        className="employer-delete-modal work-order-trip-card-dialog tax-exempt-trip-expense-reject-dialog"
        open={showRejectTripRoutesDialog}
        onClose={() => {
          this.closeRejectTripRoutesDialog();
        }}
      >
        <DialogTitle>{rejectTripRoutesDialogTitle}</DialogTitle>
        <DialogContent
          style={{ color: 'white', lineHeight: 'normal'  }}
        >
          {rejectingTripRoutes && Object.keys(rejectingTripRoutes).map((workOrderName) => {
            const firstTripRouteIdOfWorkOrder = rejectingTripRoutes[workOrderName][0]?.id;

            return (
              <div style={{ margin: '20px' }}>
                <div className="work-order-name">{workOrderName}</div>
                {this.renderTripRoutesInner(rejectingTripRoutes[workOrderName], false)}
                <TextField
                  style={{
                    width: '100%',
                  }}
                  label="Kirjoita korjauksen ohje tai lisätietoa"
                  icon="info"
                  // value={rejectingTripRouteEmployerComments[firstTripRouteIdOfWorkOrder] || ''}
                  onChange={(event) => this.handleTripRouteCommentChange(firstTripRouteIdOfWorkOrder, event.target.value)}
                />
              </div>
            );
          })}

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
          </div>
          <div className="employer-send-sms-checkbox">
            <div
              style={{
                width: '94%',
                paddingLeft: '9px',
              }}
            >
              <Checkbox
                label="Muistuta SMS-viestillä"
                checked={rejectingTripRouteSendSMS || false}
                onChange={() => {
                  this.setState((prevState) => ({
                    rejectingTripRouteSendSMS: !prevState.rejectingTripRouteSendSMS,
                  }));
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            onClick={() => {
              this.rejectTripRoutes(rejectingTripRouteEmployerComments);
            }}
          >
            Lähetä
          </DialogButton>

          <DialogButton
            type="button"
            className="employer-reject-button"
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  renderTrip(trip, old_trip, tripType, mode) {
    const { workOrderTrip } = this.props;

    let dialogTitle = '';
    if (tripType === 'startTrip') {
      dialogTitle = `${workOrderTrip.userName} - Matkan alkaminen: ${trip.date.format('dd D.M.')} ${trip.time}`;
    } else if (tripType === 'endTrip') {
      console.log('Rendering dialog title');
      dialogTitle = trip.toDate ? `${workOrderTrip.userName} - Matkan päättyminen: ${trip.toDate.format('dd D.M.') || '?'} ${trip.toTime}` : 'Korjaa';
    }

    if (trip.status === 'draft' && mode === 'end' && !trip.toDate) {
      return (
        <>
          {this.renderRejectAndEditButtons(trip, dialogTitle, this.openWorkOrderTrip, this.rejectStartOrEndTrip)}
          <div style={{ padding: '10px', fontSize: '14px' }}>
            Matkan päättymistä ei ole täytetty
          </div>
        </>
      );
    } else if (trip.status === 'draft') {
      return (
        <>
          {this.renderRejectAndEditButtons(trip, dialogTitle, this.openWorkOrderTrip, this.rejectStartOrEndTrip)}
          <div style={{ padding: '10px', fontSize: '14px' }}>
            Matka kirjattu ja odottaa työntekijän hyväksyntää
          </div>
        </>
      );
    }

    const calculatedDuration = this.calculateDurationFromKm(trip.tripRoute[0].kms);

    return (
      <>
        {this.renderRejectAndEditButtons(trip, dialogTitle, this.openWorkOrderTrip, this.rejectStartOrEndTrip)}
        <div className={trip.status} style={{ position: 'relative', margin: '10px', padding: '10px' }} key={`${mode || 'nomode'}-trip.id`}>
          <div style={{ backgroundColor: 'whitesmoke', fontSize: '16px' }}>
            {this.renderTripDateTime(trip, old_trip, tripType, mode === 'end' ? null : 'start')}
            {trip.status === 'rejected' && trip.employer_comment && (
              <div className="employer-work-order-trip-card-title-inner-no-clr" style={{ marginLeft: '10px' }}>
                {trip.employer_comment}
              </div>
            )}
          </div>
          <div style={{ fontSize: '14px' }}>
            {this.renderKms(trip.tripRoute[0].kms, old_trip, old_trip?.tripRoute[0].kms, calculatedDuration)}
          </div>
          <div style={{ margin: '5px 0 0 10px', fontSize: '14px' }}>
            {this.renderRouteLocations(trip.tripRoute[0].routeLocations, old_trip?.tripRoute[0].routeLocations || [])}
          </div>
          {mode !== 'start' && !trip.time && !trip.date ? null : (
            <div style={{ fontSize: '14px' }}>
              {this.renderTripDateTime(trip, old_trip, tripType, 'end')}
            </div>
          )}

          {trip.status === 'rejected' && (
            <div style={{ fontSize: '12px', margin: '10px' }}>
              <span style={{ color: 'red', fontWeight: '550' }}>
                Lähetetty korjattavaksi{trip.employerComment ? ': ' : null}
              </span>
              {trip.employerComment}
            </div>
          )}
          {/* {this.renderButtons(trip, mode)} */}
        </div>
      </>
    )
  }

  renderKms(currentValue, oldTrip, oldValue, extraElement = null) {
    // as of the time of writing the extraElement is an element returned by calculateDurationFromKm()
    currentValue = currentValue || '?'
    oldValue = oldValue || '?'
    // If the previous trip version does not exist, no need to show old value as ?
    if (currentValue === oldValue || !oldTrip) {
      return <div>{currentValue} km {extraElement}</div>
    } else {
      return <div>{currentValue} km<span className="old-value">{oldValue} km</span> {extraElement}</div>
    }
  }

  renderComparedValues(label, currentValue, oldTrip, oldValue) {
    currentValue = currentValue || '?'
    oldValue = oldValue || '?'
    // The previous trip version does not exist, no need to show old value as ?
    if (!oldTrip) {
      oldValue = null;
    }
    if (currentValue === oldValue) {
      return <div>{label}: {currentValue}</div>
    } else {
      return <div>{label}: {currentValue} <span className="old-value">{oldValue}</span></div>
    }
  }

  // Really needs refactoring
  // The tripType + mode needs to be cleaned away somehow
  renderTripDateTime(trip, oldTrip, tripType, mode = null) {
    let tripTime = trip.time || '?';
    let tripDate = trip.date || '?';
    let oldTripTime = oldTrip?.time || '?';
    let oldTripDate = oldTrip?.date || '?';

    const stackedValues = [];

    if (mode !== 'start' && !trip.time && !trip.date) {
      return null;
    }
    // NOTE: mode === 'end' && tripType === 'startTrip' is an illegal combination, thus we need to filter it out
    if (mode === 'end' && tripType === 'endTrip') {
      // The date is an end date for either endTrip or startTrip
      stackedValues.push(
        <div key="current-to" className="employer-work-order-trip-card-header" style={{ display: 'flex' }}>
          <div style={{ display: 'flex',  justifyContent: 'inherit', fontWeight: '600', fontSize: '16px' }}>
            <span className="employer-work-hour-card-date">{this.formatDateWithDay(trip.toDate)}</span>
            <span style={{ marginLeft: '8px' }}>{trip.toTime}</span>
            {/* Print this text if the date is endTrip's to_date */}
            {tripType === 'endTrip' && <span style={{ marginLeft: '8px', fontWeight: 'normal' }}>Matkan päättymisaika</span>}
          </div>
        </div>
      );
      if (oldTrip && (oldTripDate._i !== tripDate._i || oldTripTime !== tripTime)) {
        stackedValues.push(
          <div key="old-to" className="employer-work-order-trip-card-header" style={{ display: 'flex' }}>
            {/* <div style={{ visibility: 'hidden' }}>{title}:</div> */}
            <div style={{ display: 'flex',  justifyContent: 'inherit' }}>
              <span className="employer-work-hour-card-date old-value">{this.formatDateWithDay(oldTrip.toDate)}</span>
              <span className="old-value" style={{ marginLeft: '11px' }}>{oldTrip.toTime}</span>
            </div>
          </div>
        );
      }
    } else if (mode === 'start' || mode === null) {
      // The date is a start date for either endTrip or startTrip
      stackedValues.push(
        <div key="current-from" className="employer-work-order-trip-card-header" style={{ display: 'flex' }}>
          <div style={{ display: 'flex',  justifyContent: 'inherit', fontWeight: mode === 'start' ? 600 : 'normal', fontSize: tripType === 'startTrip' && mode !== null ? '16px' : '14px' }}>
            <span className="employer-work-hour-card-date" style={{ textTransform: mode !== 'start' ? 'capitalize' : 'uppercase' }}>{this.formatDateWithDay(trip.date)}</span>
            <span style={{ marginLeft: '8px' }}>{tripTime}</span>
            {/* Only startTrip's start dates are meant to get this text */}
            {tripType === 'startTrip' && mode !== null && <span style={{ marginLeft: '8px', fontWeight: 'normal' }}>Matkan alkamisaika</span>}
          </div>
        </div>
      );
      if (oldTrip && (oldTripDate._i !== tripDate._i || oldTripTime !== tripTime)) {
        stackedValues.push(
          <div key="old-from" className="employer-work-order-trip-card-header" style={{ display: 'flex' }}>
            {/* <div style={{ visibility: 'hidden' }}>{title}:</div> */}
            <div style={{ display: 'flex', width: '60%',  justifyContent: 'inherit' }}>
              <span className="employer-work-hour-card-date old-value">{this.formatDateWithDay(oldTripDate)}</span>
              <span className="old-value" style={{ marginLeft: '11px' }}>{oldTripTime}</span>
            </div>
          </div>
        );
      }
    }

    return stackedValues;
  }

  renderRouteLocations(routeLocations, oldRouteLocations) {
    const routeLocationWrappers = [];

    // Mobx warns about how observableArray.sort() does not update the array in place, so we use slice() to create a copy to suppress said warning
    const sortedRouteLocations = routeLocations.slice().sort((a, b) => (a.locationOrder?.valueOf() - b.locationOrder?.valueOf()));
    const sortedOldRouteLocations = oldRouteLocations?.slice().sort((a, b) => (a.locationOrder?.valueOf() - b.locationOrder?.valueOf()));

    const currentRouteLocationElements = [];
    sortedRouteLocations?.forEach((routeLocation, index) => {
      currentRouteLocationElements.push(
        <div
          key={routeLocation.id + "_route_location"}
          style={{ paddingTop: index == 0 ? '0' : '5px'}}
        >
          <div>
            <span>{routeLocation.street}</span>
            {routeLocation.zipCode && <span style={{ marginLeft: '5px' }}>{routeLocation.zipCode}</span>}
            {routeLocation.city && <span style={{ marginLeft: '5px' }}>{routeLocation.city}</span>}
          </div>
        </div>
      );
    });

    routeLocationWrappers.push(
      <div key={"loc_current_wrapper"}>
        {currentRouteLocationElements}
      </div>
    );

    // If route has changed, print the old route too
    if (oldRouteLocations && !isEqual(sortedRouteLocations, sortedOldRouteLocations)) {
      const oldRouteLocationElements = [];
      sortedOldRouteLocations?.forEach((routeLocation, index) => {
        oldRouteLocationElements.push(
          <div
            key={routeLocation.id + "_route_location"}
            style={{ paddingTop: index == 0 ? '0' : '10px'}} className="old-value"
          >
            <span>{routeLocation.street}</span>
            {routeLocation.zipCode && <span style={{ marginLeft: '5px' }}>{routeLocation.zipCode}</span>}
            {routeLocation.city && <span style={{ marginLeft: '5px' }}>{routeLocation.city}</span>}
          </div>
        );
      });

      routeLocationWrappers.push(
        <div
          key={"loc_old_wrapper"}
          style={{ marginTop: '10px' }}
        >
          {oldRouteLocationElements}
        </div>
      );
    }

    return routeLocationWrappers;
  }

  renderTripSummary(workOrderTrip, allAllowances) {
    const { t } = this.props;

    const allowanceSums = {};
    const allowanceSumRows = [];
    // const allowanceRows = [];

    // Sort by date, ascending
    const sortedAllowances = allAllowances.sort((a, b) => a.date > b.date && 1 || -1)
    sortedAllowances.forEach((allowance) => {
      // enum status: { draft: 0, accepted: 1, closed: 2, pending: 3, rejected: 4 }
      if (allowance.value === 'full_plus_10') {
        allowanceSums['full'] = allowanceSums['full'] ? allowanceSums['full'] + 2 : 2;
      } else if (allowance.value === 'two_meals') {
        allowanceSums['meal'] = allowanceSums['meal'] ? allowanceSums['meal'] + 2 : 2;
      } else if (allowance.value === 'se_double') {
        allowanceSums['se'] = allowanceSums['se'] ? allowanceSums['se'] + 2 : 2;
      } else if (allowance.value === 'full_plus_2') {
        allowanceSums['half'] = allowanceSums['half'] ? allowanceSums['half'] + 1 : 1;
      } else if (allowance.value === 'full_half') {
        allowanceSums['full'] = allowanceSums['full'] ? allowanceSums['full'] + 1 : 1;
        allowanceSums['half'] = allowanceSums['half'] ? allowanceSums['half'] + 1 : 1;
      } else {
        // 1 if null, +1 if already existing
        allowanceSums[allowance.value] = allowanceSums[allowance.value] ? allowanceSums[allowance.value] + 1 : 1;
      }
    });

    for (const [key, value] of Object.entries(allowanceSums)) {
      allowanceSumRows.push(
        <div key={key + '-sum'} style={{ display: 'flex' }}>
          <div>{value}x</div>
          <div style={{ marginLeft: '10px' }}>{getAllowanceLabel(key)}</div>
        </div>
      )
    }

    return (
      <CollapsibleList
        open
        handle={
          <SimpleListItem
            className="allowance-list-item"
            text={
              <div style={{ display: 'flex', flexDirection: 'column', width: '80%', padding: '0' }}>
                {/* {allowanceSumRows} */}
                Yhteenveto
              </div>
            }
            // metaIcon="chevron_right"
          />
        }
      >
        <div style={{ display: 'flex', flexDirection: 'column', padding: '0 10px' }}>
          <div
            // className="employer-work-hour-card-title"
            style={{
              // padding: '0 25px 5px 25px',
              marginLeft: '7px',
              paddingTop: '10px',
              paddingBottom: '5px',
            }}
          >
            Kesto: {getTripDurationWeekless(workOrderTrip.startTrip, workOrderTrip.endTrip)}
          </div>
          <div
            style={{
              marginLeft: '7px',
              paddingBottom: '10px',
            }}
          >
            {allowanceSumRows}
          </div>
          {/* {allowanceRows} */}
        </div>
      </CollapsibleList>
    )
  }

  formatDate(date) {
    const formattedDate = moment(date).format('DD.MM.YY')
    if (formattedDate.toUpperCase() === 'INVALID DATE') {
      return '?';
    } else {
      return formattedDate;
    }
  }

  formatDateWithDay(date, zeroes = true) {
    const format = zeroes ? 'dd DD.MM.' : 'dd D.M.';
    const formattedDate = moment(date).format(format)
    if (formattedDate.toUpperCase() === 'INVALID DATE') {
      return '?';
    } else {
      return formattedDate;
    }
  }

  formatTime(time) {
    if (time) {
      return moment(time).format('HH:mm');
    } else {
      return '?'
    }
  }

  openTimelogAcceptanceDialog(timelogId) {
    const { timelogStore } = this.props;

    this.setState({
      showTimelogDialog: true,
    }, () => {
      new Promise((resolve, reject) => timelogStore.getResponsibleEmployerTimelogsByIdPromise([timelogId], resolve, reject))
        .then((response) => {
          // Success
          this.setState({
            // The response is an array of timelogs with meta but with only one element, which we want
            openedTimelogWithMeta: response[0],
          });
        })
        .catch((err) => {
          // Failure
          this.setState({
            openedTimelogWithMeta: null,
          }, console.log('ERROR: ', err));
        });
    });
  }

  updateWorkHour = (id, status, employerComment) => {
    const { workOrderTrip } = this.props;
    const { showTimelogDialog } = this.state;
    const updatedWorkHours = [...workOrderTrip.work_hours];

    const foundWorkHourIndex = updatedWorkHours.findIndex((workHour) => workHour.id === id);
    if (foundWorkHourIndex !== -1) {
      updatedWorkHours[foundWorkHourIndex].status = status;
      updatedWorkHours[foundWorkHourIndex].employer_comment = employerComment;
    }
    workOrderTrip.work_hours = updatedWorkHours;

    if (showTimelogDialog) {
      this.setState((prevState) => ({
        openedTimelogWithMeta: { ...prevState.openedTimelogWithMeta, work_hour: { ...prevState.openedTimelogWithMeta.work_hour, status: status, employer_comment: employerComment } }
      }));
    }
  }

  taxExemptTripExpenseRejectTitle(workOrderTrip, expenseName) {
    const { t } = this.props;

    return `${workOrderTrip.userName} matka ${workOrderTrip.startTrip.date.format('D.M.')}-${workOrderTrip.endTrip.toDate?.format('D.M.') || '?'} ${t(`timelog.attributes.${expenseName}_plural`)}`;
  }

  renderTaxExemptTripExpenses(taxExemptTripExpenseCategories, allAllowances) {
    const { t, dialogMode, workOrderTrip, salaryPeriods } = this.props;

    return (
      <div className="tax-exempt-trip-expense-category-collapsible">
        {Object.keys(taxExemptTripExpenseCategories).map((category) => {
          let totalAmount = allAllowances.length;
          if (category === 'allowance') {
            const combinationValues = ['full_plus_10', 'full_half', 'two_meals', 'se_double'];
            totalAmount = 0;
            allAllowances.forEach((allowance) => {
              if (combinationValues.includes(allowance.value)) {
                totalAmount += 2;
              } else {
                totalAmount += 1;
              }
            });
          }

          return (
            <CollapsibleList
              key={`${category}-collapsible`}
              handle={
                <SimpleListItem
                  className="allowance-list-item"
                  text={
                    <div className="category-collapsible-handle">
                      {t(`timelog.attributes.${category}_plural`) + `: ${totalAmount} kpl`}
                    </div>
                  }
                  metaIcon="chevron_right"
                />
              }
              startOpen
            >
              {Object.keys(taxExemptTripExpenseCategories[category]).map((salaryPeriod) => {
                // Is not current salary period and the salary period's to date (start date) is after current date = upcoming period
                const isUpcomingSalaryPeriod = salaryPeriod !== 'current' && moment(salaryPeriod.split(' ')[0], 'DD.MM.YY').isAfter(moment());

                // Current salary period, render the content as-is
                if (salaryPeriod === 'current') {
                  return (
                    <div
                      key={salaryPeriod}
                      className="trip-expense-category-collapsible-content"
                    >
                      <div style={{ paddingBottom: '10px' }}>
                        {this.renderRejectAndEditButtons(taxExemptTripExpenseCategories[category][salaryPeriod], this.taxExemptTripExpenseRejectTitle(workOrderTrip, category), this.editTripExpenses, this.openRejectTripExpensesDialog)}
                        {Object.keys(taxExemptTripExpenseCategories[category][salaryPeriod]).map((workOrderName) => (
                          <div key={`${workOrderName}-expenses`}>
                            <div className="work-order-name">{workOrderName}</div>
                            {this.renderExpenses(taxExemptTripExpenseCategories[category][salaryPeriod][workOrderName])}
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                } else {
                  // Non-current salary period, wrap content in a collapsible
                  return (
                    <div className="salary-period-collapsible">
                      <CollapsibleList
                        // NOTE: Due to RMWC bug we can't assign className to CollapsibleList, we have to use a wrapper div
                        handle={
                          <SimpleListItem
                            className="allowance-list-item"
                            text={
                              <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
                                {isUpcomingSalaryPeriod ? `${salaryPeriod} (tuleva)`: salaryPeriod}
                              </div>
                            }
                            metaIcon="chevron_right"
                          />
                        }
                      >
                        <div className="trip-expense-category-collapsible-content dashed-border">
                          <div style={{ paddingBottom: '10px' }}>
                            {Object.keys(taxExemptTripExpenseCategories[category][salaryPeriod]).map((workOrderName) => (
                              <>
                                <div className="work-order-name">{workOrderName}</div>
                                {this.renderExpenses(taxExemptTripExpenseCategories[category][salaryPeriod][workOrderName])}
                              </>
                            ))}
                          </div>
                        </div>
                      </CollapsibleList>
                    </div>
                  );
                }
              })}
            </CollapsibleList>
          );
        })}
      </div>
    );

    // return (
    //   <div className="tax-exempt-trip-expense-category-collapsible">
    //     {Object.keys(taxExemptTripExpenseCategories).map((category) => (
    //       <CollapsibleList
    //         handle={
    //           <SimpleListItem
    //             className="allowance-list-item"
    //             text={
    //               <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
    //                 {t(`timelog.attributes.${category}_plural`) + `: ${Object.values(taxExemptTripExpenseCategories[category]).flat().length} kpl`}
    //               </div>
    //             }
    //             metaIcon="chevron_right"
    //           />
    //         }
    //         open
    //       >
    //         <div className="trip-expense-category-collapsible-content">
    //           <div
    //             style={{
    //               paddingBottom: '10px',
    //             }}
    //           >
    //             {this.renderRejectAndEditButtons(taxExemptTripExpenseCategories[category], this.taxExemptTripExpenseRejectTitle(workOrderTrip, category), this.editTripExpenses, this.openRejectTripExpensesDialog)}
    //             {Object.keys(taxExemptTripExpenseCategories[category]).map((workOrderName) => (
    //               <>
    //                 <div className="work-order-name">
    //                   {workOrderName}
    //                 </div>
    //                 {this.renderExpenses(taxExemptTripExpenseCategories[category][workOrderName])}
    //               </>
    //             ))}
    //           </div>
    //         </div>
    //       </CollapsibleList>
    //     ))}
    //   </div>
    // );
  }

  renderTripRoutesInner(tripRoutes, oldTripRoutes) {
    return (
      tripRoutes.map((tripRoute, index) => {
        return (
          <div key={`${tripRoute.id}_trip_route`} style={{ padding: '10px', margin: '10px 0' }} className={tripRoute.status}>
            <div
              style={{ textTransform: 'capitalize' }}
            >
              {`${tripRoute.date.format('dd D.M.')} reitti ${index + 1}:`}
            </div>
            <div style={{ marginLeft: '10px', marginTop: '5px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Selite:</span>
                <span style={{ textAlign: 'end', hyphens: 'auto', marginBottom: '5px' }}>{tripRoute.description}</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Etäisyys (km):</span>
                <span>{tripRoute.kms}</span>
              </div>
            </div>
            {/* <div style={{ padding: '10px 0 10px 25px' }}> */}
            {tripRoute.routeLocations.length > 0 && (
              <div style={{ marginLeft: '20px', marginTop: '10px' }}>
                {this.renderRouteLocations(tripRoute.routeLocations, oldTripRoutes?.[index]?.routeLocations)}
              </div>
            )}
          </div>
        );
      })
    );
  }

  renderTripRoutes(tripRoutes, oldTripRoutes) {
    let totalKms = 0;

    Object.keys(tripRoutes).forEach((salaryPeriod) => {
      Object.keys(tripRoutes[salaryPeriod]).forEach((workOrderName) => {
        tripRoutes[salaryPeriod][workOrderName].forEach((tripRoute) => {
          totalKms += Number.parseFloat(tripRoute.kms || 0);
        });
      });
    });

    // return (
    //   <div className="tax-exempt-trip-expense-category-collapsible">
    //     <CollapsibleList
    //       handle={
    //         <SimpleListItem
    //           className="allowance-list-item"
    //           text={
    //             <div className="category-collapsible-handle">
    //               {/* {`Kilometrikorvaukset: ${tripRoutes.flat().reduce((acc, tripRoute) => acc + (Number.parseFloat(tripRoute.kms) || 0), 0)} km`} */}
    //               {`Kilometrikorvaukset: ${totalKms} km`}
    //             </div>
    //           }
    //           metaIcon="chevron_right"
    //         />
    //       }
    //       open
    //     >
    //       <div className="trip-expense-category-collapsible-content">
    //         {this.renderRejectAndEditButtons(tripRoutes, 'Pyydä korjaus kilometrikorvauksiin', this.editTripRoutes, this.openRejectTripRoutesDialog)}
    //         <div style={{ margin: '10px' }}>
    //           <div style={{ fontSize: '14px' }}>
    //             {Object.keys(tripRoutes).map((workOrderName) => {
    //               const firstTripRoute = tripRoutes[workOrderName][0];

    //               return (
    //                 <div key={`${workOrderName}-trip-routes`}>
    //                   <div>{workOrderName}</div>
    //                   {tripRoutes[workOrderName].find((route) => route.status !== 'accepted') && firstTripRoute.employerComment && (
    //                     <div
    //                       style={{ marginLeft: '10px', fontSize: '12px' }}
    //                       className="rejected"
    //                     >
    //                       "{firstTripRoute.employerComment}"
    //                     </div>
    //                   )}
    //                   {this.renderTripRoutesInner(tripRoutes[workOrderName])}
    //                 </div>
    //               );
    //             })}
    //           </div>
    //         </div>
    //       </div>
    //     </CollapsibleList>
    //   </div>
    // );

    return (
      <div className="tax-exempt-trip-expense-category-collapsible">
          <CollapsibleList
            handle={
              <SimpleListItem
                className="allowance-list-item"
                text={
                  <div className="category-collapsible-handle">
                    {`Kilometrikorvaukset: ${totalKms} km`}
                  </div>
                }
                metaIcon="chevron_right"
              />
            }
            startOpen
          >
            {Object.keys(tripRoutes).map((salaryPeriod) => {
              // Current salary period, render the content as-is
              if (salaryPeriod === 'current') {
                return (
                  <div key={salaryPeriod}>
                    {this.renderRejectAndEditButtons(tripRoutes[salaryPeriod], 'Pyydä korjaus kilometrikorvauksiin', this.editTripRoutes, this.openRejectTripRoutesDialog)}
                    <div style={{ margin: '10px', fontSize: '14px' }}>
                      {Object.keys(tripRoutes[salaryPeriod]).map((workOrderName) => {
                        const firstTripRoute = tripRoutes[salaryPeriod][workOrderName][0];

                        return (
                          <div key={`${workOrderName}-trip-routes`}>
                            <div>{workOrderName}</div>
                            {tripRoutes[salaryPeriod][workOrderName].find((route) => route.status !== 'accepted') && firstTripRoute.employerComment && (
                              <div
                                style={{ marginLeft: '10px', fontSize: '12px' }}
                                className="rejected-text"
                              >
                                "{firstTripRoute.employerComment}"
                              </div>
                            )}
                            {this.renderTripRoutesInner(tripRoutes[salaryPeriod][workOrderName])}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              } else {
                // Non-current salary period, wrap content in a collapsible
                return (
                  <div className="salary-period-collapsible">
                    <CollapsibleList
                      key={salaryPeriod}
                      ripple={false}
                      // NOTE: Due to RMWC bug we can't assign className to CollapsibleList, we have to use a wrapper div
                      handle={
                        <SimpleListItem
                          className="allowance-list-item"
                          text={
                            <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>{salaryPeriod}</div>
                          }
                          metaIcon="chevron_right"
                        />
                      }
                    >
                      <div style={{ margin: '10px' }}>
                        <div style={{ fontSize: '14px' }}>
                          {Object.keys(tripRoutes[salaryPeriod]).map((workOrderName) => {
                            const firstTripRoute = tripRoutes[salaryPeriod][workOrderName][0];

                            return (
                              <div key={`${workOrderName}-trip-routes`}>
                                <div>{workOrderName}</div>
                                {tripRoutes[salaryPeriod][workOrderName].find((route) => route.status !== 'accepted') && firstTripRoute.employerComment && (
                                  <div
                                    style={{ marginLeft: '10px', fontSize: '12px' }}
                                    className="rejected-text"
                                  >
                                    "{firstTripRoute.employerComment}"
                                  </div>
                                )}
                                {this.renderTripRoutesInner(tripRoutes[salaryPeriod][workOrderName])}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </CollapsibleList>
                  </div>
                );
              }
            })}
          </CollapsibleList>
      </div>
    );
  }

  renderRejectAndEditButtons(data, dialogTitle, edit, reject) {
    const { mode } = this.state;

    if (mode !== 'editing') {
      return null;
    }

    return (
      <div style={{ width: '100%' }}>
        <Button
          startIcon={<KeyboardArrowLeftIcon />}
          className="trip-action-button trip-action-button-left"
          onClick={() => {
            if (edit) {
              edit();
              this.setState({ hasEdited: true });
            }
          }}
        >
          Korjaa itse
        </Button>
        <Button
          endIcon={<KeyboardArrowRightIcon />}
          className="trip-action-button trip-action-button-right"
          onClick={() => {
            if (reject) {
              reject(dialogTitle, data);
              this.setState({ hasEdited: true });
            }
          }}
        >
          Pyydä korjaus
        </Button>
      </div>
    );
  }

  renderTimelogAcceptanceDialog() {
    const { showTimelogDialog, openedTimelogWithMeta } = this.state;

    return (
      <Dialog
        style={{ padding: '0' }}
        className="employer-delete-modal timelog-card-dialog"
        open={showTimelogDialog}
        onClose={(evt) => {
          this.setState({
            showTimelogDialog: false,
            openedTimelogWithMeta: null,
            rejectingTimelog: false,
          });
        }}
      >
        <DialogContent
          style={{ color: 'white', lineHeight: 'normal' }}
        >
          {openedTimelogWithMeta ? (
              <EmployerTimelogCard
                workHourWithMeta={openedTimelogWithMeta}
                updateWorkHour={this.updateWorkHour}
                dialogMode={true}
              />
            ) : (
              <div style={{ width: '100%', paddingTop: '20px', textAlign: 'center' }}>
                <LoadingSpinner color="black" />
              </div>
            )
          }
          {/* <p>Timelog card here: {openedTimelogWithMeta?.value?.id || 'no timelog'}</p> */}
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            style={{
              color: '#645F5F',
              border: '1px solid #c7c9cc',
              borderRadius: '0px',
            }}
            action="close"
          >
            Sulje
          </DialogButton>

          {/* {openedTimelogWithMeta && this.renderTimelogAcceptanceDialogActions()} */}
        </DialogActions>
      </Dialog>
    );
  }

  // renderTimelogAcceptanceDialogActions() {
  //   // const { workHourWithMeta } = this.props;
  //   const { openedTimelogWithMeta, rejectingTimelog } = this.state;
  //   const { sendSMS } = this.state;

  //   return (
  //     <div>
  //       {openedTimelogWithMeta.work_hour.status !== 'draft' && (
  //         <>
  //           <div className="employer-send-sms-checkbox">
  //             <div
  //               style={{
  //                 width: '94%',
  //                 paddingLeft: '9px',
  //               }}
  //             >
  //               {rejectingTimelog ? (
  //                 <Checkbox
  //                   label="Muistuta SMS-viestillä"
  //                   checked={sendSMS}
  //                   onChange={() => {
  //                     this.setState((prevState) => ({
  //                       sendSMS: !prevState.sendSMS,
  //                     }));
  //                   }}
  //                 />
  //               ) : null}
  //             </div>
  //           </div>
  //           <CardActions>
  //             <CardActionButtons className="work-hour-card-buttons">
  //               {/* Hide the accept button if the work hour has already been accepted or we are in the reject mode */}
  //               {rejectingTimelog || openedTimelogWithMeta.work_hour.status === 'accepted' ? (<div style={{ width: '87px' }}></div>) : (
  //                 <CardActionButton
  //                   className="employer-accept-button"
  //                   // style={{ width: '87px' }}
  //                   onClick={() => this.employerAccept(openedTimelogWithMeta.work_hour.work_order_id, openedTimelogWithMeta.work_hour.id)}
  //                 >
  //                   Hyväksy
  //                 </CardActionButton>
  //               )}

  //               {rejectingTimelog ? (
  //                 <CardActionButton
  //                   className="employer-reject-button"
  //                   // style={{ width: '87px' }}
  //                   // onClick={() => this.employerReject(workHourWithMeta.work_hour.work_order_id, workHourWithMeta.work_hour.id)}
  //                   onClick={() => this.employerReject(openedTimelogWithMeta.work_hour.work_order_id, openedTimelogWithMeta.work_hour.id, sendSMS)}
  //                 >
  //                   Lähetä
  //                 </CardActionButton>
  //               ) : null}

  //               {rejectingTimelog ? (
  //                 <CardActionButton
  //                   className="employer-cancel-button"
  //                   style={{ width: '117px' }}
  //                   onClick={() => this.cancelReject()}
  //                 >
  //                   Peruuta
  //                 </CardActionButton>
  //               ) : (
  //                 <CardActionButton
  //                   className="employer-reject-button"
  //                   style={{ width: '117px' }}
  //                   onClick={() => { this.setState({ rejectingTimelog: true }) }}
  //                 >
  //                   Korjattava
  //                 </CardActionButton>
  //               )}
  //             </CardActionButtons>
  //           </CardActions>
  //         </>
  //       )}
  //     </div>
  //   );
  // }

  renderExpenses(expenses, clickableExpenses = true) {
    const { t, dialogMode } = this.props;
    const firstExpense = expenses[0];

    return (
      <>
        {firstExpense && firstExpense.status !== 'accepted' && firstExpense.employer_comment && (
          <div className="tax-exempt-trip-expense-employer-comment">
            <span className="rejected-text">
              "{firstExpense.employer_comment}"
            </span>
          </div>
        )}
        {expenses.map((expense) => (
          <div key={`expense-${expense.id}`} className="tax-exempt-trip-expense" onClick={() => !dialogMode && clickableExpenses && this.openTimelogAcceptanceDialog(expense.work_hour_id)}>
            <div className={`tax-exempt-trip-expense-inner ${expense.status}`}>
              <div>
                {moment(expense.date, 'YYYY-MM-DD').format('dd D.M.')}
              </div>
              <div>
                {expense.name === 'allowance' ? getAllowanceLabel(expense.value, true) : expense.value}
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  render() {
    const {
      workOrderTrip,
      dialogMode,
      openEmployeeInfoDialog,
      afterUpdate,
      readOnly,
    } = this.props;
    const { employerTimelogViewDialogOpen, mode, initialTabIndex, hasEdited } = this.state;

    let allAllowances = [];
    Object.keys(workOrderTrip.taxExemptTripExpenses?.allowance || {}).forEach((salaryPeriod) => {
      Object.keys(workOrderTrip.taxExemptTripExpenses.allowance[salaryPeriod]).forEach((workOrderName) => {
        const allowancesByPeriodAndWorkOrder = workOrderTrip.taxExemptTripExpenses.allowance[salaryPeriod][workOrderName];
        allAllowances = allAllowances.concat(allowancesByPeriodAndWorkOrder);
      });
    });

    return (
      <>
        {this.renderTimelogAcceptanceDialog()}
        {this.renderRejectTripDialog()}
        {this.renderRejectTripExpenseDialog()}
        {this.renderRejectTripRoutesDialog()}
        <EmployerTimelogViewDialog
          employeeId={workOrderTrip.userId}
          workOrderTripId={workOrderTrip.id}
          open={employerTimelogViewDialogOpen}
          onClose={this.closeEmployerTimelogViewDialog}
          initialTabIndex={initialTabIndex}
          afterUpdate={afterUpdate}
        />

        <Card
          className="employer-work-hour-card no-bg"
          key={workOrderTrip.id}
          style={{ maxWidth: dialogMode ? '100%' : '', width: dialogMode ? '100%' : ''}}
        >
          <div
            className="employer-work-hour-card-title"
          >
            {/* <span style={{hyphens: 'auto'}}>{workOrderTrip.userName}</span> */}
            <span
              role="button"
              onClick={() => openEmployeeInfoDialog(workOrderTrip.user)}
              style={{ cursor: 'pointer' }}
            >
              {workOrderTrip.user.full_name}
            </span>
            <span>{this.formatDate(workOrderTrip.startTrip.date)}-{this.formatDate(workOrderTrip.endTrip.date)}</span>
          </div>

          {/* This section is horrible spaghetti that should be refactored and cleaned up */}
          {this.renderTrip(workOrderTrip.startTrip, workOrderTrip.old_start_trip, 'startTrip', 'start')}
          {Object.keys(workOrderTrip.taxExemptTripExpenses).length > 0 && this.renderTaxExemptTripExpenses(workOrderTrip.taxExemptTripExpenses, allAllowances)}
          {Object.keys(workOrderTrip.tripRoutes).length > 0 && this.renderTripRoutes(workOrderTrip.tripRoutes)}
          <div className="tax-exempt-trip-expense-category-collapsible">
            <CollapsibleList
              handle={
                <SimpleListItem
                  className="allowance-list-item"
                  text={
                    <div className="category-collapsible-handle" style={{ display: 'unset' }}>
                      {/* {`Matkan päättyminen: ${workOrderTrip.endTrip.toDate ? this.formatDateWithDay(workOrderTrip.endTrip.toDate) : ''}`} */}
                      <span>Matkan päättyminen</span>
                      <span style={{ textTransform: 'capitalize' }}>
                        {workOrderTrip.endTrip.toDate ? `: ${this.formatDateWithDay(workOrderTrip.endTrip.toDate)}` : ''}
                      </span>
                    </div>
                  }
                  metaIcon="chevron_right"
                />
              }
              startOpen
            >
              {this.renderTrip(workOrderTrip.endTrip, workOrderTrip.old_end_trip, 'endTrip', 'end')}
            </CollapsibleList>
          </div>

          <div
            className="employer-work-hour-card-title"
            style={{
              backgroundColor: '#1d2333',
              // marginTop: '10px',
            }}
          >
            {this.renderTripSummary(workOrderTrip, allAllowances)}
          </div>

          {!readOnly && mode === 'editing' && (
            <div style={{ padding: '10px', textAlign: 'end' }}>
              <Button
                className="employer-reject-button mdc-button"
                onClick={() => this.setState({ mode: 'accepting', hasEdited: false })}
              >
                {hasEdited ? 'Valmis' : 'Peruuta'}
              </Button>
            </div>
          )}

          {!readOnly && mode !== 'editing' && (
            <div style={{ padding: '10px', display: 'flex', justifyContent: 'space-between' }}>
              <Button
                className="employer-accept-button"
                onClick={() => this.acceptTrip(workOrderTrip)}
                style={{ visibility: workOrderTrip.isAccepted() ? 'hidden' : 'initial' }}
              >
                Hyväksy
              </Button>
              {!dialogMode && (
                <Button
                  className="employer-reject-button mdc-button"
                  onClick={() => this.setState({ mode: 'editing' })}
                >
                  Korjaa
                </Button>
              )}
            </div>
          )}
        </Card>
      </>
    );
  }
}

export default EmployerWorkOrderTripCard;
